// @flow
const keyMap = {
  BACKSPACE: 'Backspace',
  ENTER: 'Enter',
  LEFT_ARROW: 'ArrowLeft',
  RIGHT_ARROW: 'ArrowRight',
  DELETE: 'Delete',
  E: 'e',
  PLUS: '+',
  MINUS: '-',
};

export default keyMap;
