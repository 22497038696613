// @flow
import cx from 'classnames';
import React, { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';

import Frame from '../FrameDemo/FrameContainer';
import Option from './../../ThreePay/Option';

import {
  MixpanelHelpers,
  MIXPANEL_ACTION_PAGE_VIEW,
  MIXPANEL_ACTION_SCROLL,
} from '../../../utils/mixpanel';
import { hanToZen, toHalfWidth } from '../../../utils';

import styles from './../../ThreePay/ThreePaySelect.scss';

import type { ThreePayOption, UnavailableOption } from './../../ThreePay/type';

type Props = {
  availableOptions: ThreePayOption[],
  selectedOption: ThreePayOption,
  onSelectOption: (option: ThreePayOption) => void,
  onClickNext: (option: ThreePayOption) => void,
  unavailableOptions: UnavailableOption[],
};

const ThreePaySelect = ({
  availableOptions,
  selectedOption,
  onSelectOption,
  onClickNext,
  unavailableOptions,
}: Props) => {
  const npayOptions = availableOptions.filter(
    opt => opt.kind === '3pay' || opt.kind === '6pay' || opt.kind === '12pay'
  );
  const nlpOptions = unavailableOptions.filter(
    opt =>
      opt.reason === 'amount.over-limit' &&
      (opt.kind === '3pay' || opt.kind === '6pay' || opt.kind === '12pay')
  );

  const singlePayOption = availableOptions.find(opt => opt.kind === 'single_pay');
  const unavailableSinglePayOption = unavailableOptions.find(
    opt => opt.reason === 'amount.over-limit' && opt.kind === 'single_pay'
  );
  const npayEligible = npayOptions.length > 0;
  const nlpEligible = nlpOptions.length > 0;

  const location = useLocation();

  const { pathname } = location;

  useEffect(() => {
    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      extraData: {
        'Available Options': availableOptions,
        'Unavailable Options': unavailableOptions,
      },
    });
    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });

    return () => {
      MixpanelHelpers.trackDuration({
        pathname,
        actionName: MIXPANEL_ACTION_PAGE_VIEW,
        shouldEndTracker: true,
      });
    };
  }, []);

  // ３・６・１２
  const getNpayOptionsString = (options = npayOptions) => {
    return options
      .map((option, index, arr) =>
        hanToZen(arr[arr.length - 1 - index].installments.length.toString())
      )
      .join('・');
  };

  const debouncedOnScroll = debounce(
    () =>
      MixpanelHelpers.trackAction({
        pathname,
        actionName: MIXPANEL_ACTION_SCROLL,
      }),
    500
  );

  return (
    <Frame hasCornerBackButton={false} helpType={npayEligible ? 'threepay' : 'all'}>
      <div
        className={styles['scrollable-content']}
        data-testid="scrollable-content"
        onScroll={debouncedOnScroll}
      >
        <b className={styles.title}>お支払い回数を選ぶ</b>
        {singlePayOption && nlpEligible ? (
          <span className={styles['npay-description']} data-testid="nlp-description-header">
            今回の決済は{toHalfWidth(getNpayOptionsString(nlpOptions))}
            回あと払いをご利用いただけません。
          </span>
        ) : (
          <span className={styles['npay-description']} data-testid="npay-description">
            <>
              分割手数料無料*で{toHalfWidth(getNpayOptionsString())}回あと払いに変更できます。
              <span className={cx(styles['secondary-text'], styles['block'])}>
                *口座振替・銀行振込のみ無料
              </span>
            </>
          </span>
        )}
        {npayOptions.map(option => (
          <Option
            key={option.kind}
            isSelected={selectedOption.kind === option.kind}
            option={option}
            onSelectOption={onSelectOption}
          />
        ))}
        {singlePayOption && (
          <>
            <hr className={styles.separator} />
            <Option
              isSelected={selectedOption.kind === singlePayOption.kind}
              option={singlePayOption}
              onSelectOption={onSelectOption}
            />
          </>
        )}
        {nlpOptions.map(option => (
          <Option key={option.kind} option={{ ...option, disabled: true }} />
        ))}
        {unavailableSinglePayOption && (
          <>
            <hr className={styles.separator} />
            <Option option={{ ...unavailableSinglePayOption, disabled: true }} />
          </>
        )}
        {npayEligible && Boolean(nlpOptions.length) && (
          <span className={cx(styles['sub-title'], styles.mb24)} data-testid="nlp-sub-title">
            {toHalfWidth(getNpayOptionsString(npayOptions))}回あと払いでの購入が可能です
          </span>
        )}
        {!Boolean(nlpOptions.length) && (
          <span className={cx(styles['secondary-text'], styles.mb24)} data-testid="nlp-description">
            {toHalfWidth(getNpayOptionsString(npayOptions))}
            回あと払いの利用を一度確定すると、再度支払い回数を変更することはできませんのでご注意ください。
          </span>
        )}
        {singlePayOption && nlpEligible && (
          <>
            <span className={cx(styles['sub-title'], styles.mb24)} data-testid="nlp-sub-title">
              一括払いでの購入が可能です
            </span>
            <span
              className={cx(styles['npay-description'], styles['secondary-text'])}
              data-testid="nlp-description"
            >
              {`${toHalfWidth(
                getNpayOptionsString(nlpOptions)
              )}回あと払いは、翌月以降のご利用可能額が不足しているため、選択できません。詳しくはペイディアプリをご確認ください。`}
            </span>
          </>
        )}
        {npayEligible && nlpEligible && (
          <span
            className={cx(styles['npay-description'], styles['secondary-text'])}
            data-testid="nlp-description"
          >
            {`${toHalfWidth(
              getNpayOptionsString(nlpOptions)
            )}回あと払いは、翌月以降のご利用可能額が不足しているため、選択できません。`}
          </span>
        )}
        {!singlePayOption && npayEligible && (
          <>
            <span
              className={cx(styles['npay-description'], styles['secondary-text'])}
              data-testid="nlp-copyright"
            >
              一括払いは、今月のご利用可能額を下記の方法で調整すると、利用可能になる場合があります。
            </span>
            <ul className={styles.ul}>
              <li className={cx(styles['npay-description'], styles['secondary-text'])}>
                ご請求金額をすぐ払い機能で支払う
              </li>
              <li className={cx(styles['npay-description'], styles['secondary-text'])}>
                一括払いのご利用を3・6・12回あと払いに変更する
              </li>
            </ul>
            <span className={cx(styles['npay-description'], styles['secondary-text'])}>
              詳しくはペイディアプリをご確認ください。
            </span>
          </>
        )}
      </div>
      <div className={styles.controls}>
        <button
          id="btn-next"
          data-testid="btn-next"
          className={styles.button}
          onClick={() => onClickNext(selectedOption)}
          autoFocus
        >
          次へ
        </button>
      </div>
    </Frame>
  );
};

export default ThreePaySelect;
