import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MultiPayForm from './MultiPayForm';
import { updateFormPairs } from '../../redux/modules/form';

const mapStateToProps = state => ({
  email: state.auth.email,
  phone: state.auth.phone,
  // merchant
  shopName: state.merchant.shopName,
  logoURL: state.merchant.logoURL,
  // payment
  tier: state.payment.tier,
  paymentId: state.payment.paymentId,
  totalAmount: state.payment.totalAmount,
  // form
  annual_income: state.form.annual_income,
  city: state.form.city,
  line1: state.form.line1,
  line2: state.form.line2,
  state: state.form.state,
  zip: state.form.zip,
  date_of_birth: state.form.date_of_birth,
  dob_year: state.form.dob_year,
  dob_month: state.form.dob_month,
  dob_day: state.form.dob_day,
  employer: state.form.employer,
  first_name_kana: state.form.first_name_kana,
  last_name_kana: state.form.last_name_kana,
  gender: state.form.gender,
  household_size: state.form.household_size,
  marital_status: state.form.marital_status,
  mortgage: state.form.mortgage,
  residence_type: state.form.residence_type,
});

const mapDispatchToProps = dispatch => ({
  updateFormPairs: pairs => dispatch(updateFormPairs(pairs)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MultiPayForm)
);
