import React, { useState, useEffect } from 'react';
import AnimateHeight from 'react-animate-height';

import styles from './ConfirmItemOverviewCollapsible.scss';

type Props = {
  shouldBeCollapsible: boolean,
  children: ?any,
};

export default function ConfirmItemOverviewCollapsible({ children, shouldBeCollapsible }: Props) {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (shouldBeCollapsible) {
      setHeight(182);
    } else {
      setHeight('auto');
    }
  }, []);

  if (height === 0) {
    return null;
  }

  if (!shouldBeCollapsible) {
    return <div className={styles.container}>{children}</div>;
  }

  return (
    <div className={styles.container}>
      <AnimateHeight duration={500} height={height}>
        {children}
      </AnimateHeight>
      {height !== 'auto' && (
        <div
          onClick={() => setHeight('auto')}
          className={styles['expand-button']}
          role="button"
          tabIndex={0}
        >
          <div>
            <img
              src={require('../../assets/icon-chevron-down-pink.svg')}
              width="18"
              height="10"
              alt="show more icon"
            />
          </div>
          <span className={styles['expand-button-text']}>すべてを表示</span>
        </div>
      )}
    </div>
  );
}
