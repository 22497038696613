// @flow
/* eslint-disable jsx-a11y/label-has-for */
import React, { useRef } from 'react';
import { isIOS } from 'react-device-detect';
import cx from 'classnames';
import styles from './InputBox.scss';

type Props = {
  label: string,
  size: string,
  type: string,
  errors: Object,
  name: string,
  placeholder: string,
  value: ?string | ?number,
  onChange: Function,
  onBlur: Function,
  isRequired?: boolean, // this is just for 【任意】
};

const InputBox = ({
  label,
  size,
  type,
  errors,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  isRequired,
}: Props) => {
  const isIncome = name === 'annual_income' || name === 'income';
  const inputRef = useRef();

  const pattern = type === 'number' || type === 'tel' ? '\\d*' : undefined;

  return (
    <div className={cx(size, errors[name] ? 'error' : '', isIncome ? styles.income : '')}>
      <label htmlFor={`ip_${name}`}>{`${label} ${!isRequired ? '【任意】' : ''}`}</label>
      {type === 'date' && isIOS ? (
        <div className="select-box">
          <input
            readOnly
            type="text"
            pattern="\d{1,2}/\d{1,2}/\d{4}"
            className={cx(name, styles['fake-input'])}
            name="fake-date"
            value={value ? value.replace(/-/g, '/') : undefined}
            onClick={() => {
              inputRef.current.focus();
              const ev = document.createEvent('KeyboardEvent');

              ev.initKeyboardEvent('keydown', true, true, document.defaultView, 'F4', 0);
              inputRef.current.dispatchEvent(ev);
            }}
          />
        </div>
      ) : null}
      <input
        ref={inputRef}
        className={cx(name, isIOS && type === 'date' ? styles['hidden-input'] : '')}
        id={`ip_${name}`}
        type={type || 'text'}
        pattern={pattern}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onCompositionUpdate={onChange}
        style={isIncome ? { display: 'block' } : {}}
      />
      {errors[name] && <span className="input-error">{errors[name]}</span>}
      {isIncome && (
        <div className={styles['income-display']}>
          <em>{value || 0}万円</em>
        </div>
      )}
    </div>
  );
};

InputBox.defaultProps = {
  isRequired: true,
  onValidate: () => {},
};

export default InputBox;
