// @@flow
import robustStorage from '../../utils/robust-local-storage';

const UPDATE_PAIRS = '@@checkout/auth/update_pairs';

export const loadLocalAuth = () => {
  // this is to avoid issues when users' permission setting doesn't allow us to use localStorage and all
  try {
    // FIXME: dev only default props, should be both undefined
    // const localEmail = localStorage.getItem('paidy_email') || 'successful.payment@paidy.com';
    // const localPhone = localStorage.getItem('paidy_phone') || '818000000001';
    const localEmail = robustStorage.getItem('paidy_email') || undefined;
    const localPhone = robustStorage.getItem('paidy_phone') || undefined;

    return {
      email: localEmail,
      phone: localPhone,
      fromMerchant: false,
      isReturning: !!(localEmail && localPhone),
    };
  } catch (error) {
    return {
      email: undefined,
      phone: undefined,
      fromMerchant: false,
      isReturning: false,
    };
  }
};

export const saveLocalAuth = ({ email, phone }) => {
  robustStorage.setItem('paidy_email', email);
  robustStorage.setItem('paidy_phone', phone);
};

export const deleteLocalAuth = () => {
  robustStorage.removeItem('paidy_email');
  robustStorage.removeItem('paidy_phone');
};

const initialState = {
  ...loadLocalAuth(),
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAIRS: {
      return {
        ...state,
        ...action.pairs,
      };
    }
    default:
      return state;
  }
};

export const updateAuthPairs = pairs => ({
  type: UPDATE_PAIRS,
  pairs,
});

export default auth;
