// @flow
export const placeholders = {
  LAST_NAME_KATAKANA: 'セイ',
  FIRST_NAME_KATAKANA: 'メイ',
  LAST_NAME_KANJI: '姓',
  FIRST_NAME_KANJI: '名',
  ZIP: '000-0000',
  EMAIL: 'hello@paidy.com',
  PHONE: '000-0000-0000',
  CITY: '例：千代田区',
  LINE1: '例：テラスアパート103号室',
  LINE2: '例：大手町1-2-3',
  DATE_OF_BIRTH: 'YYYY-MM-DD',
  INCOME: '数字',
  EMPLOYER: '会社名',
};

export default {
  placeholders,
};
