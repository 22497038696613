// @flow
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { MIXPANEL_ACTION_LINK_CLICK, MixpanelHelpers } from '../../utils/mixpanel';
import styles from './Error.scss';
import robustStorage from "../../utils/robust-local-storage";
import {deleteLocalAuth} from "../../redux/modules/auth";

const ConsumerPhoneMismatch = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const onLinkClick = () => {
    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_LINK_CLICK,
      actionItem: 'Change Phone',
    });
  };

  const onGoBack = () => {
    robustStorage.setItem('paidy_remember_me', 'false');
    deleteLocalAuth();
    history.goBack()
  }

  return (
    <>
      <div className={styles['scrollable-content']}>
        <b className={styles.title}>ペイディでご利用されている携帯電話番号が異なります</b>
        <p>
          前の画面に戻り、入力内容を確認してください。
          ペイディでご利用の携帯電話番号を変更したい場合、下記の変更手続きを行ってから、再度お試しください。
        </p>
        <p>
          <a
            href="https://my.paidy.com/phone/resets"
            onClick={onLinkClick}
            target="_blank"
            rel="noopener noreferrer"
          >
            携帯電話番号を変更
          </a>
        </p>
      </div>
      <div className={styles.controls}>
        <button className={styles.button} onClick={onGoBack} data-testid="btn-back">
          戻る
        </button>
      </div>
    </>
  );
};

export default ConsumerPhoneMismatch;
