// @flow
import cx from 'classnames';
import React, { useState } from 'react';

import faqJSON from '../../contentful/faq.json';
import styles from './Help.scss';

const Chevron = (props: { className: string }) => (
  <svg
    width="16"
    height="8"
    viewBox="0 0 16 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      d="M1 1L8 7L15 1"
      stroke="#404042"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);


type Props = {
  id: string,
};

const Question = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);


  if (typeof faqJSON[props.id] === 'undefined') {
    return null;
  }

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.faq}>
      <button className={styles.question} onClick={toggle} aria-expanded={isOpen}>
        <span>{faqJSON[props.id].question}</span>
        <Chevron className={cx(styles.chevron, isOpen && styles.inverse)} />
      </button>
      <p className={cx(styles.answer, !isOpen && styles.hidden)} dangerouslySetInnerHTML={{ __html: faqJSON[props.id].answer }} />
    </div>
  );
};

export default Question;
