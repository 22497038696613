// @flow
import React, { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { faqMap } from '../../constants/faq';
import { updateUIPairs } from '../../redux/modules/ui';
import {
  MixpanelHelpers,
  FAQ_DISPLAY_NAMES,
  MIXPANEL_ACTION_PAGE_VIEW,
} from '../../utils/mixpanel';
import Frame from '../Frame/FrameContainer';
import styles from './Help.scss';
import Question from "./Question";

const getFAQ = ({ helpType }: { helpType: string }): Array<string> => {
  const all = Array.from(
    new Set(Object.keys(faqMap).reduce((accu, prop) => accu.concat(faqMap[prop]), []))
  );

  // as long as faqMap has the type
  if (Object.keys(faqMap).indexOf(helpType) > -1) {
    MixpanelHelpers.trackAction({
      customPath: `Help Content (${FAQ_DISPLAY_NAMES[helpType]})`,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });

    return helpType === 'all' ? all : faqMap[helpType];
  }

  // default
  return all;
};

const Help = () => {
  const dispatch = useDispatch();
  const helpType = useSelector(state => state.ui.helpType);

  const showAll = (event: SyntheticEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    dispatch(updateUIPairs({ helpType: 'all' }));
  };

  return (
    <Frame hasCornerCloseButton={false}>
      <div className={styles['scrollable-content']}>
        <b className={styles.title}>よくあるご質問</b>
        {getFAQ({ helpType }).map(id => (
            <Question id={id} key={id} />
        ))}
        {helpType !== 'all' && (
          <a href="#" className={styles['show-all']} onClick={showAll}>
            その他のご質問はこちら
          </a>
        )}
      </div>
    </Frame>
  );
};

export default Help;
