// @flow
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import moment from 'moment';
import idx from 'idx';

import Frame from '../Frame/FrameContainer';
import { errorHandler } from '../../utils';
import { MixpanelHelpers, MIXPANEL_ACTION_PAGE_VIEW } from '../../utils/mixpanel';

import styles from './PlusConfirm.scss';

type Props = {
  plusContractData: any,
  onPatch: Function,
};

const PlusConfirm = ({ plusContractData, onPatch }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const createdAt = useSelector(state => state.payment.createdAt);

  const { pathname } = location;

  useEffect(() => {
    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });

    return () => {
      MixpanelHelpers.trackDuration({
        pathname,
        actionName: MIXPANEL_ACTION_PAGE_VIEW,
        shouldEndTracker: true,
      });
    };
  }, []);

  const onConfirm = confirmedPlusContractData => {
    onPatch({
      number_of_installments: 1,
      plus_contract_data: confirmedPlusContractData,
    })
      .then(res => {
        // res is undefined means we have more form to fill, do nothing here
        if (!res) {
          return;
        }

        const status = idx(res, _ => _.data.status);

        if (status === 'pending') {
          history.push('/result/plus');
        } else {
          history.push('/result/rejected');
        }
      })
      .catch(err => {
        errorHandler({ err, history });
      });
  };

  return (
    <Frame hasCornerBackButton helpType="plus">
      <div className={styles['scrollable-content']}>
        <b className={styles.title}>お支払い内容の確認</b>
        <div className={styles.details}>
          <b>お支払い日</b>
          <span>
            {moment(createdAt)
              .add(3, 'months')
              .date(27)
              .format('YYYY年M月D日')}
          </span>
        </div>
        <div className={styles.details}>
          <b>お支払い回数</b>
          <span>1回</span>
        </div>
        <div className={styles.details}>
          <b>分割手数料</b>
          <span>無料</span>
        </div>
      </div>
      <div className={styles.controls}>
        <button
          id="btn-confirm"
          data-testid="btn-confirm"
          onClick={() => onConfirm(plusContractData)}
          className={styles.button}
        >
          お支払いを確定
        </button>
      </div>
    </Frame>
  );
};

export default PlusConfirm;
