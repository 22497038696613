// @flow

export const configurationToFlow = (configuration: ?Object, pathname: ?string) => {
  const {
    required_consumer_data: requiredConsumerData,
    installment_plan_options: requireInstallmentPlanOptions,
  } = configuration || {};

  const flow = [];

  let flowIndex = -1;

  if (requiredConsumerData) {
    flow.push('/digital/form');
  }

  if (requireInstallmentPlanOptions) {
    flow.push('/3pay');
  }

  if (pathname) {
    for (let i = 0; i < flow.length; i += 1) {
      const flowPath = flow[i];

      if (pathname.indexOf(flowPath) === 0) {
        flowIndex = i;

        break;
      }
    }
  }

  return {
    flow,
    flowIndex,
    isFirst: flowIndex === 0,
    isLast: flow.length && flowIndex === flow.length - 1,
  };
};

export default {};
