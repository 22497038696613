// @@flow

const UPDATE_PAIRS = '@@checkout/email-update/update_pairs';

const initialState = {
  phoneForEmailUpdate: null,
  reCapatchaToken: '',
  session: '',
  successfullyFinished: false,
};

const emailUpdate = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAIRS: {
      return {
        ...state,
        ...action.pairs,
      };
    }
    default:
      return state;
  }
};

export const updateEmailUpdatePairs = pairs => ({
  type: UPDATE_PAIRS,
  pairs,
});

export default emailUpdate;