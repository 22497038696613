// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoginForm from './LoginForm';
import { updateAuthPairs } from '../../../redux/modules/auth';
import { tocMap } from '../../../constants/toc';

const mapStateToProps = state => ({
  email: state.auth.email,
  phone: state.auth.phone,
  isReturning: state.auth.isReturning,
  tier: state.payment.tier,
  tocSingleVersion:
    state.policy.toc && state.policy.toc[tocMap.SINGLE] && state.policy.toc[tocMap.SINGLE].version,
  tocPlusVersion:
    state.policy.toc && state.policy.toc[tocMap.PLUS] && state.policy.toc[tocMap.PLUS].version,
});

const mapDispatchToProps = (dispatch: Function) => ({
  updateAuthPairs: pairs => dispatch(updateAuthPairs(pairs)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginForm)
);
