// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TokenConfirm from './TokenConfirm';
import { updatePaymentPairs } from '../../../redux/modules/payment';

const mapStateToProps: Function = (state: Object): { shopName: string } =>
  ({
    shopName: state.merchant.shopName,
  }: {
    shopName: string,
  });

const mapDispatchToProps = (dispatch: Function) => ({
  updatePaymentPairs: pairs => dispatch(updatePaymentPairs(pairs)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TokenConfirm)
);
