// @flow
/* eslint-disable camelcase, jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import cx from 'classnames';
import idx from 'idx';
import autobind from 'autobind-decorator';
import { isAndroid } from 'react-device-detect';
import Frame from '../Frame/FrameContainer';
import InputBox from '../Form/InputBox';
import SelectPrefecture from '../MultiPay/SelectPrefecture';
import DOBInput from '../Form/DOBInput';
import ShadowScrollbar from '../ShadowScrollbar/ShadowScrollbarContainer';
import withValidation from '../Form/withValidation';
import validators from '../Form/validators';
import { errorHandler, getFormattedZip, scrollToFirstError, debouncedZipReq } from '../../utils';
import { paymentStatusMap, desktopBigMaxHeightStyle } from '../../constants';
import {
  MixpanelHelpers,
  MIXPANEL_ACTION_PAGE_VIEW,
  MIXPANEL_ACTION_SUBMIT,
  MIXPANEL_ACTION_LINK_CLICK,
  MIXPANEL_ACTION_FORM_ERROR,
} from '../../utils/mixpanel';
import { placeholders } from '../../constants/form';

const formValidators = {
  city: validators.empty,
  line2: validators.empty,
  state: validators.empty,
  zip: validators.zip,
  date_of_birth: validators.dateOfBirth,
  first_name_kanji: validators.kanji,
  last_name_kanji: validators.kanji,
  first_name_kana: validators.katakana,
  last_name_kana: validators.katakana,
};

const Form = ({
  city,
  line1,
  line2,
  state,
  zip,
  date_of_birth,
  dob_year,
  dob_month,
  dob_day,
  first_name_kana,
  last_name_kana,
  first_name_kanji,
  last_name_kanji,
  onChange,
  errors,
  onValidate,
  onBlur,
}) => (
  <form>
    <fieldset>
      <InputBox
        label="姓"
        size="half"
        type="text"
        errors={errors}
        name="last_name_kanji"
        placeholder={placeholders.LAST_NAME_KANJI}
        value={last_name_kanji}
        onChange={onChange}
        onBlur={onBlur}
      />
      <InputBox
        label="名"
        size="half"
        type="text"
        errors={errors}
        name="first_name_kanji"
        placeholder={placeholders.FIRST_NAME_KANJI}
        value={first_name_kanji}
        onChange={onChange}
        onBlur={onBlur}
      />
    </fieldset>
    <fieldset>
      <InputBox
        label="セイ (カタカナ)"
        size="half"
        type="text"
        errors={errors}
        name="last_name_kana"
        placeholder={placeholders.LAST_NAME_KATAKANA}
        value={last_name_kana}
        onChange={onChange}
        onBlur={onBlur}
      />
      <InputBox
        label="メイ (カタカナ)"
        size="half"
        type="text"
        errors={errors}
        name="first_name_kana"
        placeholder={placeholders.FIRST_NAME_KATAKANA}
        value={first_name_kana}
        onChange={onChange}
        onBlur={onBlur}
      />
    </fieldset>
    <fieldset>
      <DOBInput
        errors={errors}
        value={date_of_birth}
        dobYear={dob_year}
        dobMonth={dob_month}
        dobDay={dob_day}
        onChange={onChange}
        onBlur={onBlur}
        onValidate={onValidate}
      />
    </fieldset>
    <fieldset>
      <InputBox
        label="現住所"
        size="half"
        type={isAndroid ? 'number' : 'tel'}
        errors={errors}
        name="zip"
        placeholder={placeholders.ZIP}
        value={zip}
        onChange={onChange}
        onBlur={onBlur}
      />
      <div className={cx('half', errors.state ? 'error' : undefined)}>
        <label htmlFor="select_state">都道府県</label>
        <SelectPrefecture id="select_state" name="state" value={state} onChange={onChange} />
        {errors.state && <span className="input-error">{errors.state}</span>}
      </div>
    </fieldset>
    <fieldset>
      <InputBox
        label="市区町村"
        size="full"
        type="text"
        errors={errors}
        name="city"
        placeholder={placeholders.CITY}
        value={city}
        onChange={onChange}
        onBlur={onBlur}
      />
    </fieldset>
    <fieldset>
      <InputBox
        label="丁目・番地"
        size="full"
        type="text"
        errors={errors}
        name="line2"
        placeholder={placeholders.LINE2}
        value={line2}
        onChange={onChange}
        onBlur={onBlur}
      />
    </fieldset>
    <fieldset>
      <InputBox
        label="建物名・部屋番号"
        size="full"
        type="text"
        errors={errors}
        name="line1"
        placeholder={placeholders.LINE1}
        value={line1}
        onChange={onChange}
        onBlur={onBlur}
        isRequired={false}
      />
    </fieldset>
  </form>
);

type Props = {
  //auth
  isReturning: boolean,
  onPatch: Function,
  // misc
  history: Object,
  location: Object,
  updateFormPairs: Function,
  // form fields
  city: string,
  line1: string,
  line2: ?string,
  state: string,
  zip: string,
  date_of_birth: string,
  dob_year: string,
  dob_month: string,
  dob_day: string,
  first_name_kana: string,
  first_name_kanji: string,
  last_name_kana: string,
  last_name_kanji: string,
  // validation
  onValidate: Function,
  onValidateAll: Function,
  // ui
  updateUIPairs: Function,
  loadingType: string,
  errors: Object,
};

const trackLinkClickToc = () =>
  MixpanelHelpers.trackAction({
    customPath: 'Big Form (Single)',
    actionName: MIXPANEL_ACTION_LINK_CLICK,
    actionItem: 'T&C',
  });

class BigSingle extends Component<Props, { errored: Object }> {
  state = {
    errored: {},
  };

  componentDidMount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.errors).length) {
      const newState = {};

      Object.keys(nextProps.errors).forEach(key => {
        if (nextProps[key]) {
          newState[key] = true;
        }
      });

      this.setState({ errored: newState });
    }
  }

  componentWillUnmount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      shouldEndTracker: true,
    });
  }

  @autobind
  onBlur(event: SyntheticInputEvent<HTMLInputElement>) {
    const { name } = event.currentTarget;
    const isSeparatedDOBInput = ['dob_year', 'dob_month', 'dob_day'].indexOf(name) > -1;

    this.props.onValidate({ name: isSeparatedDOBInput ? 'date_of_birth' : name });
  }

  @autobind
  onChange(event: SyntheticInputEvent<HTMLInputElement>) {
    const { name } = event.currentTarget;
    let { value } = event.currentTarget;

    if (name === 'zip') {
      if (value.length > 7) {
        return;
      } else if (value.length === 7) {
        const { updateFormPairs } = this.props;

        debouncedZipReq({
          updateFormPairs,
          value,
        });
      }
    }

    const dateOfBirth = this.props.date_of_birth;
    const isSeparatedDOBInput = ['dob_year', 'dob_month', 'dob_day'].indexOf(name) > -1;

    if (isSeparatedDOBInput) {
      const originalValue = value;
      const arrDOB = dateOfBirth.split('-');

      switch (name) {
        case 'dob_year': {
          value = [value].concat(dateOfBirth.split('-').slice(1)).join('-');

          break;
        }
        case 'dob_month': {
          const month = value ? `0${value}`.slice(-2) : '';

          value = `${arrDOB[0]}-${month}-${arrDOB[2]}`;

          break;
        }
        case 'dob_day': {
          const day = value ? `0${value}`.slice(-2) : '';

          value = `${arrDOB[0]}-${arrDOB[1]}-${day}`;

          break;
        }
        default:
          break;
      }

      this.props.updateFormPairs({
        date_of_birth: value,
        [name]: originalValue,
      });
    } else {
      this.props.updateFormPairs({
        [name]: value,
      });
    }

    // this is to make sure we are validating updated redux value
    setTimeout(() => {
      if (Object.keys(this.state.errored).indexOf(name) > -1) {
        this.props.onValidate({ name: isSeparatedDOBInput ? 'date_of_birth' : name });
      }

      if (Object.keys(this.state.errored).indexOf('date_of_birth') > -1 && isSeparatedDOBInput) {
        this.props.onValidate({ name: 'date_of_birth' });
      }
    }, 0);
  }

  @autobind
  onSubmit(event: SyntheticInputEvent<HTMLElement>) {
    const { pathname } = this.props.location;
    const { history, onPatch } = this.props;

    event.preventDefault();

    MixpanelHelpers.trackAction({ pathname, actionName: MIXPANEL_ACTION_SUBMIT });

    const errors = this.props.onValidateAll({ checkTouch: false });

    if (!Object.keys(errors).find(key => !!errors[key])) {
      const bigContractData = {
        address: {
          city: this.props.city,
          line1: this.props.line1,
          line2: this.props.line2,
          state: this.props.state,
          zip: getFormattedZip(this.props.zip),
        },
        date_of_birth: this.props.date_of_birth,
        first_name_kana: this.props.first_name_kana,
        last_name_kana: this.props.last_name_kana,
        first_name_kanji: this.props.first_name_kanji,
        last_name_kanji: this.props.last_name_kanji,
      };

      onPatch({
        number_of_installments: 1,
        big_contract_data: bigContractData,
      })
        .then((res: Object) => {
          // res is undefined means we have more form to fill, do nothing here
          if (!res) {
            return;
          }

          const status = idx(res, _ => _.data.status);

          if (status === paymentStatusMap.AUTHORIZED) {
            history.push('/result/big');
          } else {
            history.push('/result/rejected');
          }
        })
        .catch((err: Object) => {
          errorHandler({ err, history });
        });
    } else {
      scrollToFirstError();

      const erroredFields = Object.keys(errors).filter(key => !!errors[key]);

      MixpanelHelpers.trackAction({
        pathname,
        actionName: MIXPANEL_ACTION_FORM_ERROR,
        extraData: {
          'Errored Fields': erroredFields,
        },
      });
    }
  }

  render() {
    return (
      <Frame helpType="big" hasCornerBackButton mainStyle={desktopBigMaxHeightStyle}>
        <ShadowScrollbar className="no-padding-bottom">
          <section className="page-title-section">
            <h3 className="page-title">追加情報を入力してください</h3>
            <p className="desc">全てご記入ください</p>
          </section>
          <Form
            {...this.props}
            onChange={this.onChange}
            onBlur={this.onBlur}
            updateFormPairs={this.props.updateFormPairs}
          />
          <Link to="/toc" onClick={trackLinkClickToc} className="toc-above">
            Paidyの利用規約・個人情報取扱条項
            <span>に同意して</span>
          </Link>
          <button id="btn_big_form_next" className="rebel" onClick={this.onSubmit}>
            Paidyで支払う
          </button>
        </ShadowScrollbar>
      </Frame>
    );
  }
}

export default withRouter(withValidation(formValidators)(BigSingle));
