// @flow
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import cx from 'classnames';
import Frame from '../Frame/FrameContainer';
import ShadowScrollbar from '../ShadowScrollbar/ShadowScrollbarContainer';
import styles from './Big.scss';
import {
  MixpanelHelpers,
  MIXPANEL_ACTION_LINK_CLICK,
  MIXPANEL_ACTION_PAGE_VIEW,
} from '../../utils/mixpanel';

const trackLinkClickSingle = () =>
  MixpanelHelpers.trackAction({
    customPath: 'Big Installment Option',
    actionName: MIXPANEL_ACTION_LINK_CLICK,
    actionItem: 'Single Pay',
  });
const trackLinkClickMultipay = () =>
  MixpanelHelpers.trackAction({
    customPath: 'Big Installment Option',
    actionName: MIXPANEL_ACTION_LINK_CLICK,
    actionItem: 'Multipay',
  });

type Props = {
  location: Object,
};

class Big extends Component<Props, void> {
  componentDidMount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }

  componentWillUnmount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      shouldEndTracker: true,
    });
  }

  render() {
    return (
      <Frame hasCornerBackButton={false} helpType="big">
        <ShadowScrollbar className="no-padding-bottom">
          <section className="page-title-section">
            <h2 className="page-title">支払方法を選択してください</h2>
          </section>
          <div className={styles.content}>
            <figure className={styles.icon} alt="Paidy Big" />
            <div className={styles.text}>
              <Link
                id="btn_selection_multipay"
                onClick={trackLinkClickMultipay}
                className={styles['btn-multipay']}
                to="/multi-pay/select"
              >
                分割払いを選択する
              </Link>
              <br />
              <p className={styles.desc}>※ 分割払いの場合は別途審査がございます</p>
              <p className={styles.desc}>※ 分割手数料(実質年率15%)がかかります</p>
            </div>
          </div>
        </ShadowScrollbar>
        <Link
          id="btn_selection_single"
          onClick={trackLinkClickSingle}
          className={cx('btn', 'primary', styles['btn-proceed'])}
          to="/big/single"
        >
          1回払い
        </Link>
      </Frame>
    );
  }
}

export default withRouter(Big);
