// @flow
import React from 'react';

import styles from './Intro.scss';

type Props = {
  title: string,
  line1: string,
  line2: string,
  line3: string,
  line4: string,
};

function Content({ title, line1, line2, line3, line4 }: Props) {
  return (
    <>
      <b className={styles.title}>{title}</b>
      <div className={styles.illustrations}>
        <img
          src={require('../../assets/intro-step1.svg')}
          width="64"
          height="64"
          alt="email"
        />
        <span>{line1}</span>
        <img
          src={require('../../assets/intro-step2.svg')}
          width="64"
          height="64"
          alt="sms"
        />
        <span>{line2}</span>
        <img
          src={require('../../assets/intro-step3.svg')}
          width="64"
          height="64"
          alt="payment"
        />
        <span>{line3}</span>
        <img
          src={require('../../assets/intro-step4.svg')}
          width="64"
          height="64"
          alt="calendar"
        />
        <span>{line4}</span>
      </div>
    </>
  );
}

export default Content;
