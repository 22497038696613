// @flow
import React from 'react';
import moment from 'moment';

import styles from './PaymentDates.scss';

import type { ThreePayOption, ThreePayInstallment } from './type';

type Props = {
  selectedOption: ThreePayOption,
};

const getDate = (currentItem: ThreePayInstallment, prevItem: ?ThreePayInstallment) => {
  if (prevItem) {
    const currentItemMoment = moment(currentItem.due_date);
    const prevItemMoment = moment(prevItem.due_date);

    if (currentItemMoment.year() !== prevItemMoment.year()) {
      return moment(currentItem.due_date).format('YYYY年M月D日');
    }

    return moment(currentItem.due_date).format('M月D日');
  }

  return moment(currentItem.due_date).format('YYYY年M月D日');
};

export default function PaymentDates({ selectedOption }: Props) {
  const itemsCount = selectedOption.installments.length;

  return selectedOption.installments.map((item, index, options) => (
    <React.Fragment key={index.toLocaleString()}>
      <div className={styles['line-container']}>
        <span className={styles['date-text']} data-testid="npay-confirm-overview-due-date">
          {getDate(item, options[index - 1])}
        </span>
        <img
          src={require('../../assets/icon-indicator-circle.svg')}
          width="16"
          height="16"
          alt="circle indicator icon"
        />
        <span className={styles['amount-text']} data-testid="npay-confirm-overview-amount">
          {item.amount.toLocaleString('ja-jp')}円
        </span>
      </div>
      <div className={styles['line-indicator-container']}>
        {index < itemsCount - 1 && (
          <img
            src={require('../../assets/icon-indicator-line.svg')}
            width="16"
            height="16"
            alt="line indicator icon"
          />
        )}
      </div>
    </React.Fragment>
  ));
}
