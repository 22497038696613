// @flow
/* eslint-disable camelcase, jsx-a11y/label-has-for */
import React from 'react';
import cx from 'classnames';

import SelectPrefecture from '../MultiPay/SelectPrefecture';
import { placeholders } from '../../constants/form';
import CheckBox from '../CheckBox';
import styles from './Plus.scss';
import Cleave from 'cleave.js/react';

type FormProps = {
  city: string,
  line1: string,
  line2: ?string,
  state: string,
  zip: string,
  date_of_birth: string,
  first_name_kana: string,
  last_name_kana: string,
  first_name_kanji: string,
  last_name_kanji: string,
  isAdult: boolean,
  hasAdultConsent: boolean,
  head_of_household: boolean,
  income: number,
  occupation: string,
  rent_or_loan: boolean,
  onChange: Function,
  onBlur: Function,
  // validation
  // onValidate: Function,
  errors: Object,
};

const FormExtended = ({
  city,
  line1,
  line2,
  state,
  zip,
  date_of_birth,
  first_name_kana,
  last_name_kana,
  first_name_kanji,
  last_name_kanji,
  head_of_household,
  income,
  occupation,
  rent_or_loan,
  isAdult,
  hasAdultConsent,
  onChange,
  onBlur,
  errors,
}: FormProps) => (
  <>
    <div className={styles.name}>
      <fieldset>
        <label htmlFor="last-name-kanji">姓</label>
        <input
          onChange={onChange}
          onBlur={onBlur}
          id="last-name-kanji"
          type="text"
          name="last_name_kanji"
          placeholder={placeholders.LAST_NAME_KANJI}
          value={last_name_kanji}
        />
        {errors.last_name_kanji && <span className="error">{errors.last_name_kanji}</span>}
      </fieldset>
      <fieldset>
        <label htmlFor="first-name-kanji">姓</label>
        <input
          onChange={onChange}
          onBlur={onBlur}
          id="first-name-kanji"
          type="text"
          name="first_name_kanji"
          placeholder={placeholders.FIRST_NAME_KANJI}
          value={first_name_kanji}
        />
        {errors.first_name_kanji && <span className="error">{errors.first_name_kanji}</span>}
      </fieldset>
    </div>
    <div className={styles.name}>
      <fieldset>
        <label htmlFor="last-name-kana">姓（カナ）</label>
        <input
          onChange={onChange}
          onBlur={onBlur}
          id="last-name-kana"
          type="text"
          name="last_name_kana"
          placeholder={placeholders.LAST_NAME_KATAKANA}
          value={last_name_kana}
        />
        {errors.last_name_kana && <span className="error">{errors.last_name_kana}</span>}
      </fieldset>
      <fieldset>
        <label htmlFor="first-name-kana">名（カナ）</label>
        <input
          onChange={onChange}
          onBlur={onBlur}
          id="first-name-kana"
          type="text"
          name="first_name_kana"
          placeholder={placeholders.FIRST_NAME_KATAKANA}
          value={first_name_kana}
        />
        {errors.first_name_kana && <span className="error">{errors.first_name_kana}</span>}
      </fieldset>
    </div>
    <fieldset>
      <label htmlFor="date-of-birth">生年月日</label>
      <Cleave
        id="date-of-birth"
        name="date_of_birth"
        placeholder={placeholders.DATE_OF_BIRTH}
        options={{ date: true, delimiter: '-', datePattern: ['Y', 'm', 'd'] }}
        onBlur={onBlur}
        onChange={onChange}
        value={date_of_birth}
      />
      {errors.date_of_birth && <span className="error">{errors.date_of_birth}</span>}
    </fieldset>
    <fieldset>
      <label htmlFor="date-of-birth">生年月日</label>
      <Cleave
        id="zip"
        name="zip"
        placeholder={placeholders.ZIP}
        options={{ numericOnly: true, blocks: [7] }}
        onBlur={onBlur}
        onChange={onChange}
        value={zip}
      />
      {errors.zip && <span className="error">{errors.zip}</span>}
    </fieldset>
    <fieldset>
      <label htmlFor="state">都道府県</label>
      <SelectPrefecture id="state" name="state" value={state} onChange={onChange} onBlur={onBlur} />
      {errors.state && <span className="error">{errors.state}</span>}
    </fieldset>
    <fieldset>
      <label htmlFor="city">市区町村</label>
      <input
        onChange={onChange}
        onBlur={onBlur}
        id="city"
        type="text"
        name="city"
        placeholder={placeholders.CITY}
        value={city}
      />
      {errors.city && <span className="error">{errors.city}</span>}
    </fieldset>
    <fieldset>
      <label htmlFor="line2">丁目・番地</label>
      <input
        onChange={onChange}
        onBlur={onBlur}
        id="line2"
        type="text"
        name="line2"
        placeholder={placeholders.LINE2}
        value={line2}
      />
      {errors.line2 && <span className="error">{errors.line2}</span>}
    </fieldset>
    <fieldset>
      <label htmlFor="line1">建物名・部屋番号【任意】</label>
      <input
        onChange={onChange}
        onBlur={onBlur}
        id="line1"
        type="text"
        name="line1"
        placeholder={placeholders.LINE1}
        value={line1}
      />
      {errors.line1 && <span className="error">{errors.line1}</span>}
    </fieldset>
    <fieldset>
      <label htmlFor="occupation">職業形態</label>
      <div className="select">
        <select
          id="occupation"
          name="occupation"
          value={occupation}
          onChange={onChange}
          onBlur={onChange}
        >
          <option value="-1">選択</option>
          <option value="employed-full-time">社会人</option>
          <option value="employed-part-time">フリーター</option>
          <option value="housewife">主婦</option>
          <option value="unemployed">無職</option>
          <option value="student">学生</option>
        </select>
      </div>
      {errors.occupation && <span className="input-error">{errors.occupation}</span>}
    </fieldset>
    <fieldset>
      <label htmlFor="income">年収 (万単位)</label>
      <Cleave
        id="income"
        name="income"
        placeholder={placeholders.INCOME}
        options={{ numericOnly: true }}
        onBlur={onBlur}
        onChange={onChange}
        value={income}
      />
      {errors.income && <span className="error">{errors.income}</span>}
    </fieldset>
    <b className={cx(styles.income, errors.income && styles.error)}>{income || 0}万円</b>
    <fieldset>
      <label htmlFor="head_of_household">同一生計人数（本人・別居含む）</label>
      <div className="select">
        <select
          id="head_of_household"
          name="head_of_household"
          value={head_of_household}
          onChange={onChange}
          onBlur={onChange}
        >
          <option value="-1">選択</option>
          <option value="1">1名</option>
          <option value="2">2名</option>
          <option value="3">3名</option>
          <option value="4+">4名以上</option>
        </select>
      </div>
      {errors.head_of_household && <span className="input-error">{errors.head_of_household}</span>}
    </fieldset>
    <fieldset>
      <label htmlFor="rent_or_loan">家賃もしくは住宅ローンの支払い（配偶者含む）</label>
      <div className="select">
        <select
          id="rent_or_loan"
          name="rent_or_loan"
          value={rent_or_loan}
          onChange={onChange}
          onBlur={onChange}
        >
          <option value="-1">選択</option>
          <option value="true">はい</option>
          <option value="false">いいえ</option>
        </select>
      </div>
      {errors.rent_or_loan && <span className="input-error">{errors.rent_or_loan}</span>}
    </fieldset>
    {!isAdult && (
      <fieldset>
        <CheckBox
          id="has_adult_consent"
          name="has_adult_consent"
          checked={hasAdultConsent}
          onChange={onChange}
          title="親権者の同意を得て"
        />
      </fieldset>
    )}
  </>
);

export default FormExtended;
