// @flow
const keyCodeMap = {
  BACKSPACE: 8,
  ENTER: 13,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  DELETE: 46,
  E: 69,
  PLUS: 187,
  MINUS: 189,
};

export default keyCodeMap;
