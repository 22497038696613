import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import styles from './Pending.scss';
import qrDior from '../../assets/high-ticket-merchants-appsflyer-qr-code-dior.png';
import qrTagHeuer from '../../assets/high-ticket-merchants-appsflyer-qr-code-tagheuer.png';
import { onPending, onClose } from '../../utils';
import {
  MixpanelHelpers,
  MIXPANEL_ACTION_PAGE_VIEW,
  MIXPANEL_ACTION_LINK_CLICK,
} from '../../utils/mixpanel';
import { HIGH_TICKET_MERCHANT_API_KEYS } from '../../constants';

const Pending = () => {
  const { pathname } = useLocation();
  const payment = useSelector(state => state.payment);
  const AUTO_CLOSE_DELAY = 180000; // 3 minutes
  const BADGE_CLOSE_DELAY = 250; // 0.25 seconds
  const merchantKeyId: String = useSelector(state => state.merchant.keyId);
  let appDownloadUrl;
  let appDownloadQr;

  // Dynamically generate the AppsFlyer QR code and links by merchant name
  switch (merchantKeyId) {
    case HIGH_TICKET_MERCHANT_API_KEYS.DIOR_STG:
    case HIGH_TICKET_MERCHANT_API_KEYS.DIOR_PROD:
      appDownloadQr = qrDior;
      appDownloadUrl = 'https://paidy.onelink.me/vgqQ/bigurpzl';
      break;
    case HIGH_TICKET_MERCHANT_API_KEYS.TAG_HEUER_STG:
    case HIGH_TICKET_MERCHANT_API_KEYS.TAG_HEUER_PROD:
      appDownloadQr = qrTagHeuer;
      appDownloadUrl = 'https://paidy.onelink.me/vgqQ/gvbbdigw';
      break;
  }

  // Run the `on_pending` callback if the merchant provides it
  useEffect(() => {
    onPending({
      amount: payment.totalAmount,
      created_at: payment.createdAt,
      currency: payment.currency,
      id: payment.paymentId,
      status: payment.status,
      test: payment.isTest,
    });

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });

    // New requirement it should auto close after 3 minutes
    setTimeout(onClose.bind(this), AUTO_CLOSE_DELAY);
  }, []);

  const onBadgeClick = (vendor: string) => {
    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_LINK_CLICK,
      actionItem: `Download from ${vendor}`,
    });

    // Added delay before closing
    setTimeout(() => onConfirmAndProceedClick(), BADGE_CLOSE_DELAY);
  };

  const onConfirmAndProceedClick = () => {
    onClose();
  };

  const getTimeDateJST = timeStamp => {
    const formatter = Intl.DateTimeFormat('en-US', {
      timeZone: 'Asia/Tokyo',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });

    return formatter.formatToParts(timeStamp).reduce((acc, part) => {
      if (part.type !== 'literal') {
        acc[part.type] = part.value;
      }
      return acc;
    }, {});
  };

  // If the consumer is prompted to upgrade due to purchasing from a high-ticket merchant
  // the backend will check if the application to upgrade was done within CIC's business hours
  // CIC - Credit Information Center (https://www.cic.co.jp/)
  const isWithinCICBusinessHours = () => {
    // TODO: Backend should handle the logic, since JavaScript just depends on the device's system time
    const { day, hour } = getTimeDateJST(Date.now());

    // CIC business hours are between 8:00 and 25:00 JST every day, except for Sundays, which ends at 22:00 JST
    // However, we start showing the "outside the CIC business hours" message 1 hour early before the closing time.
    if (parseInt(hour) > 7) {
      if (day === 'Sun') {
        return hour <= 21;
      }

      return hour <= 23;
    }

    return false;
  };

  return (
    <div className={styles['scrollable-content']}>
      <div className={styles['content-wrapper']}>
        <div className={styles['mobile-wrapper']}>
          <img
            src={require('../../assets/icon-info-colored.svg')}
            alt="info icon"
            width={64}
            height={64}
          />

          <b className={styles.title}>
            1時間以内に
            <br />
            本人確認を行ってください
          </b>

          <span className={styles['instructions-mobile']}>
            お買い物はまもなく完了します。下記のボタンからペイディアプリをダウンロードして、
            <b>1時間</b>以内に本人確認を行ってください。
          </span>

          <div className={styles['install-mobile-app']}>
            <a
              href={appDownloadUrl}
              target="_blank"
              rel="nofollow noopener noreferrer"
              onClick={() => onBadgeClick('Apple App Store')}
              data-testid="pending-screen-apple-app-store-badge-button"
            >
              <img
                src={require('../../assets/badge-apple-app-store.png')}
                alt="Apple App Store badge"
                width={168}
                height={48}
                data-testid="pending-screen-apple-app-store-badge"
              />
            </a>
            <a
              href={appDownloadUrl}
              target="_blank"
              rel="nofollow noopener noreferrer"
              onClick={() => onBadgeClick('Google Play Store')}
              data-testid="pending-screen-google-play-store-badge-button"
            >
              <img
                src={require('../../assets/badge-google-play-store.png')}
                alt="Apple App Store badge"
                width={168}
                height={48}
                data-testid="pending-screen-google-play-store-badge"
              />
            </a>
          </div>

          <b className={styles.title}>最短5分で完了</b>

          <span>
            本人確認には運転免許証またはマイナンバーカードが利用可能。通常数時間以内に結果をメールでお知らせし、本人確認完了後に商品の購入が確定します。
          </span>
        </div>

        <div className={styles['desktop-wrapper']}>
          <b className={styles.title}>お買い物はまもなく完了します</b>

          <div>
            <div className={styles['flex-container']}>
              <img
                src={require('../../assets/pending-step1.svg')}
                alt="pending step 1"
                width={64}
                height={64}
                style={{ marginRight: 13 }}
              />
              <div>
                <b>
                  1時間以内に本人確認をする <br />
                </b>
                <span>
                  スマートフォンでQRコードを読み取ってペイディアプリをダウンロードし、
                  <b>1時間以内に本人確認を行ってください。</b>
                </span>
              </div>
            </div>
            <div className={styles['desktop-qr-container']}>
              <img
                src={appDownloadQr}
                alt="Install mobile app QR code"
                width={80}
                height={80}
                data-testid="pending-screen-qr-code"
              />
            </div>
          </div>

          <div className={styles['horizontal-divider']} />

          <div className={styles['flex-container']}>
            <img
              src={require('../../assets/pending-step2.svg')}
              alt="pending step 2"
              width={64}
              height={64}
            />
            <div>
              <b>
                本画面を閉じる
                <br />
              </b>
              <span>
                アプリのダウンロード完了後、下記のボタンをクリックして、本画面を閉じてください。
              </span>
              <p>※本画面が開いた状態では、購入が完了しません。</p>
            </div>
          </div>
          <button
            id="btn-pending-confirm-and-proceed"
            data-testid="btn-pending-confirm-and-proceed"
            className={cx('btn', styles.button)}
            onClick={() => onConfirmAndProceedClick()}
          >
            ダウンロード完了を確認して閉じる
          </button>
        </div>

        {!isWithinCICBusinessHours() && (
          <div
            className={styles['cic-hours-message']}
            id="pending-screen-cic-business-hours-message"
            data-testid="pending-screen-cic-business-hours-message"
          >
            <img
              src={require('../../assets/icon-info.svg')}
              alt="CIC info icon"
              width={32}
              height={32}
            />
            <span>
              深夜25:00（日曜日のみ22:00）から翌朝8:00の間にお申し込みの場合、結果のご連絡が8:00以降になります。
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pending;
