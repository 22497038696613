// @flow
import React from 'react';

import { onClose } from '../../utils';
import styles from './Error.scss';

const TokenAuthenticationExpired = () => (
  <>
    <div className={styles['scrollable-content']}>
      <b className={styles.title}>正しい認証コードを確認できませんでした</b>
      <p>ご入力いただいた認証コードに誤りがあるか、一定の時間が経過しています。</p>
      <p>お手数ですが、ペイディの画面を閉じて、もう一度お試しください。</p>
    </div>
    <div className={styles.controls}>
      <button className={styles.button} onClick={() => onClose()} data-testid="btn-close">
        閉じる
      </button>
    </div>
  </>
);

export default TokenAuthenticationExpired;
