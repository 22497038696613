// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ShadowScrollbar from './ShadowScrollbar';
import { updateUIPairs } from '../../redux/modules/ui';

const mapDispatchToProps = (dispatch: Function) => ({
  updateUIPairs: pairs => dispatch(updateUIPairs(pairs)),
});

export default withRouter(connect(null, mapDispatchToProps)(ShadowScrollbar));
