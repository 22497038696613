// @flow
import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import idx from 'idx';
import autobind from 'autobind-decorator';
import Frame from '../FrameDemo/FrameContainer';
import styles from './../../Token/TokenConfirm.scss';
import {
  MixpanelHelpers,
  MIXPANEL_ACTION_PAGE_VIEW,
  MIXPANEL_ACTION_NEXT,
} from '../../../utils/mixpanel';

type Props = {
  confirmToken: Function,
  updatePaymentPairs: Function,
  location: Object,
};

type State = {
  confirmedResult: ?string,
};

class TokenConfirm extends Component<Props, State> {
  state = {
    confirmedResult: undefined,
  };

  componentDidMount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }

  componentWillUnmount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      shouldEndTracker: true,
    });
  }

  @autobind
  onConfirmToken() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_NEXT,
    });

    this.props.confirmToken().then(res => {
      const status = idx(res, _ => _.data.status);

      this.props.updatePaymentPairs({ status });

      this.setState({
        confirmedResult: status === 'active' ? 'success' : 'fail',
      });
    });
  }

  render() {
    const { confirmedResult } = this.state;

    if (confirmedResult === 'success') {
      return <Redirect to="/result/token" />;
    } else if (confirmedResult === 'fail') {
      return <Redirect to="/result/token-rejected" />;
    }

    return (
      <Frame hasCornerBackButton={false} helpType="token">
        <div className={styles['scrollable-content']}>
          <b className={styles.title}>ペイディをお支払い方法に登録</b>
          <img
            className={styles.illustration}
            src={require('../../../assets/token-illustration.svg')}
            width="145"
            height="145"
            alt="confirm payment icon"
          />
        </div>
        <div className={styles.controls}>
          <button id="btn-next" className={styles.button} onClick={this.onConfirmToken}>
            登録
          </button>
        </div>
      </Frame>
    );
  }
}

export default withRouter(TokenConfirm);
