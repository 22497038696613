// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PlusRegular from './PlusRegular';
import { updateFormPairs } from '../../redux/modules/form';

const mapStateToProps = (state: {
  form: {
    city: string,
    line1: string,
    line2: ?string,
    state: string,
    zip: string,
    date_of_birth: string,
    first_name_kana: string,
    first_name_kanji: string,
    last_name_kana: string,
    last_name_kanji: string,
  },
  auth: {
    isReturning: boolean,
  },
}) => ({
  city: state.form.city,
  line1: state.form.line1,
  line2: state.form.line2,
  state: state.form.state,
  zip: state.form.zip,
  date_of_birth: state.form.date_of_birth,
  first_name_kana: state.form.first_name_kana,
  first_name_kanji: state.form.first_name_kanji,
  last_name_kana: state.form.last_name_kana,
  last_name_kanji: state.form.last_name_kanji,
  isReturning: state.auth.isReturning,
});

const mapDispatchToProps = (dispatch: Function) => ({
  updateFormPairs: pairs => dispatch(updateFormPairs(pairs)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlusRegular));
