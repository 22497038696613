import React from 'react';

import check from '../../assets/check-animated.gif';

import styles from './Success.scss';

const Success = () => {
  return (
    <div className={styles['success-wrapper']}>
      <div>
        <img src={check} alt="success check" width="128" height="128" />
        <b>ありがとうございます</b>
      </div>
    </div>
  );
};

export default Success;
