// @flow
import React, { Component } from 'react';
import type { ComponentType } from 'react';
import autobind from 'autobind-decorator';

type Props = Object;
type State = {
  errors: Object,
  touched: Object,
};

const withValidation = (validations: Object) => (WrappedComponent: ComponentType<any>) =>
  class Form extends Component<Props, State> {
    _isMounted: boolean;

    constructor(props: Props) {
      super(props);

      this.state = {
        errors: {},
        touched: {},
      };
    }

    componentDidMount() {
      this._isMounted = true;
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    @autobind
    onValidate({ name }: { name: string }) {
      // FIXME: delay the thread, but we can do better
      setTimeout(() => {
        const { touched } = this.state;

        if (name && typeof this.props[name] !== 'undefined') {
          touched[name] = true;

          if (this._isMounted) {
            this.setState({ touched });
            this.validate();
          }
        }
      }, 0);
    }

    @autobind
    validate({ checkTouch }: { checkTouch?: boolean } = { checkTouch: true }): Object {
      const errors: Object = {};
      const touched: Object = {};
      const newState: { errors: Object, touched?: Object } = { errors };

      Object.keys(validations).forEach((key: string) => {
        if (checkTouch) {
          if (!this.state.touched[key]) {
            return;
          }
        } else {
          touched[key] = true;
        }

        const validator = validations[key];
        const value = this.props[key];

        errors[key] = validator(value, this.props);
      });

      if (Object.keys(touched).length) {
        newState.touched = touched;
      }

      this.setState(newState);

      return errors;
    }

    // Manually set errors (e.g. errors from backend)
    @autobind
    setError(error: { [key: string]: string }) {
      // Append new errors
      const errors = { ...this.state.errors, ...error };

      this.setState({ errors });
    }

    render() {
      const { errors } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onValidateAll={this.validate}
          onValidate={this.onValidate}
          errors={errors}
          setError={this.setError}
          hasError={!!Object.keys(errors).find(key => !!errors[key])}
        />
      );
    }
  };

export default withValidation;
