import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DigitalRegular from './DigitalRegular';
import { updateFormPairs } from '../../redux/modules/form';

const mapStateToProps = state => ({
  city: state.form.city,
  line1: state.form.line1,
  line2: state.form.line2,
  state: state.form.state,
  zip: state.form.zip,
  first_name_kanji: state.form.first_name_kanji,
  last_name_kanji: state.form.last_name_kanji,
  isReturning: state.auth.isReturning,
  isToken: !!state.payment.token && state.payment.token.type === 'recurring',
  configuration: state.payment.configuration,
});

const mapDispatchToProps = dispatch => ({
  updateFormPairs: pairs => dispatch(updateFormPairs(pairs)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DigitalRegular)
);
