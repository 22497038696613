import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Frame from '../Frame/FrameContainer';
import {
  MixpanelHelpers,
  MIXPANEL_ACTION_NEXT,
  MIXPANEL_ACTION_PAGE_VIEW,
} from '../../utils/mixpanel';
import styles from './HighTicket.scss';

const HighTicket = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const merchantName: string = useSelector(state => state.merchant.shopName);

  useEffect(() => {
    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });

    return () => {
      MixpanelHelpers.trackDuration({
        pathname,
        actionName: MIXPANEL_ACTION_PAGE_VIEW,
        shouldEndTracker: true,
      });
    };
  }, []);

  const onButtonClick = () => {
    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_NEXT,
    });

    history.push('/3pay/select');
  };

  return (
    <Frame hasCornerBackButton={false} helpType="highTicket">
      <div className={styles['scrollable-content']}>
        <b className={styles.title} data-testid="high-ticket-merchant-name">
          {merchantName}でのお買い物を自由で安全に
        </b>
        <div className={styles.illustrations}>
          <img
            src={require('../../assets/high-ticket-digit-1.svg')}
            width="64"
            height="64"
            alt="step-1-digit"
          />
          <span>お支払い回数を選択</span>
          <img
            src={require('../../assets/high-ticket-illustration-1.svg')}
            width="64"
            height="64"
            alt="step-1-illustration"
          />
          <img
            src={require('../../assets/high-ticket-digit-2.svg')}
            width="64"
            height="64"
            alt="step-2-digit"
          />
          <span>最短5分で本人確認</span>
          <img
            src={require('../../assets/high-ticket-illustration-2.svg')}
            width="64"
            height="64"
            alt="step-2-illustration"
          />
          <img
            src={require('../../assets/high-ticket-digit-3.svg')}
            width="64"
            height="64"
            alt="step-3-digit"
          />
          <span>お買い物完了</span>
          <img
            src={require('../../assets/high-ticket-illustration-3.svg')}
            width="64"
            height="64"
            alt="step-3-illustration"
          />
        </div>
        <p className={styles.description}>
          {merchantName}でのお買い物の決済にペイディを選んでいただき、ありがとうございます。
          <b>ペイディアプリ</b>から本人確認を完了すると、{merchantName}
          でのお買い物を自由で安全に楽しめます。
          <br />
          <br />
          ・ご利用可能額の確認
          <br />
          ・分割手数料無料*の3・6回あと払い
          <br />
          <br />
          <span className={styles['secondary-text']}>*口座振替・銀行振込のみ無料</span>
          <br />
          <br />
          ぜひ本人確認を完了して、{merchantName}でのお買い物を、ペイディでかしこくお楽しみください。
        </p>
      </div>
      <div className={styles.controls}>
        <button
          onClick={onButtonClick}
          id="btn-next"
          className={styles.button}
          data-testid="high-ticket-next-button"
        >
          次へ
        </button>
      </div>
    </Frame>
  );
};

export default HighTicket;
