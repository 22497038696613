import idx from 'idx';

export const isV1 = (payload: Object): boolean => !!payload.merchant_data;

export const fromV1 = (payload: Object): ?Object => {
  if (!payload) {
    return undefined;
  }

  /**
   * Use idx only for optional fields
   */
  try {
    const newPayload = {};

    newPayload.amount = payload.order.total_amount;
    newPayload.version = 'v1';

    if (!payload.buyer) {
      newPayload.buyer = null;
    } else {
      const zip = idx(payload, _ => _.buyer.address.postal_code);

      newPayload.shipping_address = {
        line1: idx(payload, _ => _.buyer.address.address1),
        line2: idx(payload, _ => _.buyer.address.address2),
        city: idx(payload, _ => _.buyer.address.address3),
        state: idx(payload, _ => _.buyer.address.address4),
        zip,
      };

      const email = idx(payload, _ => _.buyer.email.address);
      const phone = idx(payload, _ => _.buyer.phone.number);
      const name1 = idx(payload, _ => _.buyer.name);

      newPayload.buyer = {
        email,
        phone,
        name1,
        name2: idx(payload, _ => _.buyer.name2),
        date_of_birth: idx(payload, _ => _.buyer.dob),
      };
    }

    /**
     * buyer_data itself is required, but the fields inside can be empty
     * the reason we mark the fields as required in our public document is because we keen to encourage the merchants to provide us as much data as possible for the payment so that we can have better risk accessment
     */
    newPayload.buyer_data = payload.merchant_data;
    newPayload.store_name = idx(payload, _ => _.merchant_data.store) || 'Paidy';

    newPayload.order = {
      items: payload.order.items.reduce(
        (accu, cur) =>
          accu.concat([
            {
              // id should be a [string], this is a workaround in case some merchant have this in number
              id: `${cur.item_id}`,
              description: `${cur.description}`,
              quantity: +cur.quantity,
              title: `${cur.title}`,
              unit_price: +cur.amount,
            },
          ]),
        []
      ),
      order_ref: idx(payload, _ => _.order.order_ref),
      shipping: idx(payload, _ => _.order.shipping),
      tax: idx(payload, _ => _.order.tax),
    };

    /**
     * there is no currency field in v1 config
     * so we simply use JPY for v1 merchants
     * as currency is necessary field in v2
     */
    newPayload.currency = 'JPY';
    newPayload.metadata = payload.metadata;

    return newPayload;
  } catch (error) {
    if (error) {
      console.error(error);
    }

    return undefined;
  }
};

export default {
  isV1,
  fromV1,
};
