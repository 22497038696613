// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Options from './Options';

const mapStateToProps = (state: Object): Object => ({
  number_of_installments: state.form.number_of_installments,
  totalAmount: state.payment.totalAmount,
  installmentOptions: state.payment.installmentOptions,
});

export default withRouter(
  connect(
    mapStateToProps,
    undefined
  )(Options)
);
