import React, { useEffect } from 'react';
import { MixpanelHelpers, MIXPANEL_ACTION_PAGE_VIEW } from '../../utils/mixpanel';

import styles from './PlusUpgradePending.scss';

const PlusUpgradeMoreThanTwoMinutes = () => {
  useEffect(() => {
    MixpanelHelpers.trackAction({
      customPath: 'Waiting for Plus Upgrade Result Delayed',
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }, []);

  return (
    <div className={styles['scrollable-content']}>
      <div className={styles['timer-img-container']}>
        <img
          src={require('../../assets/clock-timer@3x.png')}
          width="184"
          height="184"
          alt="clock-timer"
        />
      </div>
      <b className={styles.title}>画面を開いたままお待ちください</b>
      <span className={styles.body}>
        お待たせして大変申し訳ございません。まもなく完了しますので、画面を開いたまま、もう少々お待ちください。
      </span>
      <div className={styles['info-section']}>
        <img
          src={require('../../assets/icon-info-inverted.svg')}
          alt="CIC info icon"
          width={24}
          height={24}
        />
        <span>画面を閉じると購入が完了できません。ご注意ください。</span>
      </div>
    </div>
  );
};

export default PlusUpgradeMoreThanTwoMinutes;
