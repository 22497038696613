//@flow

let instanceID: ?string = null;
let instanceDomain: string = '*';

export default {
  setID(_id: string) {
    instanceID = _id;
  },

  setDomain(_domain: string) {
    instanceDomain = _domain;
  },

  postMessage(d: Object) {
    if (window.parent) {
      const data = d;

      if (instanceID) {
        data._id = instanceID;
      }

      window.parent.postMessage(data, instanceDomain);
    }
  },
};
