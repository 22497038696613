// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import Frame from '../Frame/FrameContainer';
import DigitalRegular from './DigitalRegularContainer';

const Digital = ({ onPatch, patchToken }: { onPatch: Function, patchToken: Function }) => (
  <Frame hasCornerBackButton={false} helpType="digital">
    <DigitalRegular onPatch={onPatch} patchToken={patchToken} />
  </Frame>
);

export default withRouter(Digital);
