// @flow
import React from 'react';
import { useLocation } from 'react-router-dom';

import styles from './Frame.scss';
import FrameNavigation from './FrameNavigation';

type Props = {
  hasCornerBackButton: ?boolean,
  hasCornerCloseButton: ?boolean,
  token: ?string,
  totalAmount: ?number,
  numberOfInstallments: ?number,
  monthlyAmount: ?number,
  showInstallments: ?boolean,
  shopName: ?string,
  logoURL: ?string,
};

const FrameHeader = (props: Props) => {
  const {
    // from parent
    hasCornerBackButton,
    hasCornerCloseButton,
  } = props;

  const { pathname } = useLocation();

  console.log('pathname', pathname);

  return (
    <header>
      <FrameNavigation
        hasCornerBackButton={hasCornerBackButton}
        hasCornerCloseButton={hasCornerCloseButton}
      />

      {pathname === '/' && (
        <div className={styles.introLogoContainer}>
          <img src={require('../../assets/paidy-logo.svg')} alt="Paidy logo" />
        </div>
      )}
    </header>
  );
};

export default FrameHeader;
