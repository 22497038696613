import React, { useEffect, SyntheticEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { MixpanelHelpers, MIXPANEL_ACTION_HELP } from '../../../utils/mixpanel';
import { updateUIPairs } from "../../../redux/modules/ui";

type Props = {
  helpType: ?string,
};

const FrameFooter = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (props.helpType) {
      dispatch(updateUIPairs({ helpType: props.helpType }));
    }
  }, []);

  const onClick = (event: SyntheticEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_HELP,
    });

    if (pathname === '/help') {
      history.goBack();
    } else {
      history.push('/help');
    }
  };

  return (
    <footer>
      <a href="#" id="link-help" onClick={onClick}>
        {pathname === '/help' ? '戻る' : 'ヘルプ'}
      </a>
    </footer>
  );
};

export default FrameFooter;
