// @flow
import React from 'react';
import styles from './Loading.scss';

import spinner from '../../assets/spinner.gif';

export default function Loading() {
  return (
    <div className={styles.wrapper}>
      <img src={spinner} alt="spinner" width="120" height="120" />
    </div>
  );
}
