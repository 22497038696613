// @@flow
const UPDATE_PAIRS = '@@checkout/merchant/update_pairs';

const initialState = {
  shopName: 'Paidy',
  logoURL: undefined,
  // Is this merchant high-ticket? (Expensive goods, and Checkout will ask consumers to upgrade to Plus)
  isHighTicket: false,
  keyId: '', // Used for High Ticket Merchant
  isRequiredPlusUpgrade: false,
};

const merchant = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAIRS: {
      return {
        ...state,
        ...action.pairs,
      };
    }
    default:
      return state;
  }
};

export const updateMerchantPairs = pairs => ({
  type: UPDATE_PAIRS,
  pairs,
});

export default merchant;
