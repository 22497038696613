// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import OTPInputDemo from './OTPInputDemo';

type Props = {
  email: string,
  phone: string,
  isReturning: boolean,
  tier: string,
  totalAmount: number,
  shopName: string,
  paymentId: string,
  pinCodeSentCount: number,
};

const mapStateToProps: Function = ({ auth, payment, ui }): Props =>
  ({
    email: auth.email,
    phone: auth.phone,
    isReturning: auth.isReturning,
    tier: payment.tier,
    paymentId: payment.paymentId,
    pinCodeSentCount: ui.pinCodeSentCount,
  }: Props);

export default withRouter(connect(mapStateToProps)(OTPInputDemo));
