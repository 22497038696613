// @flow
import autobind from 'autobind-decorator';
import cx from 'classnames';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import robustStorage from '../../../utils/robust-local-storage';
import Frame from '../FrameDemo/FrameContainer';
import ContentDemo from './ContentDemo';
import { tierMap } from '../../../constants';
import {
  MixpanelHelpers,
  MIXPANEL_ACTION_NEXT,
  MIXPANEL_ACTION_PAGE_VIEW,
} from '../../../utils/mixpanel';
import styles from './IntroDemo.scss';

type Props = {
  tier: ?string,
  history: Object,
};

const introPayLaterProps = {
  title: 'ペイディの使い方',
  line1: 'メールアドレスと携帯番号を入力',
  line2: 'SMSで届く4桁の認証コードを入力',
  line3: (
    <>
      翌月1日～5日にメールとSMSでご
      <br />
      請求金額をお知らせ
    </>
  ),
  line4: '翌月27日までに、コンビニ払い、銀行振込、口座振替でお支払い',
};

const introClassicProps = {
  title: 'ペイディのご利用方法',
  line1: 'メールアドレスと携帯番号を入力',
  line2: 'SMSで届く4桁の認証コードを入力',
  line3: (
    <>
      翌月1日～3日にメールとSMSで
      <br />
      ご請求金額をお知らせ
    </>
  ),
  line4: '翌月10日までに、コンビニ払い、銀行振込、口座振替でお支払い',
};

const introPlusProps = {
  title: 'ペイディ超あと払いのご利用方法',
  line1: 'メールアドレスと携帯番号を入力',
  line2: 'SMSで届く4桁の認証コードを入力',
  line3: '３か月後の1日～3日にメールとSMSでご請求金額をお知らせ',
  line4: (
    <>
      3か月後の10日までに、コンビニ払い、銀行振込、
      <br />
      口座振替でお支払い
    </>
  ),
};

class IntroDemo extends Component<Props, void> {
  componentWillMount() {
    const seenClassicIntro = robustStorage.getItem('paidy_seen_classic_intro') === 'true';
    const seenPlusIntro = robustStorage.getItem('paidy_seen_plus_intro') === 'true';

    if (
      (this.props.tier !== tierMap.PLUS && seenClassicIntro) ||
      (this.props.tier === tierMap.PLUS && seenPlusIntro)
    ) {
      this.props.history.push('/login');
    }
  }

  componentDidMount() {
    const isPlus = this.props.tier === tierMap.PLUS;
    const customPath = isPlus ? 'Intro (Plus)' : 'Intro (Classic)';

    if (isPlus) {
      robustStorage.setItem('paidy_seen_plus_intro', 'true');
    } else {
      robustStorage.setItem('paidy_seen_classic_intro', 'true');
    }

    MixpanelHelpers.trackAction({
      customPath,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });

    MixpanelHelpers.trackDuration({
      customPath,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }

  componentWillUnmount() {
    const isPlus = this.props.tier === tierMap.PLUS;
    const customPath = isPlus ? 'Intro (Plus)' : 'Intro (Classic)';

    MixpanelHelpers.trackDuration({
      customPath,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      shouldEndTracker: true,
    });
  }

  @autobind
  onButtonClick() {
    const isPlus = this.props.tier === tierMap.PLUS;
    const customPath = isPlus ? 'Intro (Plus)' : 'Intro (Classic)';

    // If Remember Me is enabled, the Login screen is skipped (auto-login).
    // If the consumer went back to this screen then presses the next button,
    // we want the Login screen to show.
    robustStorage.setItem('paidy_remember_me', 'false');

    MixpanelHelpers.trackAction({
      customPath,
      actionName: MIXPANEL_ACTION_NEXT,
    });

    this.props.history.push('/login');
  }

  render() {
    // Uncomment the following 3 lines after PayLater Integration ( When it's ready to continue Checkout Demo )
    // const { tier } = this.props;
    // const isPlus = tier === tierMap.PLUS;
    // const contentProps = isPlus ? introPlusProps : introClassicProps;

    const contentProps = introPayLaterProps;

    return (
      <Frame hasCornerBackButton={false}>
        <div className={styles['scrollable-content']}>
          <ContentDemo {...contentProps} />
        </div>
        <div className={styles.controls}>
          <button id="btn-next" className={cx('btn', styles.button)} onClick={this.onButtonClick}>
            次へ
          </button>
        </div>
      </Frame>
    );
  }
}

export default withRouter(IntroDemo);
