// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import IntroDemo from '../../Demo/IntroDemo/IntroDemo';

type Props = {
  tier: ?string,
};

const FirstScreenDemo = ({ tier }: Props) => <IntroDemo tier={tier} />;

export default withRouter(FirstScreenDemo);
