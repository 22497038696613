// @flow
import React from 'react';
import cx from 'classnames';
import moment from 'moment';

import styles from './Option.scss';
import type { ThreePayOption } from './type';
import { hanToZen } from '../../utils';

type Props = {
  option: ThreePayOption & { disabled?: boolean },
  isSelected: boolean,
  onSelectOption?: (option: ThreePayOption) => void,
};

const getDateDescription = (option: ThreePayOption) => {
  const installmentsCount = option.installments.length;

  if (installmentsCount > 1) {
    return `${moment(option.installments[0].due_date).format('M月D日')} - ${moment(
      option.installments[installmentsCount - 1].due_date
    ).format('M月D日')}`;
  }

  return moment(option.installments[0].due_date).format('M月D日');
};

const Option = ({ option, isSelected, onSelectOption }: Props) => {
  // Pick a smaller amount for display
  // See https://paidy-portal.atlassian.net/browse/BDI-7
  const installmentPrice = option.installments.reduce(
    (min, opt) => Math.min(min, opt.amount),
    option.installments[0].amount
  );
  const isOptionDisabled = option.disabled;

  return (
    <div
      data-testid={`npay-select-option-${option.kind}`}
      role="button"
      tabIndex={0}
      className={cx(
        styles.container,
        isSelected && styles.active,
        isOptionDisabled && styles.disabled
      )}
      onClick={!isOptionDisabled ? () => onSelectOption(option) : undefined}
      onKeyDown={
        !isOptionDisabled
          ? e => {
              if (e.key === 'Enter') {
                onSelectOption(option);
              }
            }
          : undefined
      }
    >
      <span>
        <b className={styles.amount} data-testid="npay-select-option-price-amount">
          {installmentPrice.toLocaleString('ja-jp')}
        </b>
        <b>円/月 × {hanToZen(option.installments.length.toString())}回</b>
      </span>
      <span className={styles.description} data-testid="npay-select-option-due-date">
        {getDateDescription(option)}
      </span>
      <div className={cx(styles['check-container'], isSelected && styles.checked)} />
    </div>
  );
};

export default Option;
