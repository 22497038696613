// @flow
/**
 * @fileoverview This file expose an interface of localStorage to other modules.
 * The prupose is because localStorage is not available in some scenario.
 * Such as legacy browsers, blocked by user setting or browser extensions.
 */

export default {
  setItem(key: string, value: any): void {
    try {
      if (typeof value === 'string') {
        return localStorage.setItem(key, value);
      }
    } catch (error) {
      console.error('Some issues with localStorage.');
    }

    return undefined;
  },

  getItem(key: string): ?string {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      console.error('Some issues with localStorage.');
    }

    return undefined;
  },

  removeItem(key: string): void {
    try {
      return localStorage.removeItem(key);
    } catch (error) {
      console.error('Some issues with localStorage.');
    }

    return undefined;
  },
};
