// @flow
import React from 'react';

import styles from './IntroDemo.scss';

type Props = {
  title: string,
  line1: string,
  line2: string,
  line3: string,
  line4: string,
};

function ContentDemo({ title, line1, line2, line3, line4 }: Props) {
  return (
    <>
      <b className={styles.title}>{title}</b>
      <div className={styles.illustrations}>
        <img src={require('../../../assets/intro-step1.svg')} width="47" height="47" alt="email" />
        <span>{line1}</span>
        <img src={require('../../../assets/intro-step2.svg')} width="47" height="47" alt="sms" />
        <span>{line2}</span>
        <img
          src={require('../../../assets/intro-step3.svg')}
          width="47"
          height="47"
          alt="payment"
        />
        <span>{line3}</span>
        <img
          src={require('../../../assets/intro-step4-paylater.svg')}
          width="47"
          height="47"
          alt="calendar"
        />
        <span>{line4}</span>
      </div>
    </>
  );
}

export default ContentDemo;
