// @flow
/* eslint-disable camelcase, jsx-a11y/label-has-for */
import React from 'react';
import cx from 'classnames';

import styles from './Checkbox.scss';

type Props = {
  id: string,
  name: string,
  checked: boolean,
  onChange: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  title: string,
  className?: string,
};

export default function Checkbox({ id, name, checked, onChange, title, className }: Props) {
  return (
    <label htmlFor={id} className={cx(styles.checkbox, className)}>
      <input type="checkbox" id={id} name={name} checked={checked} onChange={onChange} />
      <span>{title}</span>
      <i className={styles.icon} />
    </label>
  );
}

Checkbox.defaultProps = {
  className: '',
};
