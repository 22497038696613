// @flow
import { isValidPhoneNumber, getParsedPhoneNumber } from '../../utils';

export const isEmpty = (input: string): boolean =>
  !input || typeof input !== 'string' || !input.trim().length;

const EMPTY_MESSAGE = '必須項目です';
const SELECT_EMPTY_MESSAGE = '選択してください';

const validators = {
  email: (email: string) => {
    const re = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;

    if (isEmpty(email)) {
      return EMPTY_MESSAGE;
    }

    if (!re.test(email)) {
      return 'メールアドレスを正しく入力してください';
    }

    return null;
  },
  phone: (phone: string) => {
    if (isEmpty(phone)) {
      return EMPTY_MESSAGE;
    }

    const parsed = getParsedPhoneNumber(phone);

    if (
      !isValidPhoneNumber(phone) ||
      // Only accepts numeric characters (and dash)
      !/^(\d|-)+$/.test(phone) ||
      // only accepts mobile, non-virtual phone numbers
      !/^(819|818|817)/.test(parsed)
    ) {
      return '携帯電話の番号を入力してください';
    }

    return null;
  },
  number: (number: string) => {
    if (isEmpty(number)) {
      return EMPTY_MESSAGE;
    }

    if (!/^\d+$/.test(number)) {
      return '半角数字で入力してください';
    }

    return null;
  },
  income: (income: string) => {
    if (isEmpty(income)) {
      return EMPTY_MESSAGE;
    }

    if (!/^(0|[1-9]\d*)$/.test(income)) {
      return '半角数字で入力してください';
    }

    return null;
  },
  zip: (zip: string) => {
    if (isEmpty(zip)) {
      return EMPTY_MESSAGE;
    }

    if (!/^\d{7}$/.test(zip)) {
      return '郵便番号に誤りがあります';
    }

    return null;
  },
  empty: (input: string) => {
    if (input === '-1') {
      return SELECT_EMPTY_MESSAGE;
    }

    if (isEmpty(input)) {
      return EMPTY_MESSAGE;
    }

    return null;
  },
  gender: (gender: string) => {
    if (['male', 'female'].indexOf(gender) < 0) {
      return SELECT_EMPTY_MESSAGE;
    }

    return null;
  },
  maritalStatus: (maritalStatus: string) => {
    if (['married', 'single'].indexOf(maritalStatus) < 0) {
      return SELECT_EMPTY_MESSAGE;
    }

    return null;
  },
  householdSize: (householdSize: string) => {
    if (!householdSize || +householdSize < 1 || +householdSize > 4) {
      return SELECT_EMPTY_MESSAGE;
    }

    return null;
  },
  residenceType: (residenceType: string) => {
    if (
      ['rented_by_self', 'rented_by_company', 'owned_by_self', 'owned_by_family'].indexOf(
        residenceType
      ) < 0
    ) {
      return SELECT_EMPTY_MESSAGE;
    }

    return null;
  },
  mortgage: (mortgage: boolean) => {
    if ([true, false].indexOf(mortgage) < 0) {
      return SELECT_EMPTY_MESSAGE;
    }

    return null;
  },
  occupation: (occupation: string) => {
    if (
      ['employed-full-time', 'employed-part-time', 'housewife', 'unemployed', 'student'].indexOf(
        occupation
      ) < 0
    ) {
      return EMPTY_MESSAGE;
    }

    return null;
  },
  dateOfBirth: (dateOfBirth: string) => {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dateOfBirth)) {
      return '生年月日を正しく入力してください';
    }

    const today = new Date();
    const enteredDate = new Date(dateOfBirth);

    if (enteredDate > today) {
      return '入力された生年月日ではお申込みいただけません';
    }

    if (enteredDate < today.setFullYear(today.getFullYear() - 100)) {
      return '入力された生年月日ではお申込みいただけません';
    }

    return null;
  },
  headOfHousehold: (headOfHousehold: string) => {
    if (['1', '2', '3', '4+', 'n/a'].indexOf(headOfHousehold) < 0) {
      return EMPTY_MESSAGE;
    }

    return null;
  },
  rentOrLoan: (rentOrLoan: boolean) => {
    if ([true, false].indexOf(rentOrLoan) < 0) {
      return EMPTY_MESSAGE;
    }

    return null;
  },
  katakana: (katakana: string) => {
    if (isEmpty(katakana)) {
      return EMPTY_MESSAGE;
    }

    /**
     * 1. Consumer must be able to enter hankaku or zenkaku.
     * 2. Kana name can contain katakana and the  “ー”.
     * 3. Alphabetic characters are "not" allowed.
     * regex ref: https://gist.github.com/oanhnn/9043867
     */
    if (!/^([ァ-ンｧ-ﾝﾞﾟ]|ー)+$/.test(katakana)) {
      return 'カタカナで入力してください';
    }

    return null;
  },
  kanji: (kanji: string) => {
    if (isEmpty(kanji)) {
      return EMPTY_MESSAGE;
    }

    /**
     * 1. Kanji name can contain kanji, hiragana, katakana, alphabet and the “-” or “ー”.
     * 2. Consumer must be able to enter hankaku or zenkaku.
     * 3. Alphabetic characters are allowed.
     * regex ref: https://gist.github.com/oanhnn/9043867
     */
    if (!/^([ぁ-んァ-ンｧ-ﾝﾞﾟ一-龥A-Za-ｚ]|[\u4E00-\u9FAF]|-|ー)+$/.test(kanji)) {
      return '数字や記号 (%,*,#) は入力できません';
    }

    return null;
  },
};

export default validators;
