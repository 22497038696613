// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BigSingle from './BigSingle';
import { updateFormPairs } from '../../redux/modules/form';
import { updateUIPairs } from '../../redux/modules/ui';

const mapStateToProps = state => ({
  city: state.form.city,
  line1: state.form.line1,
  line2: state.form.line2,
  state: state.form.state,
  zip: state.form.zip,
  date_of_birth: state.form.date_of_birth,
  dob_year: state.form.dob_year,
  dob_month: state.form.dob_month,
  dob_day: state.form.dob_day,
  first_name_kana: state.form.first_name_kana,
  first_name_kanji: state.form.first_name_kanji,
  last_name_kana: state.form.last_name_kana,
  last_name_kanji: state.form.last_name_kanji,
  loadingType: state.ui.loadingType,
  // auth
  isReturning: state.auth.isReturning,
});

const mapDispatchToProps = (dispatch: Function) => ({
  updateFormPairs: pairs => dispatch(updateFormPairs(pairs)),
  updateUIPairs: pairs => dispatch(updateUIPairs(pairs)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BigSingle)
);
