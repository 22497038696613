// @flow
import React from 'react';

import { onClose } from '../../utils';
import styles from './Error.scss';

const PaymentOrderItemForbidden = () => (
  <>
    <div className={styles['scrollable-content']}>
      <b className={styles.title}>今回のお支払いは承認されませんでした</b>
      <p>
        ペイディご利用対象外の内容が含まれています。恐れ入りますが、他のお支払い方法をお選び下さい。
        今回承認とならなかった理由の詳細はお伝えいたしかねますが、ご了承ください。
      </p>
    </div>
    <div className={styles.controls}>
      <button className={styles.button} onClick={() => onClose()} data-testid="btn-close">
        他の支払い方法を選ぶ
      </button>
    </div>
  </>
);

export default PaymentOrderItemForbidden;
