// @flow
import React, { useEffect } from 'react';
import idx from 'idx';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isDesktop } from 'react-device-detect';

import { INTERNAL_ERROR_TYPES, BAD_REQUEST_TYPES } from '../../constants';
import { errorCodes, errorCodecV2, emailReminderCodes } from '../../constants/error-codes';
import Frame from '../Frame/FrameContainer';
import { onClose } from '../../utils';
import styles from '../App/App.scss';

import TokenAuthenticationExpired from './TokenAuthenticationExpired';
import PaymentOrderItemForbidden from './PaymentOrderItemForbidden';
import ConsumerPhoneMismatch from './ConsumerPhoneMismatch';
import ConsumerEmailMismatch from './ConsumerEmailMismatch';
import BadRequest from './BadRequest';
import PaymentAuthenticationFailed from './PaymentAuthenticationFailed';
import HoukatsuPreviouslyRejected from './HoukatsuPreviouslyRejected';
import KYCRequired from './KYCRequired';

import ErrorContentMobile from './ErrorContentMobile';
import ErrorContentDesktop from './ErrorContentDesktop';

import {
  MixpanelHelpers,
  MIXPANEL_ACTION_PAGE_VIEW,
  MIXPANEL_ACTION_LINK_CLICK,
  MIXPANEL_ACTION_CLICK,
} from '../../utils/mixpanel';
import errorScreenContentMobile from './error-screen-content-mobile';
import errorScreenContentDesktop from './error-screen-content-desktop';

const Error = ({
  history,
  match,
  code,
  shopName,
  totalAmount,
  merchantConfig,
  merchantPayload,
}: {
  history: Object,
  match: Object,
  code: string,
  totalAmount: number,
  shopName: string,
  merchantConfig: Object,
  merchantPayload: Object,
}) => {
  const errorCode = code || idx(match, _ => _.params.type);
  const { pathname } = history.location;
  const ErrorContent = isDesktop ? ErrorContentDesktop : ErrorContentMobile;
  const errorScreenContent = isDesktop ? errorScreenContentDesktop : errorScreenContentMobile;
  let Content = () => <></>;
  let isGenericScreen = false;

  switch (true) {
    // TODO: Remove old codes after some time since it is going
    //       to be deprecated confirm with Payments team (Leonardo)
    /*<OLD ERROR CODES>*/
    case errorCode === errorCodes.TOKEN_AUTHENTICATION_EXPIRED: {
      Content = () => <TokenAuthenticationExpired />;

      break;
    }
    case errorCode === errorCodes.PAYMENT_ORDER_ITEM_FORBIDDEN: {
      Content = () => <PaymentOrderItemForbidden />;

      break;
    }
    // TODO: add errorCode === '404' (resource not found)
    case errorCode === errorCodes.CONSUMER_PHONE_MISMATCH: {
      Content = () => <ConsumerPhoneMismatch />;

      break;
    }
    case errorCode === errorCodes.TOKEN_AUTHENTICATION_FAILED:
    case errorCode === errorCodes.PAYMENT_AUTHENTICATION_FAILED: {
      Content = () => <PaymentAuthenticationFailed />;

      break;
    }
    case errorCode === errorCodes.CONSUMER_EMAIL_MISMATCH: {
      Content = () => <ConsumerEmailMismatch />;

      break;
    }
    case errorCode === errorCodes.HOUKATSU_PREVIOUSLY_REJECTED: {
      Content = () => <HoukatsuPreviouslyRejected />;

      break;
    }
    case errorCode === errorCodes.KYC_REQUIRED: {
      Content = () => <KYCRequired />;

      break;
    }
    // internal error
    case INTERNAL_ERROR_TYPES.indexOf(errorCode) > -1:
    case BAD_REQUEST_TYPES.indexOf(errorCode) > -1: {
      Content = () => <BadRequest />;

      break;
    }
    /*</OLD ERROR CODES>*/

    /*<NEW ERROR CODES>*/
    // Overdue bill
    // TODO: remove CONSUMER_BILL_STATEMENT_OVERDUE
    case errorCode === errorCodes.CONSUMER_BILL_STATEMENT_OVERDUE:
    case errorCode === errorCodecV2.OVERDUE_BILL: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.OVERDUE_BILL]}
            onClick={() => {
              onClose();
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                actionItem: 'Pay bill',
              });
            }}
          />
        );
      };
      break;
    }

    // Over Plus limit - cannot retry
    case errorCode === errorCodecV2.EXCEEDED_PLUS_LIMIT:
    case errorCode === errorCodecV2.EXCEEDED_NLP_LIMIT_X1: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[
              errorCode === errorCodecV2.EXCEEDED_PLUS_LIMIT
                ? errorCodecV2.EXCEEDED_PLUS_LIMIT
                : errorCodecV2.EXCEEDED_NLP_LIMIT_X1
            ]}
            onClick={() => {
              onClose();
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                actionItem: 'Check limit',
                extraData: {
                  'Merchant Config': merchantConfig,
                  'Store Name': shopName,
                  'Order Amount': totalAmount,
                  'Merchant Payload': merchantPayload,
                  Type: errorCode === errorCodecV2.EXCEEDED_NLP_LIMIT_X1 ? 'NLP 1x' : 'Plus',
                },
              });
            }}
          />
        );
      };
      break;
    }

    // Over Plus limit - can retry
    case errorCode === errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE:
    case errorCode === errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE_X1: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[
              errorCode === errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE
                ? errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE
                : errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE_X1
            ]}
            onClick={() => {
              onClose();
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                actionItem: 'PayNow',
                extraData: {
                  'Merchant Config': merchantConfig,
                  'Store Name': shopName,
                  'Order Amount': totalAmount,
                  'Merchant Payload': merchantPayload,
                  Type:
                    errorCode === errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE_X1 ? 'NLP 1x' : 'Plus',
                },
              });
            }}
          />
        );
      };
      break;
    }

    case errorCode === errorCodecV2.MERCHANT_REQUIRES_KYC: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.MERCHANT_REQUIRES_KYC]}
            onClick={() => {
              onClose();
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                actionItem: 'eKYC',
                extraData: {
                  'Merchant Config': merchantConfig,
                  'Store Name': shopName,
                  'Order Amount': totalAmount,
                  'Merchant Payload': merchantPayload,
                },
              });
            }}
          />
        );
      };
      break;
    }

    case errorCode === errorCodecV2.MERCHANT_REQUIRES_HOSHINO: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.MERCHANT_REQUIRES_HOSHINO]}
            onClick={() => {
              onClose();
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                actionItem: 'eKYC',
                extraData: {
                  'Merchant Config': merchantConfig,
                  'Store Name': shopName,
                  'Order Amount': totalAmount,
                  'Merchant Payload': merchantPayload,
                },
              });
            }}
          />
        );
      };
      break;
    }

    case errorCode === errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE]}
            onClick={() => {
              onClose();
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                actionItem: 'Boost',
                extraData: {
                  'Merchant Config': merchantConfig,
                  'Store Name': shopName,
                  'Order Amount': totalAmount,
                  'Merchant Payload': merchantPayload,
                },
              });
            }}
          />
        );
      };
      break;
    }

    case errorCode === errorCodecV2.EXCEEDED_NLP_LIMIT: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.EXCEEDED_NLP_LIMIT]}
            onClick={() => {
              onClose();
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                actionItem: 'Check limit',
                extraData: {
                  'Merchant Config': merchantConfig,
                  'Store Name': shopName,
                  'Order Amount': totalAmount,
                  'Merchant Payload': merchantPayload,
                },
              });
            }}
          />
        );
      };
      break;
    }

    case errorCode === errorCodecV2.HOUKATSU_REJECTED: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.HOUKATSU_REJECTED]}
            onClick={() => {
              onClose();
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_CLICK,
                actionItem: 'Change payment method',
              });
            }}
          />
        );
      };
      break;
    }

    case errorCode === errorCodecV2.PLUS_UPGRADE_KYC_REJECTED: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.PLUS_UPGRADE_KYC_REJECTED]}
            onClick={() => {
              onClose();
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_CLICK,
                actionItem: 'Retry checkout',
              });
            }}
          />
        );
      };
      break;
    }

    case errorCode === errorCodecV2.PLUS_UPGRADE_TIMEOUT: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.PLUS_UPGRADE_TIMEOUT]}
            onClick={() => {
              onClose();
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_CLICK,
                actionItem: 'Return to merchant',
              });
            }}
          />
        );
      };
      break;
    }

    case errorCode === errorCodecV2.PLUS_UPGRADE_OUTSIDE_CIC_HOURS: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.PLUS_UPGRADE_OUTSIDE_CIC_HOURS]}
            onClick={() => {
              onClose();
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_CLICK,
                actionItem: 'Change payment method',
              });
            }}
          />
        );
      };
      break;
    }

    case errorCode === emailReminderCodes.PHONE_24H_ATTEMP: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return <ErrorContent {...errorScreenContent[emailReminderCodes.PHONE_24H_ATTEMP]} />;
      };
      break;
    }

    case errorCode === emailReminderCodes.OTP_MORE_THEN_3_ATTEMPS: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return <ErrorContent {...errorScreenContent[emailReminderCodes.OTP_MORE_THEN_3_ATTEMPS]} />;
      };
      break;
    }

    case errorCode === emailReminderCodes.OTP_MAX_RETRIES: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return <ErrorContent {...errorScreenContent[emailReminderCodes.OTP_MAX_RETRIES]} />;
      };
      break;
    }

    case errorCode === emailReminderCodes.OTP_UNAVAILABLE: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return <ErrorContent {...errorScreenContent[emailReminderCodes.OTP_UNAVAILABLE]} />;
      };
      break;
    }

    case errorCode === emailReminderCodes.OTP_UNAUTHORIZED: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return <ErrorContent {...errorScreenContent[emailReminderCodes.OTP_UNAUTHORIZED]} />;
      };
      break;
    }

    /*</NEW ERROR CODES>*/
    case errorCode === errorCodecV2.GENERIC:
    default: {
      // eslint-disable-next-line react/display-name
      isGenericScreen = true;
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.GENERIC]}
            onClick={() => {
              onClose();
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
              });
            }}
          />
        );
      };
      break;
    }
  }

  let extraData = {};

  if (
    errorCode === errorCodecV2.EXCEEDED_NLP_LIMIT_X1 ||
    errorCode === errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE_X1
  ) {
    extraData = {
      Type: 'NLP 1x',
    };
  } else if (
    errorCode === errorCodecV2.EXCEEDED_PLUS_LIMIT ||
    errorCode === errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE
  ) {
    extraData = {
      Type: 'Plus',
    };
  }

  MixpanelHelpers.trackAction({
    pathname,
    actionName: MIXPANEL_ACTION_PAGE_VIEW,
    extraData,
  });

  useEffect(() => {
    if (isDesktop) {
      const mainAppContent = document.getElementById('main-app-content-height');
      if (
        mainAppContent &&
        (errorCode === errorCodecV2.EXCEEDED_NLP_LIMIT ||
          errorCode === errorCodecV2.EXCEEDED_PLUS_LIMIT)
      ) {
        mainAppContent.classList.add(styles['main-app-content-extend-height']);
      } else if (mainAppContent && errorCode === errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE) {
        mainAppContent.classList.add(styles['main-app-content-extend-height-retriable']);
      }
    }
  }, []);

  return (
    <Frame
      hasFooter={
        errorScreenContent[errorCode] ? errorScreenContent[errorCode].hasFooter : !isGenericScreen
      }
      hasCornerBackButton={false}
      hideShopInfo={
        errorScreenContent[errorCode] ? errorScreenContent[errorCode].hideShopInfo : isGenericScreen
      }
      footerText={
        errorScreenContent[errorCode] ? errorScreenContent[errorCode].footerText : undefined
      }
      footerHref={
        errorScreenContent[errorCode] ? errorScreenContent[errorCode].footerHref : undefined
      }
      footerOnClick={
        errorScreenContent[errorCode] && errorScreenContent[errorCode].footerOnClick
          ? errorScreenContent[errorCode].footerOnClick
          : undefined
      }
    >
      <Content />
    </Frame>
  );
};

const mapStateToProps = state => ({
  shopName: state.merchant.shopName,
  totalAmount: state.payment.totalAmount,
});

export default withRouter(connect(mapStateToProps)(Error));
