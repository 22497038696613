// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TOC from './TOC';

const mapStateToProps: Function = (state: Object): { toc: Object } => ({
  toc: state.policy.toc,
  tier: state.payment.tier,
  isBig: state.payment.isBig,
  isMulti: state.form.number_of_installments && state.form.number_of_installments > 1,
});

export default withRouter(connect(mapStateToProps, undefined)(TOC));
