// @flow
import React from 'react';

import { onClose } from '../../utils';
import styles from './Error.scss';

const PaymentRejected = () => (
  <>
    <div className={styles['scrollable-content']}>
      <b className={styles.title}>今回のお支払いは承認されませんでした</b>
      <p>恐れ入りますが、他の支払い方法をお選びください。</p>
      <p>今回、承認とならなかった理由の詳細はお伝えいたしかねますので、ご了承ください。</p>
    </div>
    <div className={styles.controls}>
      <button className={styles.button} onClick={() => onClose()} data-testid="btn-close">
        他の支払い方法を選ぶ
      </button>
    </div>
  </>
);

export default PaymentRejected;
