export const tocMap = {
  SINGLE: '2G4Vn9ewYAD1B0otubVpx2',
  MULTI: '2gpyJI6a80gY0AGQyeWiSW',
  PLUS: 'wmP97LGDM4GeyKQ8UmG8c',
  PRIVACY: '4U3RcA6rksqsQ8ow2ECE8M',
};

export default {
  tocMap,
};
