import React, { useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { MixpanelHelpers, MIXPANEL_ACTION_PAGE_VIEW } from '../../utils/mixpanel';

import styles from './PlusUpgradePending.scss';

type Props = {
  onClose: Function,
  visible: boolean,
  currentPendingState?: number,
};

const PlusUpgradeSucessModal = (props: Props) => {
  const DISPLAY_TIME = 2000; // 2 seconds

  useEffect(() => {
    if (!props.visible) return;
    MixpanelHelpers.trackAction({
      customPath: 'Plus Upgrade Success Modal',
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      extraData: {
        "Screen type": props.currentPendingState === 1 ? 'Waiting for Plus Upgrade Result Video' : 'Waiting for Plus Upgrade Result Delayed'
      }
    });
    setTimeout(() => {
      if (props.onClose) props.onClose();
    }, DISPLAY_TIME);
  }, [props.visible]);

  if (!props.visible) {
    return null;
  }

  return (
    <div
      className={styles['modal']}
      style={
        isDesktop
          ? props.currentPendingState === 1
            ? {
                marginTop: 156,
                maxHeight: 588,
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
              }
            : {}
          : {}
      }
    >
      <div className={styles['modal-content']}>
        <div className={styles['modal-content-icon']}>
          <img
            src={require('../../assets/circled-success.svg')}
            width="138"
            height="138"
            alt="circled-success"
          />
        </div>
        <b className={styles['modal-content-message']}>本人確認が完了しました</b>
      </div>
    </div>
  );
};

export default PlusUpgradeSucessModal;
