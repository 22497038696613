import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import Error from '../Error/Error';

type Props = {};

type State = {
  hasError: boolean,
  errorCode: string,
};

export default class ErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false,
    errorCode: '',
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, errorCode: 'service-exception' });

    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });

    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return <Error code={this.state.errorCode} />;
    }

    return this.props.children;
  }
}
