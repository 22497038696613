// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FirstScreenDemo from './FirstScreenDemo';
import { tocMap } from '../../../constants/toc';

const mapStateToProps = (
  state: Object
): {
  fromMerchant: boolean,
  email: string,
  phone: string,
  tier: string,
  tocSingleVersion: ?string,
  tocPlusVersion: ?string,
} => ({
  fromMerchant: state.auth.fromMerchant,
  email: state.auth.email,
  phone: state.auth.phone,
  tier: state.payment.tier,
  tocSingleVersion:
    state.policy.toc && state.policy.toc[tocMap.SINGLE] && state.policy.toc[tocMap.SINGLE].version,
  tocPlusVersion:
    state.policy.toc && state.policy.toc[tocMap.PLUS] && state.policy.toc[tocMap.PLUS].version,
});

export default withRouter(
  connect(
    mapStateToProps,
    undefined
  )(FirstScreenDemo)
);
