// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import Intro from '../../components/Intro/Intro';

type Props = {
  tier: ?string,
};

const FirstScreen = ({ tier }: Props) => <Intro tier={tier} />;

export default withRouter(FirstScreen);
