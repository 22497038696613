// flow
/* eslint-disable react/no-danger */
import cx from 'classnames';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

import Frame from '../Frame/FrameContainer';
import { tierMap } from '../../constants'; // get lower case tier string
import { tocMap } from '../../constants/toc'; // to get contentful post id
import { tierTypes } from '../../types';
import { MixpanelHelpers, MIXPANEL_ACTION_PAGE_VIEW, MIXPANEL_ACTION_BACK } from '../../utils/mixpanel';
import styles from './TOC.scss';

type Props = {
  tocContent: Object,
};

const TOCContent = ({ tocContent }: Props) => (
  <div dangerouslySetInnerHTML={{ __html: tocContent && tocContent.content }} />
);

// for Single, need to merge Single T&C and Privacy Policy into one page
const TOCContentSingle = ({ toc }: { toc: Object }) => (
  <>
      <div dangerouslySetInnerHTML={{ __html: toc[tocMap.SINGLE] && toc[tocMap.SINGLE].content }} />
      <b>個人情報保護に関する基本方針（プライバシーポリシー）</b>
      <div
        dangerouslySetInnerHTML={{ __html: toc[tocMap.PRIVACY] && toc[tocMap.PRIVACY].content }}
      />
  </>
);

const getTOCByType = ({
  tier,
  toc,
  isMulti,
  isBig,
}: {
  tier: tierTypes,
  toc: Object,
  isMulti: boolean,
  isBig: boolean,
}) => {
  const locationName = 'T&C';

  if (isMulti || isBig) {
    MixpanelHelpers.trackAction({
      customPath: locationName,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      extraData: {
        'Event Type': 'Multipay',
      },
    });

    return <TOCContent tocContent={toc[tocMap.MULTI]} />;
  }

  switch (tier) {
    case tierMap.CLASSIC: {
      MixpanelHelpers.trackAction({
        customPath: locationName,
        actionName: MIXPANEL_ACTION_PAGE_VIEW,
        extraData: {
          'Event Type': 'Single',
        },
      });

      return <TOCContentSingle toc={toc} />;
    }
    case tierMap.PLUS: {
      MixpanelHelpers.trackAction({
        customPath: locationName,
        actionName: MIXPANEL_ACTION_PAGE_VIEW,
        extraData: {
          'Event Type': 'Plus',
        },
      });

      return <TOCContent tocContent={toc[tocMap.PLUS]} />;
    }
    default: {
      MixpanelHelpers.trackAction({
        customPath: locationName,
        actionName: MIXPANEL_ACTION_PAGE_VIEW,
        extraData: {
          'Event Type': 'Single',
        },
      });

      return <TOCContentSingle toc={toc} />;
    }
  }
};

const TOC = ({ tier, toc, isMulti, isBig }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const onBack = () => {
    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_BACK,
    });

    history.goBack();
  };

  return (
    <Frame
      hasCornerCloseButton={false}
      hasFooter={false}
    >
      <div className={styles['scrollable-content']}>
        <b className={styles.title}>Paidyの利用規約・個人情報取扱に関する同意条項</b>
        {getTOCByType({tier, toc, isMulti, isBig})}
      </div>
      <div className={styles.controls}>
        <button
          id="btn-back"
          className={cx('btn', styles.button)}
          onClick={onBack}
        >
          {isMulti ? '契約内容に戻る' : 'お支払いに戻る'}
        </button>
      </div>
    </Frame>
  );
};

export default TOC;
