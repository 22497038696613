import React, { useEffect } from 'react';
import Loading from '../Loading/Loading';
import { MixpanelHelpers, MIXPANEL_ACTION_PAGE_VIEW } from '../../utils/mixpanel';

import styles from './PlusUpgradePending.scss';

const PlusUpgradeLoader = () => {
  useEffect(() => {
    MixpanelHelpers.trackAction({
      customPath: 'Payment Loader',
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }, []);

  return (
    <div className={styles['scrollable-content']}>
      <Loading />
    </div>
  );
};

export default PlusUpgradeLoader;
