// @flow
/* eslint-disable jsx-a11y/label-has-for, camelcase */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import idx from 'idx';
import autobind from 'autobind-decorator';
import SelectPrefecture from '../MultiPay/SelectPrefecture';
import withValidation from '../Form/withValidation';
import validators from '../Form/validators';

import { errorHandler, getFormattedZip, scrollToFirstError, debouncedZipReq } from '../../utils';
import { valueConverter } from '../../utils/form';
import { configurationToFlow } from '../../utils/flow';

import {
  MixpanelHelpers,
  MIXPANEL_ACTION_PAGE_VIEW,
  MIXPANEL_ACTION_SUBMIT,
  MIXPANEL_ACTION_FORM_ERROR,
} from '../../utils/mixpanel';

import { paymentStatusMap } from '../../constants';
import { placeholders } from '../../constants/form';

import styles from './Digital.scss';

type Props = {
  onPatch: Function,
  /* digital token */
  patchToken: Function,
  isToken: boolean,
  /* end of digital token */
  updateFormPairs: Function,
  history: Object,
  location: Object,
  // form
  city: string,
  line1: string,
  line2: ?string,
  state: string,
  zip: string,
  last_name_kanji: string,
  first_name_kanji: string,
  // payment
  configuration: ?Object,
  // validation
  errors: Object,
  onValidate: Function,
  onValidateAll: Function,
};

const formValidators = {
  city: validators.empty,
  line2: validators.empty,
  state: validators.empty,
  zip: validators.zip,
  first_name_kanji: validators.kanji,
  last_name_kanji: validators.kanji,
};

/**
 * the users landed here is because this is their first time with Paidy Digital
 */
class DigitalRegular extends Component<Props, { errored: Object }> {
  state = {
    errored: {},
  };

  componentDidMount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.errors).length) {
      const newState = {};

      Object.keys(nextProps.errors).forEach(key => {
        if (nextProps[key]) {
          newState[key] = true;
        }
      });

      this.setState({ errored: newState });
    }
  }

  componentWillUnmount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      shouldEndTracker: true,
    });
  }

  @autobind
  onChange(event: SyntheticInputEvent<HTMLInputElement>) {
    const { value, name } = event.currentTarget;

    if (name === 'zip') {
      if (value.length > 7) {
        return;
      } else if (value.length === 7) {
        const { updateFormPairs } = this.props;

        debouncedZipReq({
          updateFormPairs,
          value,
        });
      }
    }

    this.props.updateFormPairs({
      [name]: valueConverter(value),
    });

    // this is to make sure we are validating updated redux value
    setTimeout(() => {
      if (Object.keys(this.state.errored).indexOf(name) > -1) {
        this.props.onValidate({ name });
      }
    }, 0);
  }

  @autobind
  onBlur(event: SyntheticInputEvent<HTMLInputElement>) {
    const { name } = event.currentTarget;

    this.props.onValidate({ name });
  }

  @autobind
  onSubmit(event: SyntheticInputEvent<HTMLElement>) {
    const { pathname } = this.props.location;

    event.preventDefault();
    MixpanelHelpers.trackAction({ pathname, actionName: MIXPANEL_ACTION_SUBMIT });

    const errors = this.props.onValidateAll({ checkTouch: false });

    if (!Object.keys(errors).find(key => !!errors[key])) {
      const {
        city,
        line1,
        line2,
        state,
        zip,
        last_name_kanji,
        first_name_kanji,
        history,
        onPatch,
      } = this.props;
      const consumerData = {
        address: {
          city,
          line1,
          line2,
          state,
          zip: getFormattedZip(zip),
        },
        // make sure this won't pass backend validation by avoiding "undefined undefined" for name1
        name1:
          !last_name_kanji && !first_name_kanji
            ? undefined
            : `${last_name_kanji} ${first_name_kanji}`,
      };

      onPatch({
        consumer_data: consumerData,
      })
        .then(res => {
          // res is undefined means we have more form to fill, do nothing here
          if (!res) {
            return;
          }

          const status = idx(res, _ => _.data.status);

          if (status === paymentStatusMap.AUTHORIZED) {
            history.push('/result/digital');
          } else if (status === paymentStatusMap.AUTHENTICATED) {
            // this means multi-pay here
            history.push('/multi-pay/select');
          } else {
            history.push('/result/rejected');
          }
        })
        .catch(err => {
          errorHandler({ err, history });
        });
    } else {
      scrollToFirstError();

      const erroredFields = Object.keys(errors).filter(key => !!errors[key]);

      MixpanelHelpers.trackAction({
        pathname,
        actionName: MIXPANEL_ACTION_FORM_ERROR,
        extraData: {
          'Errored Fields': erroredFields,
        },
      });
    }
  }

  @autobind
  onTokenPatch(event: SyntheticInputEvent<HTMLElement>) {
    const { pathname } = this.props.location;

    event.preventDefault();

    MixpanelHelpers.trackAction({ pathname, actionName: MIXPANEL_ACTION_SUBMIT });

    const errors = this.props.onValidateAll({ checkTouch: false });

    if (!Object.keys(errors).find(key => !!errors[key])) {
      const {
        city,
        line1,
        line2,
        state,
        zip,
        last_name_kanji,
        first_name_kanji,
        history,
        patchToken,
      } = this.props;

      const consumerData = {
        address: {
          city,
          line1,
          line2,
          state,
          zip: getFormattedZip(zip),
        },
        // make sure this won't pass backend validation by avoiding "undefined undefined" for name1
        name1:
          !last_name_kanji && !first_name_kanji
            ? undefined
            : `${last_name_kanji} ${first_name_kanji}`,
      };

      patchToken({ consumer_data: consumerData })
        .then(() => {
          history.push('/token/confirm');
        })
        .catch(err => {
          errorHandler({ err, history });
        });
    } else {
      scrollToFirstError();

      const erroredFields = Object.keys(errors).filter(key => !!errors[key]);

      MixpanelHelpers.trackAction({
        pathname,
        actionName: MIXPANEL_ACTION_FORM_ERROR,
        extraData: {
          'Errored Fields': erroredFields,
        },
      });
    }
  }

  @autobind
  checkIfButtonDisabled() {
    const { city, line2, state, zip, last_name_kanji, first_name_kanji } = this.props;

    const hasCity = formValidators.city(city);
    const hasLine2 = formValidators.line2(line2);
    const hasState = formValidators.state(state);
    const hasZip = formValidators.zip(zip);
    const hasFirstNameKanji = formValidators.first_name_kanji(first_name_kanji);
    const hasLastNameKanji = formValidators.last_name_kanji(last_name_kanji);

    return (
      Boolean(hasCity) ||
      Boolean(hasLine2) ||
      Boolean(hasState) ||
      Boolean(hasZip) ||
      Boolean(hasFirstNameKanji) ||
      Boolean(hasLastNameKanji)
    );
  }

  render() {
    const {
      city,
      line1,
      line2,
      state,
      zip,
      first_name_kanji,
      last_name_kanji,
      errors,
      isToken,
      configuration,
      location,
    } = this.props;

    const { isLast } = configurationToFlow(configuration, location.pathname);

    return (
      <>
        <div className={styles['scrollable-content']}>
          <b className={styles.title}>今回のお支払いでは追加情報が必要です</b>
          <div className={styles.name}>
            <fieldset>
              <label htmlFor="last-name-kanji">姓</label>
              <input
                onChange={this.onChange}
                onBlur={this.onBlur}
                id="last-name-kanji"
                type="text"
                name="last_name_kanji"
                placeholder={placeholders.LAST_NAME_KANJI}
                value={last_name_kanji}
              />
              {errors.last_name_kanji && <span className="error">{errors.last_name_kanji}</span>}
            </fieldset>
            <fieldset>
              <label htmlFor="first-name-kanji">名</label>
              <input
                onChange={this.onChange}
                onBlur={this.onBlur}
                id="first-name-kanji"
                type="text"
                name="first_name_kanji"
                placeholder={placeholders.FIRST_NAME_KANJI}
                value={first_name_kanji}
              />
              {errors.first_name_kanji && <span className="error">{errors.first_name_kanji}</span>}
            </fieldset>
          </div>
          <fieldset>
            <label htmlFor="zip">現住所</label>
            <input
              onChange={this.onChange}
              onBlur={this.onBlur}
              id="zip"
              type="tel"
              pattern="\d*"
              name="zip"
              placeholder={placeholders.ZIP}
              value={zip}
            />
            {errors.zip && <span className="error">{errors.zip}</span>}
          </fieldset>
          <fieldset>
            <label htmlFor="state">都道府県</label>
            <SelectPrefecture id="state" name="state" value={state} onChange={this.onChange} />
            {errors.state && <span className="error">{errors.state}</span>}
          </fieldset>
          <fieldset>
            <label htmlFor="city">市区町村</label>
            <input
              onChange={this.onChange}
              onBlur={this.onBlur}
              id="city"
              type="text"
              name="city"
              placeholder={placeholders.CITY}
              value={city}
            />
            {errors.city && <span className="error">{errors.city}</span>}
          </fieldset>
          <fieldset>
            <label htmlFor="line2">丁目・番地</label>
            <input
              onChange={this.onChange}
              onBlur={this.onBlur}
              id="line2"
              type="text"
              name="line2"
              placeholder={placeholders.LINE2}
              value={line2}
            />
            {errors.line2 && <span className="error">{errors.line2}</span>}
          </fieldset>
          <fieldset>
            <label htmlFor="line2">建物名・部屋番号【任意】</label>
            <input
              onChange={this.onChange}
              onBlur={this.onBlur}
              id="line1"
              type="text"
              name="line1"
              placeholder={placeholders.LINE1}
              value={line1}
            />
            {errors.line1 && <span className="error">{errors.line1}</span>}
          </fieldset>
        </div>
        <div className={styles.controls}>
          <button
            id="btn-next"
            onClick={isToken ? this.onTokenPatch : this.onSubmit}
            disabled={this.checkIfButtonDisabled()}
            className={styles.button}
          >
            {isLast ? 'ペイディで支払う' : '次へ'}
          </button>
        </div>
      </>
    );
  }
}

export default withRouter(withValidation(formValidators)(DigitalRegular));
