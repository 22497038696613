// @flow
import { connect } from 'react-redux';
import Frame from './Frame';
import { updateUIPairs } from '../../redux/modules/ui';

const mapStateToProps = (state: { ui: { isLoading: boolean, loadingType: string } }) => ({
  isLoading: state.ui.isLoading,
  loadingType: state.ui.loadingType,
});

const mapDispatchToProps = (dispatch: Function) => ({
  updateUIPairs: pairs => dispatch(updateUIPairs(pairs)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Frame);
