// @@flow
import faqJSON from '../../contentful/faq.json';
import tocJSON from '../../contentful/toc.json';

const UPDATE_PAIRS = '@@checkout/policy/update_pairs';

const initialState = {
  faq: faqJSON,
  toc: tocJSON,
};

const policy = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAIRS: {
      return {
        ...state,
        ...action.pairs,
      };
    }
    default:
      return state;
  }
};

export const updatePolicyPairs = pairs => ({
  type: UPDATE_PAIRS,
  pairs,
});

export default policy;
