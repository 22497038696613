/**
 * Based on {@link https://github.com/1000ch/throttle-decorator}
 */

import throttle from 'lodash.throttle';

export default function throttleDecorator(wait, options = {}) {
  return (target, name, descriptor) => ({
    configurable: true,
    enumerable: descriptor.enumerable,
    value: throttle(descriptor.value, wait, options),
  });
}
