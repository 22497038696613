// @@flow
const UPDATE_PAIRS = '@@checkout/form/update_pairs';

type State = {
  annual_income: string,
  city: string,
  line1: string,
  line2: string,
  state: string,
  zip: string,
  date_of_birth: string,
  employer: string,
  first_name: string,
  last_name: string,
  gender: string,
  household_size: string,
  marital_status: string,
  mortgage: boolean,
  residence_type: string,
  // contract details, the following states' initial value is undefined
  contract_fee_percentage: ?number,
  currency: string,
  last_month_amount: ?number,
  monthly_amount: ?number,
  number_of_installments: ?number,
  total_amount: ?number,
  total_fee_amount: ?number,
  // plus
  income: string,
  first_name_kana: string,
  last_name_kana: string,
  first_name_kanji: string,
  last_name_kanji: string,
  head_of_household: string,
  occupation: string,
  rent_or_loan: ?boolean,
};

const stringKeys = [
  'annual_income',
  'city',
  'line1',
  'line2',
  'state',
  'zip',
  'date_of_birth',
  'employer',
  'first_name',
  'last_name',
  'gender',
  'household_size',
  'marital_status',
  'residence_type',
  'currency',
  'income',
  'first_name_kana',
  'last_name_kana',
  'first_name_kanji',
  'last_name_kanji',
  'head_of_household',
  'occupation',
];

const initialState: State = {
  annual_income: '',
  city: '',
  line1: '',
  line2: '',
  state: '',
  zip: '',
  date_of_birth: '',
  employer: '',
  first_name: '',
  last_name: '',
  gender: '',
  household_size: '',
  marital_status: '',
  mortgage: undefined,
  residence_type: '',
  // contract details, the following states' initial value is undefined
  contract_fee_percentage: undefined,
  currency: '',
  last_month_amount: undefined,
  monthly_amount: undefined,
  number_of_installments: undefined,
  total_amount: undefined,
  total_fee_amount: undefined,
  // plus
  income: '',
  first_name_kana: '',
  last_name_kana: '',
  first_name_kanji: '',
  last_name_kanji: '',
  head_of_household: '',
  occupation: '',
  rent_or_loan: undefined,
};

const form = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAIRS: {
      const newState = Object.keys(action.pairs).reduce((accu, key) => {
        const _accu = { ...accu };

        _accu[key] = action.pairs[key];

        if (stringKeys.indexOf(key) > -1) {
          // includes `undefined` and `null`
          _accu[key] = _accu[key] || '';
        }

        if (key === 'zip') {
          // toString is to make sure unexpected input never cause issue
          _accu[key] = _accu[key].toString().replace(/\D/g, '');
        }

        return _accu;
      }, {});

      return {
        ...state,
        ...newState,
      };
    }
    default:
      return state;
  }
};

export const updateFormPairs = pairs => ({
  type: UPDATE_PAIRS,
  pairs,
});

export default form;
