// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import Frame from '../Frame/FrameContainer';
import ShadowScrollbar from '../ShadowScrollbar/ShadowScrollbarContainer';
import { getDesktopContainerStyle } from '../../utils';
import styles from './MultiPayConfirm.scss';
import optionStyles from './Options.scss';
import { MixpanelHelpers, MIXPANEL_ACTION_LINK_CLICK } from '../../utils/mixpanel';

const trackLinkClickToc = () =>
  MixpanelHelpers.trackAction({
    customPath: 'Multipay Contract Preview',
    actionName: MIXPANEL_ACTION_LINK_CLICK,
    actionItem: 'T&C',
  });

type Props = {
  monthlyAmount: number,
  lastMonthAmount: number,
  totalAmount: number,
  contractFeePercentage: number,
  pureTotalAmount: number,
  totalFeeAmount: number,
  onPatch: Function,
};

const MultiPayConfirm = ({
  monthlyAmount,
  lastMonthAmount,
  totalAmount,
  contractFeePercentage,
  pureTotalAmount,
  totalFeeAmount,
  onPatch,
}: Props) => (
  <Frame helpType="multipay" hasCornerBackButton mainStyle={getDesktopContainerStyle(580)}>
    <ShadowScrollbar className="no-padding-bottom">
      <section className="page-title-section dotted-bottom alone">
        <h2 className="page-title">分割払いご契約内容</h2>
      </section>
      <section className={styles.details}>
        <div className={styles.row}>
          <span className={styles.left}>利用金額</span>
          <span className={styles.right}>¥{pureTotalAmount.toLocaleString('ja-jp')}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.left}>分割払手数料</span>
          <span className={styles.right}>¥{totalFeeAmount.toLocaleString('ja-jp')}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.left}>
            支払総額 (実質年率
            {contractFeePercentage}
            %)
          </span>
          <span className={styles.right}>¥{totalAmount.toLocaleString('ja-jp')}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.left}>毎月の支払金額</span>
          <span className={styles.right}>
            月々¥
            {monthlyAmount.toLocaleString('ja-jp')}
          </span>
        </div>
        <div className={styles.row}>
          <span className={styles.left}>最終回の支払い金額</span>
          <span className={styles.right}>¥{lastMonthAmount.toLocaleString('ja-jp')}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.left}>支払日</span>
          <span className={styles.right}>毎月10日まで</span>
        </div>
        <div className={optionStyles.memos}>
          <p className={optionStyles.memo}>月額の支払予定額 (目安) となります</p>
          <p className={optionStyles.memo}>
            分割払いの審査が不通過となった場合は、一括払いでの支払いとなります。予めご了承ください。
          </p>
        </div>
      </section>
    </ShadowScrollbar>
    <Link
      onClick={trackLinkClickToc}
      to="/toc"
      className={cx('toc-above', styles['with-padding-top'])}
    >
      Paidyの利用規約・個人情報取扱条項
      <span>に同意して</span>
    </Link>
    <button
      id="btn_multi_confirm_next"
      onClick={onPatch}
      className={cx(styles['btn-apply'], 'rebel')}
    >
      申し込む
    </button>
  </Frame>
);

export default MultiPayConfirm;
