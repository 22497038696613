// @flow
/* eslint-disable jsx-a11y/no-autofocus */
import React, { Component } from 'react';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import { withRouter, Link } from 'react-router-dom';
// components
import Checkbox from '../../CheckBox';
import Frame from '../FrameDemo/FrameContainer';
import withValidation from '../../Form/withValidation';
import {
  getParsedPhoneNumber,
  errorHandler,
  isLocalStorageSupported,
  scrollToFirstError,
  parsePhoneNumber,
} from '../../../utils';
import robustStorage from '../../../utils/robust-local-storage';
import validators from '../../Form/validators';
import { tierMap } from '../../../constants';
import { placeholders } from '../../../constants/form';
import styles from './LoginForm.scss';
import {
  MixpanelHelpers,
  LOGIN_DISPLAY_NAMES,
  MIXPANEL_ACTION_FIELD_EDIT,
  MIXPANEL_ACTION_FORM_ERROR,
  MIXPANEL_ACTION_LINK_CLICK,
  MIXPANEL_ACTION_SUBMIT,
  MIXPANEL_ACTION_PAGE_VIEW,
  MIXPANEL_ACTION_REMEMBER_ME,
} from '../../../utils/mixpanel';
import { deleteLocalAuth, saveLocalAuth } from '../../../redux/modules/auth';

type Props = {
  tier: string,
  email: string,
  phone: string,
  onSubmit: Function,
  history: any,
  location: Object,
  // rememberMe: ?string,
  // onRememberMeChange: Function,
  updateAuthPairs: Function,
  tocSingleVersion: string,
  tocPlusVersion: string,
  // form validation
  errors: Object,
  setError: Function,
  onValidate: Function,
  onValidateAll: Function,
};

const formValidators = {
  email: validators.email,
  phone: validators.phone,
};

const trackLinkClickToc = () =>
  MixpanelHelpers.trackAction({
    customPath: 'Login',
    actionName: MIXPANEL_ACTION_LINK_CLICK,
    actionItem: 'T&C',
  });

let hasEmailFieldBeenEdited = false;
let hasPhoneFieldBeenEdited = false;

type State = {
  email: string,
  phone: string,
};

class LoginForm extends Component<Props, State> {
  static onFocus = (event: SyntheticEvent<HTMLInputElement>): void => {
    event.preventDefault();

    event.currentTarget.scrollIntoView(true);
  };

  constructor(props) {
    super(props);
    const { email, phone } = this.props;
    const rememberMe = robustStorage.getItem('paidy_remember_me') === 'true' && email && phone;

    this.state = {
      email: email || '',
      phone: phone || '',
      isFocused: false,
      rememberMe,
      shouldSkipLoginPage: rememberMe,
    };
  }

  componentWillMount() {
    // This snippet is the core of the Remember Me function.
    // On the next time Checkout is opened, it automatically logs in the consumer.
    if (this.state.rememberMe) {
      this.onSubmit();
      return;
    }

    // If the email and phone are saved in localstorage,
    // Enable Remember Me as well.
    if (robustStorage.getItem('paidy_email') && robustStorage.getItem('paidy_phone')) {
      robustStorage.setItem('paidy_remember_me', 'true');
      this.setState({
        rememberMe: true,
      });
    }
  }

  componentDidMount() {
    const { pathname } = this.props.location;
    if (this.state.shouldSkipLoginPage) {
      return;
    }
    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }

  componentWillUnmount() {
    const { pathname } = this.props.location;
    if (this.state.shouldSkipLoginPage) {
      return;
    }
    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      shouldEndTracker: true,
    });
  }

  @autobind
  onChange(event: SyntheticInputEvent<HTMLInputElement>) {
    const {
      updateAuthPairs,
      location: { pathname },
    } = this.props;
    const { name, value } = event.currentTarget;
    const isFieldEmail = name === 'email';

    this.setState({
      [name]: value,
    });

    let finalValue = value;

    if (name === 'phone') {
      finalValue = value.replace(/ /g, '');
    }

    updateAuthPairs({
      [name]: finalValue,
    });

    if (
      (isFieldEmail && !hasEmailFieldBeenEdited) ||
      (name === 'phone' && !hasPhoneFieldBeenEdited)
    ) {
      if (isFieldEmail) {
        hasEmailFieldBeenEdited = true;
      } else {
        hasPhoneFieldBeenEdited = true;
      }

      MixpanelHelpers.trackAction({
        pathname,
        actionName: MIXPANEL_ACTION_FIELD_EDIT,
        actionItem: LOGIN_DISPLAY_NAMES[name],
      });
    }
  }

  @autobind
  onSubmit(): boolean | Promise<any> {
    const {
      email,
      phone,
      history,
      location: { pathname },
    } = this.props;
    const errors = this.props.onValidateAll({ checkTouch: false });

    if (!Object.keys(errors).find(key => !!errors[key])) {
      const parsedPhoneNumber = getParsedPhoneNumber(phone);

      this.props
        .onSubmit(email, parsedPhoneNumber)
        .then(() => {
          // [checkout-demo] this case is to handle rejected payment for classic user ( 一括払いのみ )
          if (
            email === 'rejected.payment@paidy.com' &&
            location.href.indexOf('installment=false') > -1
          ) {
            throw new Error();
          }

          !this.state.shouldSkipLoginPage &&
            MixpanelHelpers.trackAction({
              pathname,
              actionName: MIXPANEL_ACTION_SUBMIT,
            });

          if (this.props.tier === tierMap.PLUS) {
            robustStorage.setItem('paidy_last_agreed_toc_plus_version', this.props.tocPlusVersion);
          } else {
            robustStorage.setItem(
              'paidy_last_agreed_toc_single_version',
              this.props.tocSingleVersion
            );
          }

          // If remember me is enabled, save credentials to localstorage
          if (this.state.rememberMe) {
            saveLocalAuth({ email, phone });
          }

          this.props.history.push('/otp');
        })
        .catch(err => {
          // If an invalid number somehow got through the frontend validation
          // and backend returns an error show error message instead of just showing "現在こちらのショップでPaidyが利用できません"
          if (
            err &&
            err.data &&
            err.data.details &&
            err.data.details.length &&
            err.data.details[0].message === 'string.japanese.mobile.phone.valid'
          ) {
            this.props.setError({ phone: '携帯電話番号に誤りがあります' });

            return;
          }

          errorHandler({ err, history });
        });
    } else {
      scrollToFirstError();

      const erroredFields = Object.keys(errors).filter(key => !!errors[key]);

      MixpanelHelpers.trackAction({
        pathname,
        actionName: MIXPANEL_ACTION_FORM_ERROR,
        extraData: {
          'Errored Fields': erroredFields,
        },
      });
    }

    return false;
  }

  @autobind
  onBlur(event) {
    const { name } = event.currentTarget;

    this.props.onValidate({ name });

    this.setState({ isFocused: false });
  }

  @autobind
  onFocus() {
    this.setState({ isFocused: true });
  }

  @autobind
  onRememberMeToggle(rememberMe: boolean) {
    this.setState({
      rememberMe,
      shouldSkipLoginPage: false,
    });

    MixpanelHelpers.trackAction({
      pathname: this.props.location.pathname,
      actionName: MIXPANEL_ACTION_REMEMBER_ME,
      extraData: {
        'Remember Me': rememberMe,
      },
    });

    if (rememberMe) {
      robustStorage.setItem('paidy_remember_me', 'true');
      MixpanelHelpers.registerRememberMeStatus(true);
    } else {
      robustStorage.setItem('paidy_remember_me', 'false');
      deleteLocalAuth();

      MixpanelHelpers.registerRememberMeStatus(false);
    }
  }

  render() {
    const { rememberMe, onRememberMeChange, errors, email, phone } = this.props;

    return (
      <Frame helpType="login">
        <div className={styles['scrollable-content']}>
          <fieldset>
            <label htmlFor="email">メールアドレス</label>
            <input
              onChange={this.onChange}
              onBlur={this.onBlur}
              id="email"
              type="email"
              name="email"
              placeholder={placeholders.EMAIL}
              value={this.state.email}
              autoFocus
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </fieldset>
          <fieldset>
            <label htmlFor="phone">携帯電話番号</label>
            <input
              onChange={this.onChange}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              id="phone"
              type="tel"
              name="phone"
              pattern="\d*-*\d*-*\d*"
              placeholder={placeholders.PHONE}
              value={this.state.isFocused ? this.state.phone : parsePhoneNumber(this.state.phone)}
            />
            {errors.phone && <span className="error">{errors.phone}</span>}
          </fieldset>
          {isLocalStorageSupported() && (
            <fieldset>
              {/*<Checkbox*/}
              {/*  id="remember"*/}
              {/*  name="remember"*/}
              {/*  checked={rememberMe}*/}
              {/*  onChange={onRememberMeChange}*/}
              {/*  title="次回から入力を省略"*/}
              {/*/>*/}
              <Checkbox
                id="remember"
                name="remember"
                checked={this.state.rememberMe}
                onChange={event => {
                  this.onRememberMeToggle(event.target.checked);
                }}
                title="次回から入力を省略"
              />
            </fieldset>
          )}
        </div>
        <div className={styles.controls}>
          <span className={styles.toc}>
            <Link to="/toc" onClick={trackLinkClickToc} id="link-see-toc">
              <span>利用規約・個人情報取扱条項</span>
            </Link>
            <span>に同意して</span>
          </span>
          <button
            id="btn-login"
            className={cx('btn', styles.button)}
            onClick={(event: SyntheticEvent<HTMLButtonElement>) => {
              event.preventDefault();

              this.onSubmit();
            }}
            disabled={!email || !phone}
          >
            次へ
          </button>
        </div>
      </Frame>
    );
  }
}

export default withRouter(withValidation(formValidators)(LoginForm));
