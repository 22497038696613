import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MultiPay from './MultiPay';
import { updateMerchantPairs } from '../../redux/modules/merchant';
import { updatePaymentPairs } from '../../redux/modules/payment';
import { updateFormPairs } from '../../redux/modules/form';

const mapStateToProps = state => ({
  // auth
  isReturning: state.auth.isReturning,
  // form
  annual_income: state.form.annual_income,
  city: state.form.city,
  line1: state.form.line1,
  line2: state.form.line2,
  state: state.form.state,
  zip: state.form.zip,
  date_of_birth: state.form.date_of_birth,
  employer: state.form.employer,
  first_name_kana: state.form.first_name_kana,
  last_name_kana: state.form.last_name_kana,
  gender: state.form.gender,
  household_size: state.form.household_size,
  marital_status: state.form.marital_status,
  mortgage: state.form.mortgage,
  residence_type: state.form.residence_type,
  // contract details
  contract_fee_percentage: state.form.contract_fee_percentage,
  currency: state.form.currency,
  last_month_amount: state.form.last_month_amount,
  monthly_amount: state.form.monthly_amount,
  number_of_installments: state.form.number_of_installments,
  total_amount: state.form.total_amount,
  total_fee_amount: state.form.total_fee_amount,
  // payment
  // isFirstTime: state.payment.isFirstTime,
  isBig: state.payment.isBig,
  // payment
  installmentOptions: state.payment.installmentOptions,
});

const mapDispatchToProps = dispatch => ({
  updateMerchantPairs: pairs => dispatch(updateMerchantPairs(pairs)),
  updatePaymentPairs: pairs => dispatch(updatePaymentPairs(pairs)),
  updateFormPairs: pairs => dispatch(updateFormPairs(pairs)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MultiPay)
);
