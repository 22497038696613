// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { updateAuthPairs } from '../../../redux/modules/auth';
import { updateMerchantPairs } from '../../../redux/modules/merchant';
import { updatePaymentPairs } from '../../../redux/modules/payment';
import { updateFormPairs } from '../../../redux/modules/form';
import { updateUIPairs } from '../../../redux/modules/ui';
import { resetStore } from '../../../redux/modules';
import AppDemo from './AppDemo';

const mapStateToProps = state => ({
  email: state.auth.email,
  phone: state.auth.phone,
  isReturning: state.auth.isReturning,
  // merchant
  shopName: state.merchant.shopName,
  logoURL: state.merchant.logoURL,
  isHighTicket: state.merchant.isHighTicket,
  // payment
  tier: state.payment.tier,
  installmentOptions: state.payment.installmentOptions,
  paymentId: state.payment.paymentId,
  configuration: state.payment.configuration,
  patch: state.payment.patch,
  // plus form
  city: state.form.city,
  line1: state.form.line1,
  line2: state.form.line2,
  state: state.form.state,
  zip: state.form.zip,
  date_of_birth: state.form.date_of_birth,
  first_name_kana: state.form.first_name_kana,
  first_name_kanji: state.form.first_name_kanji,
  last_name_kana: state.form.last_name_kana,
  last_name_kanji: state.form.last_name_kanji,
  // ui
  pinCodeSentCount: state.ui.pinCodeSentCount,
});

const mapDispatchToProps = (dispatch: Function) => ({
  updateAuthPairs: pairs => dispatch(updateAuthPairs(pairs)),
  updateMerchantPairs: pairs => dispatch(updateMerchantPairs(pairs)),
  updatePaymentPairs: pairs => dispatch(updatePaymentPairs(pairs)),
  updateFormPairs: pairs => dispatch(updateFormPairs(pairs)),
  updateUIPairs: pairs => dispatch(updateUIPairs(pairs)),
  resetStore: () => dispatch(resetStore()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppDemo));
