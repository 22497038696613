// @flow
/* eslint-disable camelcase, jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import cx from 'classnames';
import autobind from 'autobind-decorator';
import { isAndroid } from 'react-device-detect';
import Frame from '../Frame/FrameContainer';
import InputBox from '../Form/InputBox';
import DOBInput from '../Form/DOBInput';
import withValidation from '../Form/withValidation';
import SelectPrefecture from './SelectPrefecture';
import ShadowScrollbar from '../ShadowScrollbar/ShadowScrollbarContainer';
import validators from '../Form/validators';
import { valueConverter } from '../../utils/form';
import { scrollToFirstError, debouncedZipReq } from '../../utils';
import {
  MixpanelHelpers,
  MIXPANEL_ACTION_SUBMIT,
  MIXPANEL_ACTION_PAGE_VIEW,
  MIXPANEL_ACTION_FORM_ERROR,
} from '../../utils/mixpanel';
import { placeholders } from '../../constants/form';

type Props = {
  history: Object,
  location: Object,
  // form
  annual_income: number,
  city: string,
  line1: string,
  line2: string,
  state: string,
  zip: string,
  date_of_birth: string,
  dob_year: string,
  dob_month: string,
  dob_day: string,
  employer: string,
  first_name_kana: string,
  last_name_kana: string,
  gender: string,
  household_size: string,
  marital_status: string,
  mortgage: boolean,
  residence_type: string,
  updateFormPairs: Function,
  // validation
  errors: Object,
  onValidateAll: Function,
  onValidate: Function,
};

const formValidators = {
  zip: validators.zip,
  state: validators.empty,
  first_name_kana: validators.katakana,
  last_name_kana: validators.katakana,
  date_of_birth: validators.dateOfBirth,
  city: validators.empty,
  line2: validators.empty,
  gender: validators.gender,
  marital_status: validators.maritalStatus,
  household_size: validators.householdSize,
  residence_type: validators.residenceType,
  mortgage: validators.mortgage,
  employer: validators.empty,
  annual_income: validators.income,
};

class MultiPayForm extends Component<Props, { errored: Object }> {
  state = {
    errored: {},
  };

  componentDidMount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.errors).length) {
      const newState = {};

      Object.keys(nextProps.errors).forEach(key => {
        if (nextProps[key]) {
          newState[key] = true;
        }
      });

      this.setState({ errored: newState });
    }
  }

  componentWillUnmount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      shouldEndTracker: true,
    });
  }

  @autobind
  onChange(event: SyntheticInputEvent<HTMLInputElement>) {
    const { name } = event.currentTarget;
    let { value } = event.currentTarget;

    if (name === 'annual_income') {
      if (value.length > 5 || (value !== '0' && value.trim().startsWith('0'))) {
        return;
      }
    }

    if (name === 'zip') {
      if (value.length > 7) {
        return;
      } else if (value.length === 7) {
        const { updateFormPairs } = this.props;

        debouncedZipReq({
          updateFormPairs,
          value,
        });
      }
    }

    const dateOfBirth = this.props.date_of_birth;
    const isSeparatedDOBInput = ['dob_year', 'dob_month', 'dob_day'].indexOf(name) > -1;

    if (isSeparatedDOBInput) {
      const originalValue = value;
      const arrDOB = dateOfBirth.split('-');

      switch (name) {
        case 'dob_year': {
          value = [value].concat(dateOfBirth.split('-').slice(1)).join('-');

          break;
        }
        case 'dob_month': {
          const month = value ? `0${value}`.slice(-2) : '';

          value = `${arrDOB[0]}-${month}-${arrDOB[2]}`;

          break;
        }
        case 'dob_day': {
          const day = value ? `0${value}`.slice(-2) : '';

          value = `${arrDOB[0]}-${arrDOB[1]}-${day}`;

          break;
        }
        default:
          break;
      }

      this.props.updateFormPairs({
        date_of_birth: value,
        [name]: originalValue,
      });
    } else {
      this.props.updateFormPairs({
        [name]: valueConverter(value),
      });
    }

    // this is to make sure we are validating updated redux value
    setTimeout(() => {
      if (Object.keys(this.state.errored).indexOf(name) > -1) {
        this.props.onValidate({ name: isSeparatedDOBInput ? 'date_of_birth' : name });
      }

      if (Object.keys(this.state.errored).indexOf('date_of_birth') > -1 && isSeparatedDOBInput) {
        this.props.onValidate({ name: 'date_of_birth' });
      }
    }, 0);
  }

  @autobind
  onBlur(event) {
    const { name } = event.currentTarget;
    const isSeparatedDOBInput = ['dob_year', 'dob_month', 'dob_day'].indexOf(name) > -1;

    this.props.onValidate({ name: isSeparatedDOBInput ? 'date_of_birth' : name });
  }

  @autobind
  onSubmit(event: SyntheticInputEvent<HTMLElement>) {
    const { pathname } = this.props.location;

    event.preventDefault();
    MixpanelHelpers.trackAction({ pathname, actionName: MIXPANEL_ACTION_SUBMIT });

    const errors = this.props.onValidateAll({ checkTouch: false });

    if (!Object.keys(errors).find(key => !!errors[key])) {
      this.props.history.push('/multi-pay/confirm');
    } else {
      scrollToFirstError();

      const erroredFields = Object.keys(errors).filter(key => !!errors[key]);

      MixpanelHelpers.trackAction({
        pathname,
        actionName: MIXPANEL_ACTION_FORM_ERROR,
        extraData: {
          'Errored Fields': erroredFields,
        },
      });
    }
  }

  render() {
    const {
      zip,
      state,
      first_name_kana,
      last_name_kana,
      date_of_birth,
      dob_year,
      dob_month,
      dob_day,
      city,
      line1,
      line2,
      gender,
      marital_status,
      household_size,
      residence_type,
      mortgage,
      employer,
      annual_income,
      errors,
      // onBlur,
      // hasError,
      onValidate,
    } = this.props;

    return (
      <Frame helpType="multipay" hasCornerBackButton hasFooter={false}>
        <ShadowScrollbar className="no-padding-bottom">
          <section className="page-title-section">
            <h2 className="page-title">分割払いお申し込み</h2>
            <p className="desc">全てご記入ください</p>
          </section>
          <form>
            <fieldset>
              <InputBox
                label="セイ (カタカナ)"
                size="half"
                type="text"
                errors={errors}
                name="last_name_kana"
                placeholder={placeholders.LAST_NAME_KATAKANA}
                value={last_name_kana}
                onChange={this.onChange}
                onBlur={this.onBlur}
              />
              <InputBox
                label="メイ (カタカナ)"
                size="half"
                type="text"
                errors={errors}
                name="first_name_kana"
                placeholder={placeholders.FIRST_NAME_KATAKANA}
                value={first_name_kana}
                onChange={this.onChange}
                onBlur={this.onBlur}
              />
            </fieldset>
            <fieldset>
              <InputBox
                label="現住所"
                size="half"
                type={isAndroid ? 'number' : 'tel'}
                errors={errors}
                name="zip"
                placeholder={placeholders.ZIP}
                value={zip}
                onChange={this.onChange}
                onBlur={this.onBlur}
              />
              <div className={cx('half', errors.state ? 'error' : undefined)}>
                <label htmlFor="select_state">都道府県</label>
                <SelectPrefecture
                  id="select_state"
                  name="state"
                  value={state}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                />
                {errors.state && <span className="input-error">{errors.state}</span>}
              </div>
            </fieldset>
            <fieldset>
              <InputBox
                label="市区町村"
                size="full"
                type="text"
                errors={errors}
                name="city"
                placeholder={placeholders.CITY}
                value={city}
                onChange={this.onChange}
                onBlur={this.onBlur}
              />
            </fieldset>
            <fieldset>
              <InputBox
                label="丁目・番地"
                size="full"
                type="text"
                errors={errors}
                name="line2"
                placeholder={placeholders.LINE2}
                value={line2}
                onChange={this.onChange}
                onBlur={this.onBlur}
              />
            </fieldset>
            <fieldset>
              <InputBox
                label="建物名・部屋番号"
                size="full"
                type="text"
                errors={errors}
                name="line1"
                placeholder={placeholders.LINE1}
                value={line1}
                onChange={this.onChange}
                onBlur={this.onBlur}
                isRequired={false}
              />
            </fieldset>
            <fieldset>
              <DOBInput
                errors={errors}
                value={date_of_birth}
                dobYear={dob_year}
                dobMonth={dob_month}
                dobDay={dob_day}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onValidate={onValidate}
              />
            </fieldset>
            <fieldset>
              <div className={cx('half', errors.gender ? 'error' : undefined)}>
                <label htmlFor="select_gender">性別</label>
                <div className="select-box">
                  <select
                    id="select_gender"
                    name="gender"
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    value={gender}
                  >
                    <option value="-1">選択</option>
                    <option value="male">男性</option>
                    <option value="female">女性</option>
                  </select>
                </div>
                {errors.gender && <span className="input-error">{errors.gender}</span>}
              </div>
              <div className={cx('half', errors.marital_status ? 'error' : undefined)}>
                <label htmlFor="select_marital_status">配偶者有無</label>
                <div className="select-box">
                  <select
                    id="select_marital_status"
                    name="marital_status"
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    value={marital_status}
                  >
                    <option value="-1">選択</option>
                    <option value="married">あり</option>
                    <option value="single">なし</option>
                  </select>
                </div>
                {errors.marital_status && (
                  <span className="input-error">{errors.marital_status}</span>
                )}
              </div>
            </fieldset>
            <fieldset>
              <div className={cx('half', errors.household_size ? 'error' : undefined)}>
                <label htmlFor="select_household_size">同居人数(本人含む)</label>
                <div className="select-box">
                  <select
                    id="select_household_size"
                    name="household_size"
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    value={household_size}
                  >
                    <option value="-1">選択</option>
                    <option value="1">1人</option>
                    <option value="2">2人</option>
                    <option value="3">3人</option>
                    <option value="4">4人~</option>
                  </select>
                </div>
                {errors.household_size && (
                  <span className="input-error">{errors.household_size}</span>
                )}
              </div>
              <div className={cx('half', errors.residence_type ? 'error' : undefined)}>
                <label htmlFor="select_residence_type">住居形態</label>
                <div className="select-box">
                  <select
                    id="select_residence_type"
                    name="residence_type"
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    value={residence_type}
                  >
                    <option value="-1">選択</option>
                    <option value="rented_by_self">賃貸</option>
                    <option value="rented_by_company">社宅・寮</option>
                    <option value="owned_by_self">持ち家 (自己名義)</option>
                    <option value="owned_by_family">持ち家 (家族名義)</option>
                  </select>
                </div>
                {errors.residence_type && (
                  <span className="input-error">{errors.residence_type}</span>
                )}
              </div>
            </fieldset>
            <fieldset>
              <div className={cx('full', errors.mortgage ? 'error' : undefined)}>
                <label htmlFor="select_mortgage">家賃・住宅ローン有無</label>
                <div className="select-box">
                  <select
                    id="select_mortgage"
                    name="mortgage"
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    value={mortgage}
                  >
                    <option value="-1">選択</option>
                    <option value>あり</option>
                    <option value={false}>なし</option>
                  </select>
                </div>
                {errors.mortgage && <span className="input-error">{errors.mortgage}</span>}
              </div>
            </fieldset>
            <fieldset>
              <InputBox
                label="年収 (万単位)"
                size="full"
                type="number"
                errors={errors}
                name="annual_income"
                placeholder={placeholders.INCOME}
                value={annual_income}
                onChange={this.onChange}
                onBlur={this.onBlur}
              />
            </fieldset>
            <fieldset>
              <InputBox
                label="会社名 (パート・アルバイト含む)"
                size="full"
                type="text"
                errors={errors}
                name="employer"
                placeholder={placeholders.EMPLOYER}
                value={employer}
                onChange={this.onChange}
                onBlur={this.onBlur}
              />
            </fieldset>
          </form>
          <Link
            id="btn_multi_form_next"
            className="btn primary"
            to="/multi-pay/preview"
            onClick={this.onSubmit}
          >
            次ヘ
          </Link>
        </ShadowScrollbar>
      </Frame>
    );
  }
}

export default withRouter(withValidation(formValidators)(MultiPayForm));
