// @flow
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import autobind from 'autobind-decorator';
import idx from 'idx';
import ShadowScrollbar from '../ShadowScrollbar/ShadowScrollbarContainer';
import Options from './OptionsContainer';
import MultiPayForm from './MultiPayFormContainer';
import MultiPayConfirm from './MultiPayConfirm';
import Frame from '../Frame/FrameContainer';
import { errorHandler, getFormattedZip } from '../../utils';
import { paymentStatusMap } from '../../constants';
import styles from './Options.scss';
import {
  MixpanelHelpers,
  MIXPANEL_ACTION_CONFIRM,
  MIXPANEL_ACTION_PAGE_VIEW,
} from '../../utils/mixpanel';

type State = {
  number_of_installments: ?number,
  contractDetails: ?Object,
  plus_contract_data: ?Object,
  contract: ?Object,
};

type Props = {
  // meta
  match: Object,
  onPatch: Function,
  installmentOptions: ?Array<any>,
  history: any,
  location: Object,
  // payment
  isBig: boolean,
  // form
  annual_income: number,
  city: string,
  line1: string,
  line2: string,
  state: string,
  zip: string,
  date_of_birth: string,
  employer: string,
  first_name_kana: string,
  last_name_kana: string,
  gender: string,
  household_size: number,
  marital_status: string,
  mortgage: boolean,
  residence_type: string,
  // contract option details
  contract_fee_percentage: ?number,
  last_month_amount: ?number,
  monthly_amount: ?number,
  number_of_installments: ?number,
  total_amount: ?number,
  total_fee_amount: ?number,
  updateFormPairs: Function,
};

class MultiPay extends Component<Props, State> {
  state = {
    contractDetails: undefined,
    number_of_installments: undefined,
    plus_contract_data: undefined,
    contract: undefined,
  };

  componentDidMount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }

  componentWillUnmount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      shouldEndTracker: true,
    });
  }

  @autobind
  onPatch() {
    const { pathname } = this.props.location;
    const { onPatch, history } = this.props;

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_CONFIRM,
    });

    const multiPayPayload = {
      contract_data: {
        annual_income: +this.props.annual_income * 10000,
        date_of_birth: this.props.date_of_birth,
        employer: this.props.employer,
        first_name: this.props.first_name_kana,
        last_name: this.props.last_name_kana,
        gender: this.props.gender,
        household_size: this.props.household_size,
        marital_status: this.props.marital_status,
        mortgage: this.props.mortgage,
        residence_type: this.props.residence_type,
        contract_address: {
          city: this.props.city,
          line1: this.props.line1,
          line2: this.props.line2,
          state: this.props.state,
          zip: getFormattedZip(this.props.zip),
        },
      },
      number_of_installments: this.props.number_of_installments,
    };

    onPatch(multiPayPayload)
      .then(res => {
        const status = idx(res, _ => _.data.status);

        if (!status || status === paymentStatusMap.REJECTED) {
          history.push('/result/rejected');
        } else {
          history.push('/result/multi-pay');
        }
      })
      .catch(err => {
        errorHandler({ err, history });
      });
  }

  @autobind
  onSelectInstallment(numberOfInstallments: string): void {
    let routeStr: string = '/result/single-pay';
    const { isBig, history, onPatch } = this.props;

    /**
     * this is the NoI (number of installment) that the user select in "select" phase
     */
    if (+numberOfInstallments === 1) {
      if (isBig) {
        history.push('/big/single');
      } else {
        onPatch({
          number_of_installments: 1,
        }).then(
          res => {
            // res is undefined means we have more form to fill, do nothing here
            if (!res) {
              return;
            }

            if (res.data.status === paymentStatusMap.AUTHORIZED) {
              routeStr = '/result/single-pay';
            } else {
              routeStr = '/result/rejected';
            }

            history.push(routeStr);
          },
          err => {
            errorHandler({ err, history });
          }
        );
      }
    } else {
      /**
       * if the NoI >= 2
       */
      const { installmentOptions } = this.props;

      const selectedOption =
        installmentOptions &&
        installmentOptions.find(
          installmentOption => installmentOption.number_of_installments === +numberOfInstallments
        );

      this.props.updateFormPairs({
        ...selectedOption,
        number_of_installments: +numberOfInstallments,
      });

      this.props.history.push('/multi-pay/form');
    }
  }

  render() {
    const { installmentOptions, match } = this.props;

    if (!installmentOptions || !installmentOptions.length) {
      return null;
    }

    switch (match.params.phase) {
      case 'select': {
        return (
          <Frame
            hasCornerBackButton={false}
            helpType="multipay"
          >
            <ShadowScrollbar className="no-padding-bottom">
              <section className="page-title-section alone dotted-bottom">
                <h2 className="page-title">支払方法を選択</h2>
              </section>
              <ul className={styles.list}>
                <Options onSelectInstallment={this.onSelectInstallment} location={location} />
              </ul>
              <div className={styles.memos}>
                <p className={styles.memo}>月額の支払予定額(目安)となります</p>
                <p className={styles.memo}>
                  分割払いの審査が不通過となった場合は、一括払いでの支払いとなります。予めご了承ください。
                </p>
              </div>
            </ShadowScrollbar>
          </Frame>
        );
      }
      case 'form': {
        return <MultiPayForm />;
      }
      case 'confirm': {
        const {
          monthly_amount,
          last_month_amount,
          contract_fee_percentage,
          total_amount,
          total_fee_amount,
          isBig,
        } = this.props;

        if (
          !monthly_amount ||
          !last_month_amount ||
          !contract_fee_percentage ||
          !total_amount ||
          !total_fee_amount
        ) {
          return null;
        }

        return (
          <MultiPayConfirm
            isBig={isBig}
            monthlyAmount={monthly_amount}
            lastMonthAmount={last_month_amount}
            contractFeePercentage={contract_fee_percentage}
            pureTotalAmount={+total_amount - +total_fee_amount}
            totalAmount={total_amount}
            totalFeeAmount={total_fee_amount}
            onPatch={this.onPatch}
          />
        );
      }
      default: {
        return null;
      }
    }
  }
}

export default withRouter(MultiPay);
