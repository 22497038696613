import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FrameHeader from './FrameHeader';
import { updateUIPairs } from '../../redux/modules/ui';

const mapStateToProps = state => ({
  shopName: state.merchant.shopName,
  logoURL: state.merchant.logoURL,
  isReturning: state.auth.isReturning,
  tier: state.payment.tier,
  isBig: state.payment.isBig,
  token: state.payment.token,
  totalAmount: state.payment.totalAmount,
  numberOfInstallments: state.payment.selectedOption
    ? state.payment.selectedOption.installments.length
    : 1,
  monthlyAmount: state.payment.selectedOption
    ? state.payment.selectedOption.installments[0].amount
    : 0,
});

const mapDispatchToProps = (dispatch: Function) => ({
  updateUIPairs: pairs => dispatch(updateUIPairs(pairs)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FrameHeader));
