// @flow
import 'core-js/fn/map';
import 'core-js/fn/set';
import 'core-js/fn/array/find';
import 'core-js/fn/array/from';
import 'core-js/fn/array/fill';
import 'core-js/fn/string/starts-with';
import 'core-js/fn/array/includes';
import 'core-js/es6/symbol';
/* main */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import idx from 'idx';
import { BrowserRouter as Router } from 'react-router-dom';
/* components */
import App from './components/App/AppContainer';
import AppDemo from './components/Demo/AppDemo/AppContainer'; // remove this `is_demo` integration once demostration has done on production
import { store } from './redux/modules';
import { isV1, fromV1 } from './utils/version';
import { parent } from './utils';
import './index.scss'; // mostly global styles

import { MixpanelHelpers } from './utils/mixpanel';

const voidFn = () => {};

if (typeof window !== 'undefined') {
  window.console = window.console || { log: voidFn, error: voidFn, warn: voidFn }; // avoid old IE issue
}

const render = ({
  config,
  payload,
  is_demo,
}: {
  config?: Object, // this is acutally required field, we mark it as optional simply for development purpose
  payload?: ?Object,
  is_demo?: Boolean, // remove this `is_demo` integration once demostration has done on production
}) => {
  const target = document.getElementById('root');

  if (target) {
    console.log('ReactDOM re-rendered.');

    ReactDOM.render(
      <Router>
        <Provider store={store}>
          {/* remove this `is_demo` integration once demostration has done on production */}
          {is_demo ? <AppDemo key={`${+new Date()}`} merchantConfig={config} merchantPayload={payload} /> :<App key={`${+new Date()}`} merchantConfig={config} merchantPayload={payload} />}
        </Provider>
      </Router>,
      target
    );
  }
};

const getValidPayload = (payloadString: string): ?Object => {
  try {
    const payload = JSON.parse(payloadString);

    MixpanelHelpers.registerMerchantPayload(payload);

    if (isV1(payload)) {
      return fromV1(payload);
    }

    // NOTE: AirTrip, a top merchant, uses v2 payload structure but has the date_of_birth
    // under dob key as opposed of data_of_birth under the buyer object. We check for both to
    // ensure that the dob gets prefilled correctly
    const buyerDateOfBirth = idx(payload, _ => _.buyer.date_of_birth);
    const buyerDob = idx(payload, _ => _.buyer.dob);

    return {
      ...payload,
      buyer: {
        ...payload.buyer,
        date_of_birth: buyerDateOfBirth || buyerDob,
      },
    };
  } catch (error) {
    return {};
  }
};

if (window !== window.top) {
  console.log('*in iFrame*');

  window.addEventListener(
    'message',
    event => {
      if (event.data._id) {
        parent.setID(event.data._id);
      }

      // check checkout host domain to avoid fake checkout can be build easily
      // @host-checker.js

      if (document.referrer && document.referrer.indexOf(event.origin) !== 0) {
        return;
      }

      if (event.data && event.data.event === 'paidy_checkout:init') {
        console.log('paidy_checkout:init CALLED', event.data);

        // this code is only for demo - start
        const payload = JSON.parse(event.data.payload);
        const is_demo = payload.is_demo;
        delete payload.is_demo;
        event.data.payload = JSON.stringify(payload);
        // this code is only for demo - end

        const validatedPayload = getValidPayload(event.data.payload);
        const _config = JSON.parse(event.data.config);

        render({
          config: _config,
          payload: validatedPayload,
          is_demo, // remove this `is_demo` integration once demostration has done on production
        });

        // This event is to tell SDK checkout launched successful
        // Also useful when checkout is not available (e.g. server down)
        parent.postMessage({
          event: 'paidy_checkout:inited',
          payload: {},
        });
      }
    },
    false
  );
} else {
  try {
    if (process.env.NODE_ENV !== 'production') {
      console.log('*not in iFrame*');

      render({ config: undefined, payload: undefined });
    }
  } catch (error) {
    // do nth
  }
}
