// @flow
import React from 'react';
import cx from 'classnames';

import styles from './ConfirmItem.scss';

type Props = {
  title: string,
  description: string,
  explanation?: string,
  children: ?any,
};

export default function ConfirmItem({ title, description, explanation, children }: Props) {
  return (
    <div
      className={cx(
        styles['main-container'],
        explanation ? styles['padding-with-explanation'] : styles.padding
      )}
      data-testid="npay-confirm-item"
    >
      <div className={cx(styles.container)}>
        <span className={cx(styles.title)} data-testid="npay-confirm-item-title">
          {title}
        </span>
        <div className={cx(styles['left-container'])}>
          <span className={cx(styles.description)} data-testid="npay-confirm-item-description">
            {description}
          </span>
        </div>
      </div>
      <div>
        {explanation && (
          <span className={cx(styles.explanation)} data-testid="npay-confirm-item-explanation">
            {explanation}
          </span>
        )}
      </div>
      {children}
    </div>
  );
}

ConfirmItem.defaultProps = {
  explanation: undefined,
};
