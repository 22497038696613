import React, { useEffect } from 'react';
import { MixpanelHelpers, MIXPANEL_ACTION_PAGE_VIEW } from '../../utils/mixpanel';

import styles from './PlusUpgradePending.scss';

const videoSrc = require('../../assets/plus-upgrade.mp4');
const videoSrcMobile = require('../../assets/plus-upgrade-mobile.mp4');

const PlusUpgradeWaitingScreen = () => {
  useEffect(() => {
    MixpanelHelpers.trackAction({
      customPath: 'Waiting for Plus Upgrade Result Video',
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }, []);

  return (
    <>
      <div className={styles['heading']}>
        <b>画面を開いたままお待ちください。</b>
        <p>
          お手続き内容の確認は1分程度で完了します。
          <br />
          画面を閉じると購入が完了できませんので、ご注意ください。
          <br />
        </p>
      </div>
      <div className={styles['content']}>
        <video preload="true" autoPlay loop muted playsInline className={styles['video-component']}>
          <source src={videoSrc} />
        </video>
      </div>
      <div className={styles['content-mobile']}>
        <video preload="true" autoPlay loop muted playsInline className={styles['video-component']}>
          <source src={videoSrcMobile} />
        </video>
        <div className={styles['video-scroll-content']} />
      </div>
    </>
  );
};

export default PlusUpgradeWaitingScreen;
