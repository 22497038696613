// @flow
/* eslint-disable camelcase, jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import autobind from 'autobind-decorator';

import Frame from '../Frame/FrameContainer';
import { getAge, debouncedZipReq, getFormattedZip, scrollToFirstError } from '../../utils';
import { ADULT_AGE_THRESHOLD } from '../../constants';
import withValidation from '../Form/withValidation';
import validators from '../Form/validators';
import {
  MixpanelHelpers,
  MIXPANEL_ACTION_PAGE_VIEW,
  MIXPANEL_ACTION_SUBMIT,
  MIXPANEL_ACTION_FORM_ERROR,
} from '../../utils/mixpanel';
import FormRegular from './FormRegular';
import styles from './Plus.scss';

const formValidators = {
  city: validators.empty,
  line2: validators.empty,
  state: validators.empty,
  zip: validators.zip,
  date_of_birth: validators.dateOfBirth,
  first_name_kana: validators.katakana,
  last_name_kana: validators.katakana,
  first_name_kanji: validators.kanji,
  last_name_kanji: validators.kanji,
};

type Props = {
  onSubmitForm: Function,
  // misc
  updateFormPairs: Function,
  location: Object,
  // form fields
  city: string,
  line1: string,
  line2: ?string,
  state: string,
  zip: string,
  date_of_birth: string,
  first_name_kana: string,
  first_name_kanji: string,
  last_name_kana: string,
  last_name_kanji: string,
  // validation
  onValidate: Function,
  onValidateAll: Function,
  errors: Object,
};

type State = {
  isAdult: boolean,
  errored: Object,
};

class PlusRegular extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isAdult: props.date_of_birth ? getAge(props.date_of_birth) >= ADULT_AGE_THRESHOLD : true,
      hasAdultConsent: false,
      errored: {},
    };
  }

  componentDidMount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.errors).length) {
      const newState = {};

      Object.keys(nextProps.errors).forEach(key => {
        if (nextProps[key]) {
          newState[key] = true;
        }
      });

      this.setState({ errored: newState });
    }

    if (nextProps.date_of_birth && this.props.date_of_birth !== nextProps.date_of_birth) {
      this.setState({
        isAdult: getAge(nextProps.date_of_birth) >= ADULT_AGE_THRESHOLD,
      });
    }
  }

  componentWillUnmount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      shouldEndTracker: true,
    });
  }

  @autobind
  onChange(event: SyntheticInputEvent<HTMLInputElement>) {
    const { name, checked, value } = event.currentTarget;

    if (name === 'has_adult_consent') {
      this.setState({ hasAdultConsent: checked });

      return;
    }

    if (name === 'zip') {
      if (value.length === 7) {
        const { updateFormPairs } = this.props;

        debouncedZipReq({
          updateFormPairs,
          value,
        });
      }
    }

    this.props.updateFormPairs({
      [name]: value,
    });
  }

  @autobind
  onBlur(event: SyntheticInputEvent<HTMLInputElement>) {
    this.props.onValidate({ name: event.currentTarget.name });
  }

  @autobind
  onSubmit(event: SyntheticInputEvent<HTMLElement>) {
    const { pathname } = this.props.location;
    const {
      city,
      line1,
      line2,
      state,
      zip,
      date_of_birth,
      first_name_kana,
      first_name_kanji,
      last_name_kana,
      last_name_kanji,
      onSubmitForm,
      onValidateAll,
    } = this.props;

    event.preventDefault();
    MixpanelHelpers.trackAction({ pathname, actionName: MIXPANEL_ACTION_SUBMIT });

    const errors = onValidateAll({ checkTouch: false });

    if (!Object.keys(errors).find(key => !!errors[key])) {
      const plusContractData = {
        address: {
          city,
          line1,
          line2,
          state,
          zip: getFormattedZip(zip),
        },
        date_of_birth,
        first_name_kana,
        first_name_kanji,
        last_name_kana,
        last_name_kanji,
      };

      onSubmitForm(plusContractData);
    } else {
      scrollToFirstError();

      const erroredFields = Object.keys(errors).filter(key => !!errors[key]);

      MixpanelHelpers.trackAction({
        pathname,
        actionName: MIXPANEL_ACTION_FORM_ERROR,
        extraData: {
          'Errored Fields': erroredFields,
        },
      });
    }
  }

  @autobind
  isInvalid() {
    const { isAdult, hasAdultConsent } = this.state;
    const {
      city,
      line2,
      state,
      zip,
      date_of_birth,
      first_name_kana,
      first_name_kanji,
      last_name_kana,
      last_name_kanji,
    } = this.props;

    const hasCity = formValidators.city(city);
    const hasLine2 = formValidators.line2(line2);
    const hasState = formValidators.state(state);
    const hasZip = formValidators.zip(zip);
    const hasDateOfBirth = formValidators.date_of_birth(date_of_birth);
    const hasFirstNameKana = formValidators.first_name_kana(first_name_kana);
    const hasLastNameKana = formValidators.last_name_kana(last_name_kana);
    const hasFirstNameKanji = formValidators.first_name_kanji(first_name_kanji);
    const hasLastNameKanji = formValidators.last_name_kanji(last_name_kanji);

    return (
      Boolean(hasCity) ||
      Boolean(hasLine2) ||
      Boolean(hasState) ||
      Boolean(hasZip) ||
      Boolean(hasDateOfBirth) ||
      Boolean(hasFirstNameKana) ||
      Boolean(hasLastNameKana) ||
      Boolean(hasFirstNameKanji) ||
      Boolean(hasLastNameKanji) ||
      (!isAdult && !hasAdultConsent)
    );
  }

  render() {
    const { isAdult, hasAdultConsent } = this.state;

    return (
      <Frame hasCornerBackButton={false} helpType="plus">
        <div className={styles['scrollable-content']}>
          <b className={styles.title}>超あと払いお申し込み</b>
          <FormRegular
            {...this.props}
            onChange={this.onChange}
            onBlur={this.onBlur}
            isAdult={isAdult}
            hasAdultConsent={hasAdultConsent}
            onValidate={this.props.onValidate}
          />
        </div>
        <div className={styles.controls}>
          <button
            id="btn-next"
            className={styles.button}
            disabled={this.isInvalid()}
            onClick={this.onSubmit}
          >
            次へ
          </button>
        </div>
      </Frame>
    );
  }
}

export default withRouter(withValidation(formValidators)(PlusRegular));
