// @flow
import React, { PureComponent } from 'react';
import autobind from 'autobind-decorator';
import { Scrollbars as Scrollbar } from 'react-custom-scrollbars';
import cx from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import styles from './ShadowScrollbar.scss';

import throttle from '../../utils/throttle-decorator';

type ScrollbarValuesType = {
  clientHeight: number,
  clientWidth: number,
  left: number,
  scrollHeight: number,
  scrollLeft: number,
  scrollTop: number,
  scrollWidth: number,
  top: number,
  // ui
  updateUIPairs: Function,
};

type State = {
  topShadowOpacity: number,
  bottomShadowOpacity: number,
};

const SCROLL_FRAME_WAIT = 50;

const TrackerVertical = ({ style, ...props }: *) => (
  <div style={style} className={cx(styles.tracker, styles['tracker-vertical'])} {...props} />
);

const ThumbVertical = ({ style, ...props }: *) => (
  <div style={style} className={styles.thumb} {...props} />
);

const TrackerHorizontal = ({ style, ...props }: *) => (
  <div style={style} className={cx(styles.tracker, styles['tracker-horizontal'])} {...props} />
);

const ThumbHorizontal = ({ style, ...props }: *) => (
  <div style={style} className={styles.thumb} {...props} />
);

class ShadowScrollbar extends PureComponent<any, State> {
  scrollbar: any;
  topShadow: any;
  bottomShadow: any;
  lastScrollTop: number = 0;

  state = {
    topShadowOpacity: 0,
    bottomShadowOpacity: 0,
  };

  @autobind
  componentDidMount() {
    disableBodyScroll(this.scrollbar.view);
  }

  @autobind
  componenWillUnMount() {
    enableBodyScroll(this.scrollbar.view);
  }

  @autobind
  handleUpdate(values: ScrollbarValuesType) {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const topShadowOpacity = (1 / 20) * Math.min(scrollTop, 20);
    const bottomScrollTop = scrollHeight - clientHeight;
    const bottomShadowOpacity =
      (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20));

    this.setState({
      topShadowOpacity,
      bottomShadowOpacity,
    });
  }

  @autobind
  @throttle(SCROLL_FRAME_WAIT)
  onScrollFrame(values: any) {
    const { scrollTop } = values;
    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
  }

  render() {
    const { topShadowOpacity, bottomShadowOpacity } = this.state;

    return (
      <div className={styles.container} id="shadow_scrollbar">
        <div className={styles.inner}>
          <Scrollbar
            id="real_scrollbar"
            className={styles.target}
            onScrollFrame={this.onScrollFrame}
            ref={scrollbar => {
              this.scrollbar = scrollbar;
            }}
            onUpdate={this.handleUpdate}
            renderTrackHorizontal={TrackerHorizontal}
            renderTrackVertical={TrackerVertical}
            renderThumbHorizontal={ThumbHorizontal}
            renderThumbVertical={ThumbVertical}
            hideTracksWhenNotNeeded
          >
            <div className={cx(styles.content, this.props.className, 'content')}>
              {this.props.children}
            </div>
          </Scrollbar>
          <div
            ref={topShadow => {
              this.topShadow = topShadow;
            }}
            className={styles['top-shadow']}
            style={{
              opacity: topShadowOpacity,
            }}
          />
          <div
            ref={bottomShadow => {
              this.bottomShadow = bottomShadow;
            }}
            className={styles['bottom-shadow']}
            style={{
              opacity: bottomShadowOpacity,
            }}
          />
        </div>
      </div>
    );
  }
}

export default ShadowScrollbar;
