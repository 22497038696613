// @flow
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import styles from './Options.scss';
import {
  MixpanelHelpers,
  MIXPANEL_ACTION_PAGE_VIEW,
  MIXPANEL_ACTION_INSTALLMENT_SELECT,
} from '../../utils/mixpanel';

type optionType = {
  number_of_installments: number,
  monthly_amount: number,
};

type Props = {
  onSelectInstallment: Function,
  installmentOptions: Array<optionType>,
  totalAmount: number,
  location: Object,
};

class Options extends Component<Props, void> {
  componentDidMount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }

  componentWillUnmount() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      shouldEndTracker: true,
    });
  }

  @autobind
  onSelectOption(event: SyntheticInputEvent<HTMLButtonElement>) {
    const { pathname } = this.props.location;
    const installmentPeriod = event.currentTarget.getAttribute('data-times');

    event.preventDefault();

    this.props.onSelectInstallment(installmentPeriod);

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_INSTALLMENT_SELECT,
      extraData: {
        'Installment Number': installmentPeriod,
      },
    });
  }

  render() {
    const offeredOptions = this.props.installmentOptions.map(option => (
      <li className={styles['list-item']} key={option.number_of_installments}>
        <button
          className={cx(styles['multi-pay'], styles.unselected, 'multi-pay-option')}
          onClick={this.onSelectOption}
          data-times={option.number_of_installments}
        >
          <span className={styles['number-of-installments']}>{`${
            option.number_of_installments
          }回払い`}</span>
          <span className={styles['total-amount']}>
            ¥{option.monthly_amount.toLocaleString('ja-jp')}
          </span>
        </button>
      </li>
    ));

    return (
      <Fragment>
        <li className={styles['default-block']}>
          <button
            id="multi_pay_one"
            className={styles['multi-pay']}
            onClick={this.onSelectOption}
            data-times={1}
          >
            <span className={styles['number-of-installments']}>1回払い</span>
            <span className={styles['total-amount']}>
              ¥{this.props.totalAmount.toLocaleString('ja-jp')}
            </span>
          </button>
        </li>
        {offeredOptions}
      </Fragment>
    );
  }
}

export default withRouter(Options);
