// @flow
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { isDesktop } from 'react-device-detect';

import { installmentTypeMap, paymentStatusMap } from '../../../constants';
import { errorCodes, errorCodecV2 } from '../../../constants/error-codes';
import { onClose } from '../../../utils';
import {
  MixpanelHelpers,
  BAD_RESULT_TYPES_DISPLAY_NAMES,
  MIXPANEL_ACTION_PAGE_VIEW,
  MIXPANEL_ACTION_LINK_CLICK,
} from '../../../utils/mixpanel';
import PaymentOrderItemForbidden from '../../Error/PaymentOrderItemForbidden';
import PaymentRejected from '../../Error/PaymentRejected';
import Frame from '../FrameDemo/FrameContainer';
import Pending from '../../Pending/Pending';
import Success from '../../Success/Success';
import errorScreenContentMobile from '../../Error/error-screen-content-mobile';
import errorScreenContentDesktop from '../../Error/error-screen-content-desktop';

import ErrorContentMobile from '../../Error/ErrorContentMobile';
import ErrorContentDesktop from '../../Error/ErrorContentDesktop';

const CLOSE_DELAY_DEFAULT = 1600;
const CLOSE_DELAY_TOKEN = 1800;

const ContentByType = (props: {
  type: string,
  pathname: string,
  merchantConfig: Object,
  merchantPayload: Object,
}) => {
  const ErrorContent = isDesktop ? ErrorContentDesktop : ErrorContentMobile;
  const errorScreenContent = isDesktop ? errorScreenContentDesktop : errorScreenContentMobile;
  const { shopName, totalAmount, pathname, merchantPayload, merchantConfig } = props;

  switch (props.type) {
    case errorCodes.PAYMENT_ORDER_ITEM_FORBIDDEN:
      return <PaymentOrderItemForbidden />;
    case errorCodes.CONSUMER_BILL_STATEMENT_OVERDUE:
    case errorCodecV2.OVERDUE_BILL:
      return (
        <ErrorContent
          {...errorScreenContent[errorCodecV2.OVERDUE_BILL]}
          onPress={() => {
            MixpanelHelpers.trackAction({
              pathname,
              actionName: MIXPANEL_ACTION_LINK_CLICK,
              actionItem: 'Pay bill',
            });
          }}
        />
      );

    case errorCodes.REJECTED:
    case errorCodes.TOKEN_REJECTED: {
      return <PaymentRejected />;
    }
    case paymentStatusMap.PENDING: {
      return <Pending />;
    }

    case errorCodecV2.EXCEEDED_PLUS_LIMIT: {
      return (
        <ErrorContent
          {...errorScreenContent[errorCodecV2.EXCEEDED_PLUS_LIMIT]}
          onPress={() => {
            MixpanelHelpers.trackAction({
              pathname,
              actionName: MIXPANEL_ACTION_LINK_CLICK,
              actionItem: 'Check limit',
              extraData: {
                'Merchant Config': merchantConfig,
                'Store Name': shopName,
                'Order Amount': totalAmount,
                'Merchant Payload': merchantPayload,
              },
            });
          }}
        />
      );
    }

    case errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE:
      return (
        <ErrorContent
          {...errorScreenContent[errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE]}
          onPress={() => {
            MixpanelHelpers.trackAction({
              pathname,
              actionName: MIXPANEL_ACTION_LINK_CLICK,
              actionItem: 'PayNow',
              extraData: {
                'Merchant Config': merchantConfig,
                'Store Name': shopName,
                'Order Amount': totalAmount,
                'Merchant Payload': merchantPayload,
              },
            });
          }}
        />
      );

    case errorCodecV2.MERCHANT_REQUIRES_KYC:
      return (
        <ErrorContent
          {...errorScreenContent[errorCodecV2.MERCHANT_REQUIRES_KYC]}
          onPress={() => {
            MixpanelHelpers.trackAction({
              pathname,
              actionName: MIXPANEL_ACTION_LINK_CLICK,
              actionItem: 'eKYC',
              extraData: {
                'Merchant Config': merchantConfig,
                'Store Name': shopName,
                'Order Amount': totalAmount,
                'Merchant Payload': merchantPayload,
              },
            });
          }}
        />
      );

    case errorCodecV2.MERCHANT_REQUIRES_HOSHINO:
      return (
        <ErrorContent
          {...errorScreenContent[errorCodecV2.MERCHANT_REQUIRES_HOSHINO]}
          onPress={() => {
            MixpanelHelpers.trackAction({
              pathname,
              actionName: MIXPANEL_ACTION_LINK_CLICK,
              actionItem: 'eKYC',
              extraData: {
                'Merchant Config': merchantConfig,
                'Store Name': shopName,
                'Order Amount': totalAmount,
                'Merchant Payload': merchantPayload,
              },
            });
          }}
        />
      );

    case errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE:
      return (
        <ErrorContent
          {...errorScreenContent[errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE]}
          onPress={() => {
            MixpanelHelpers.trackAction({
              pathname,
              actionName: MIXPANEL_ACTION_LINK_CLICK,
              actionItem: 'Boost',
              extraData: {
                'Merchant Config': merchantConfig,
                'Store Name': shopName,
                'Order Amount': totalAmount,
                'Merchant Payload': merchantPayload,
              },
            });
          }}
        />
      );

    case errorCodecV2.EXCEEDED_NLP_LIMIT:
      return (
        <ErrorContent
          {...errorScreenContent[errorCodecV2.EXCEEDED_NLP_LIMIT]}
          onPress={() => {
            MixpanelHelpers.trackAction({
              pathname,
              actionName: MIXPANEL_ACTION_LINK_CLICK,
              actionItem: 'Check limit',
              extraData: {
                'Merchant Config': merchantConfig,
                'Store Name': shopName,
                'Order Amount': totalAmount,
                'Merchant Payload': merchantPayload,
              },
            });
          }}
        />
      );

    case errorCodecV2.GENERIC:
      return (
        <ErrorContent
          {...errorScreenContent[errorCodecV2.GENERIC]}
          onPress={() => {
            MixpanelHelpers.trackAction({
              pathname,
              actionName: MIXPANEL_ACTION_LINK_CLICK,
            });
          }}
        />
      );

    default: {
      return <Success />;
    }
  }
};

const Result = (props: {
  shopName: string,
  totalAmount: number,
  merchantConfig: Object,
  merchantPayload: Object,
}) => {
  const totalAmount = useSelector(state => state.payment.totalAmount);
  const { pathname } = useLocation();
  const { type } = useParams();

  const isError =
    Object.values(errorCodes).includes(type) || Object.values(errorCodecV2).includes(type);

  useEffect(() => {
    // Automatically close Checkout if the Success screen is shown
    if (!isError && type !== paymentStatusMap.PENDING) {
      MixpanelHelpers.trackAction({
        pathname,
        actionName: MIXPANEL_ACTION_PAGE_VIEW,
      });

      if (totalAmount) {
        MixpanelHelpers.recordRevenue(totalAmount);
      }

      setTimeout(
        onClose.bind(this),
        type === installmentTypeMap.TOKEN ? CLOSE_DELAY_TOKEN : CLOSE_DELAY_DEFAULT
      );
    } else {
      MixpanelHelpers.trackAction({
        ...(isError && { customPath: BAD_RESULT_TYPES_DISPLAY_NAMES[type] || type }),
        ...(!isError && { pathname }),
        actionName: MIXPANEL_ACTION_PAGE_VIEW,
      });
    }
  }, []);

  return (
    <Frame
      showInstallments={!isError}
      hasHeader={isError || type === paymentStatusMap.PENDING}
      hasFooter={isError}
      hasCornerBackButton={false}
      helpType={isError ? 'rejection' : null}
    >
      <ContentByType
        type={type}
        pathname={pathname}
        shopName={props.shopName}
        totalAmount={props.totalAmount}
        merchantConfig={props.merchantConfig}
        merchantPayload={props.merchantPayload}
      />
    </Frame>
  );
};

const mapStateToProps = state => ({
  shopName: state.merchant.shopName,
  totalAmount: state.payment.totalAmount,
});

export default connect(mapStateToProps)(Result);
