export const faqMap = {
  login: ['3GyjH6p7OEQsS0CmmcKqsi', 'GvcppF0aOacOA8kIEieQI', '5y2fv3uq3ekekygQ2euGCI'],
  otp: ['3JmOGGW8sMiWeq2W6aWuQE', '3GyjH6p7OEQsS0CmmcKqsi', '5y2fv3uq3ekekygQ2euGCI'],
  plus: ['6Eo0ZONoQg0qSwoGMsYsmu', '4iB2FBKgbCsIUqcASqcwmO', '3XzVaFLtjOI4IqCKMkUKUY'],
  digital: ['3GyjH6p7OEQsS0CmmcKqsi', 'QnYo7yYyS4wEgKUiOIswS', '17vD9SK58aYosAOiimMi4'],
  big: ['3GyjH6p7OEQsS0CmmcKqsi', 'QnYo7yYyS4wEgKUiOIswS', '17vD9SK58aYosAOiimMi4'],
  token: ['3GyjH6p7OEQsS0CmmcKqsi', 'QnYo7yYyS4wEgKUiOIswS', '17vD9SK58aYosAOiimMi4'],
  rejection: ['FqCAzEyQQ8CQm66YCqwk2', '6qLFO2y8dUI8geOYQmIOcW', '1CZq3sX56Yk4iuUS0WcYWc'],
  threepay: [
    'GB8R0iMpoGKWImQyQQsCA',
    '2SUpkyskvyVyvJPPiymQaP',
    '2T6qA374mDU23oi90BfHtp',
    '6W27YctU9LJaA8zm0GKobl',
    '3U4zJCWcIjmZM4TSAr4WwT',
  ],
};

export default {
  faqMap,
};
