// @@flow
import { loadingTypes } from '../../constants';

const UPDATE_PAIRS = '@@checkout/ui/update_pairs';

const initialState = {
  isLoading: false,
  loadingType: loadingTypes.REGULAR,
  helpType: undefined,
  pinCodeSentCount: 0,
};

const ui = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAIRS: {
      return {
        ...state,
        ...action.pairs,
      };
    }
    default:
      return state;
  }
};

export const updateUIPairs = pairs => ({
  type: UPDATE_PAIRS,
  pairs,
});

export default ui;
