// @flow
import React from 'react';
import QRCode from 'react-qr-code';
import { useHistory } from 'react-router';

import styles from './Error.scss';

type Props = {
  title: string,
  description: string[],
  onClick: () => void,
  buttonText?: string,
  buttonHref: string,
  hasFooter: boolean,
  bulletedLists?: string[],
  noteText?: string[],
  downloadImage?: any,
  qrCodeContent?: string,
  bottomDescription?: string[],
  btnGoBack?: boolean,
  btnGoToPath?: String,
  body: any[],
  buttonWrapperClass: String,
};

export default function ErrorContent({
  title,
  description,
  onClick,
  buttonText,
  buttonHref,
  hasFooter,
  bulletedLists,
  noteText,
  downloadImage,
  qrCodeContent,
  bottomDescription,
  btnGoBack,
  btnGoToPath,
  body,
  buttonWrapperClass,
}: Props) {
  const history = useHistory();

  const onClickHandle = () => {
    if (btnGoBack) {
      history.goBack();
    }
    if (btnGoToPath) {
      history.push(btnGoToPath);
    }
    typeof onClick === 'function' && onClick();
  };

  return (
    <>
      {body ? (
        <div className={styles['scrollable-content-desktop']}>{body}</div>
      ) : (
        <div className={styles['scrollable-content-desktop']}>
          <b className={styles.title}>{title}</b>
          {description.map(text => (
            <p key={text} className={styles['description']}>
              {text}
            </p>
          ))}
          {bulletedLists
            ? bulletedLists.map(bulletedText => (
                <div className={styles['bulleted-item']} key={bulletedText}>
                  <img src={require('../../assets/bulleted-dark.svg')} alt="bulleted icon" />
                  <span>{bulletedText}</span>
                </div>
              ))
            : null}
          {bottomDescription
            ? bottomDescription.map(text => (
                <p key={text} className={styles['description']}>
                  {text}
                </p>
              ))
            : null}
          {downloadImage ? (
            <div className={styles['download-container']}>
              <img src={downloadImage} className={styles['download-image']} />
              <div className={styles['download-qr-container']}>
                {qrCodeContent ? (
                  <>
                    <div className={styles['qr-container']}>
                      <QRCode value={qrCodeContent} size={72} />
                    </div>
                    <p className={styles['qr-title']}>
                      QRコードを読み取って
                      <br />
                      アプリで確認する
                    </p>
                    <p className={styles['qr-description']}>
                      ※エラーが表示される場合は下記からご利用ください
                    </p>
                    <div className={styles['store-container']}>
                      <a
                        href="https://apps.apple.com/jp/app/paidy/id1220373112"
                        className={styles['app-store-download']}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require('../../assets/app-store-download.svg')}
                          width={66}
                          height={24}
                        />
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.paidy.paidy&amp;hl=ja"
                        className={styles['google-play-download']}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require('../../assets/google-play-download.svg')}
                          width={81}
                          height={24}
                          className={styles['google-play-download']}
                        />
                      </a>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
          {noteText ? (
            <div className={styles['notification-text']}>
              {noteText.map(text => (
                <p key={text}>{text}</p>
              ))}
            </div>
          ) : null}
        </div>
      )}
      {buttonText ? (
        <div className={`${styles.controls} ${!hasFooter ? styles['controls-no-footer'] : buttonWrapperClass ? styles[buttonWrapperClass] :''}`}>
          {buttonHref ? (
            <a
              className={`${styles.button} ${styles['button-link']}`}
              onClick={onClick}
              target="_blank"
              data-testid="btn-close"
              href={buttonHref}
              rel="noreferrer"
            >
              {buttonText}
            </a>
          ) : (
            <button className={`${styles.button}`} onClick={onClickHandle} data-testid="btn-close">
              {buttonText}
            </button>
          )}
        </div>
      ) : null}
    </>
  );
}
