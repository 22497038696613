// @flow
import React, { useEffect } from 'react';
import styles from './Error.scss';
import {
  MixpanelHelpers,
  MIXPANEL_ACTION_PAGE_VIEW,
  MIXPANEL_ACTION_BUTTON_CLICK,
} from '../../utils/mixpanel';

const KYCRequired = () => {
  useEffect(() => {
    MixpanelHelpers.trackAction({
      pathname: 'Upgrade Prompt in Digital Checkout ',
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }, []);

  const onBadgeClick = () => {
    MixpanelHelpers.trackAction({
      pathname: 'Upgrade Prompt in Digital Checkout ',
      actionName: MIXPANEL_ACTION_BUTTON_CLICK,
      actionItem: `Digital_Checkout_Upgrade_Prompt`,
    });
  };

  return (
    <>
      <div className={styles['scrollable-content']}>
        <div className={styles['kyc-required-content-wrapper']}>
          <div className={styles['non-appflyer-info-icon']}>
            <img
              src={require('../../assets/icon-info-colored.svg')}
              alt="info icon"
              width={64}
              height={64}
            />
          </div>
          <p className={styles['kyc-required-title']}>
            <b>本人確認が必要です</b>
          </p>

          <p className={styles['kyc-required-subtitle']}>
            ペイディアプリをダウンロードして、
            <br /> 本人確認を行ってください。
          </p>

          <div className={styles['kyc-required-install-mobile-app']}>
            <a
              href="https://apps.apple.com/jp/app/paidy/id1220373112"
              target="_blank"
              rel="nofollow noopener noreferrer"
              onClick={() => onBadgeClick('Apple App Store')}
              data-testid="kyc-required-screen-apple-app-store-badge-button"
            >
              <img
                src={require('../../assets/badge-apple-app-store.png')}
                alt="Apple App Store badge"
                width={168}
                height={48}
                data-testid="kyc-required-screen-apple-app-store-badge"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.paidy.paidy&amp;hl=ja"
              target="_blank"
              rel="nofollow noopener noreferrer"
              onClick={() => onBadgeClick('Apple App Store')}
              data-testid="kyc-required-screen-google-play-store-badge-button"
            >
              <img
                src={require('../../assets/badge-google-play-store.png')}
                alt="Apple App Store badge"
                width={168}
                height={48}
                data-testid="kyc-required-screen-google-play-store-badge"
              />
            </a>
          </div>

          <div className={styles['kyc-required-instructions']}>
            <p className={styles['kyc-required-title']}>
              <b>最短5分、３ステップで完了。</b>
            </p>
            <p className={styles['kyc-required-subtitle']}>
              ①アプリの「アカウント」から本人確認を行ってください。運転免許証またはマイナンバーカードがご利用いただけます。
              <br />
              <br />
              ②通常数分以内に、結果をメールでお知らせ。アプリでご利用可能額をご確認ください。
              <br />
              <br />
              ③再度購入をお試しください。
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default KYCRequired;
