// @flow
import React from 'react';

import { onClose } from '../../utils';
import styles from './Error.scss';

const BadRequest = () => (
  <>
    <div className={styles['scrollable-content']}>
      <b className={styles.title}>現在こちらのショップでペイディが利用できません</b>
      <p>誠に申し訳ございませんが、時間をおいてから再度お試しください。</p>
      <p>
        <b>解決しない場合</b>
      </p>
      <p>
        お手数をおかけいたしますが、ご利用になっているオンラインショップへお問い合わせいただくか、
        ペイディカスタマーサポートまでお問い合わせください。
      </p>
      <p>
        <b>ペイディカスタマーサポート</b>
        <br />
        <a href="tel:0120-971-918">0120-971-918</a>
      </p>
    </div>
    <div className={styles.controls}>
      <button className={styles.button} onClick={() => onClose()} data-testid="btn-close">
        他の支払い方法を選ぶ
      </button>
    </div>
  </>
);

export default BadRequest;
