// @flow
import React from 'react';
import idx from 'idx';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isDesktop } from 'react-device-detect';

import { INTERNAL_ERROR_TYPES, BAD_REQUEST_TYPES } from '../../../constants';
import { errorCodes, errorCodecV2 } from '../../../constants/error-codes';
import Frame from '../FrameDemo/FrameContainer';

import TokenAuthenticationExpired from './../../Error/TokenAuthenticationExpired';
import PaymentOrderItemForbidden from './../../Error/PaymentOrderItemForbidden';
import ConsumerPhoneMismatch from './../../Error/ConsumerPhoneMismatch';
import ConsumerEmailMismatch from './../../Error/ConsumerEmailMismatch';
import BadRequest from './../../Error/BadRequest';
import PaymentAuthenticationFailed from './../../Error/PaymentAuthenticationFailed';
import HoukatsuPreviouslyRejected from './../../Error/HoukatsuPreviouslyRejected';
import KYCRequired from './../../Error/KYCRequired';

import ErrorContentMobile from './../../Error/ErrorContentMobile';
import ErrorContentDesktop from './../../Error/ErrorContentDesktop';

import {
  MixpanelHelpers,
  MIXPANEL_ACTION_PAGE_VIEW,
  MIXPANEL_ACTION_LINK_CLICK,
} from '../../../utils/mixpanel';
import errorScreenContentMobile from './../../Error/error-screen-content-mobile';
import errorScreenContentDesktop from './../../Error/error-screen-content-desktop';

const ErrorDemo = ({
  history,
  match,
  code,
  shopName,
  totalAmount,
  merchantConfig,
  merchantPayload,
}: {
  history: Object,
  match: Object,
  code: string,
  totalAmount: number,
  shopName: string,
  merchantConfig: Object,
  merchantPayload: Object,
}) => {
  const errorCode = code || idx(match, _ => _.params.type);
  const { pathname } = history.location;
  const ErrorContent = isDesktop ? ErrorContentDesktop : ErrorContentMobile;
  const errorScreenContent = isDesktop ? errorScreenContentDesktop : errorScreenContentMobile;
  let Content = () => <></>;

  switch (true) {
    // TODO: Remove old codes after some time since it is going
    //       to be deprecated confirm with Payments team (Leonardo)
    /*<OLD ERROR CODES>*/
    case errorCode === errorCodes.TOKEN_AUTHENTICATION_EXPIRED: {
      Content = () => <TokenAuthenticationExpired />;

      break;
    }
    case errorCode === errorCodes.PAYMENT_ORDER_ITEM_FORBIDDEN: {
      Content = () => <PaymentOrderItemForbidden />;

      break;
    }
    // TODO: add errorCode === '404' (resource not found)
    case errorCode === errorCodes.CONSUMER_PHONE_MISMATCH: {
      Content = () => <ConsumerPhoneMismatch />;

      break;
    }
    case errorCode === errorCodes.TOKEN_AUTHENTICATION_FAILED:
    case errorCode === errorCodes.PAYMENT_AUTHENTICATION_FAILED: {
      Content = () => <PaymentAuthenticationFailed />;

      break;
    }
    case errorCode === errorCodes.CONSUMER_EMAIL_MISMATCH: {
      Content = () => <ConsumerEmailMismatch />;

      break;
    }
    case errorCode === errorCodes.HOUKATSU_PREVIOUSLY_REJECTED: {
      Content = () => <HoukatsuPreviouslyRejected />;

      break;
    }
    case errorCode === errorCodes.KYC_REQUIRED: {
      Content = () => <KYCRequired />;

      break;
    }
    case BAD_REQUEST_TYPES.indexOf(errorCode) > -1: {
      Content = () => <BadRequest />;

      break;
    }
    /*</OLD ERROR CODES>*/

    /*<NEW ERROR CODES>*/
    // Overdue bill
    // TODO: remove CONSUMER_BILL_STATEMENT_OVERDUE
    case errorCode === errorCodes.CONSUMER_BILL_STATEMENT_OVERDUE:
    case errorCode === errorCodecV2.OVERDUE_BILL: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.OVERDUE_BILL]}
            onPress={() => {
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                actionItem: 'Pay bill',
              });
            }}
          />
        );
      };
      break;
    }

    // Over Plus limit - cannot retry
    case errorCode === errorCodecV2.EXCEEDED_PLUS_LIMIT: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.EXCEEDED_PLUS_LIMIT]}
            onPress={() => {
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                actionItem: 'Check limit',
                extraData: {
                  'Merchant Config': merchantConfig,
                  'Store Name': shopName,
                  'Order Amount': totalAmount,
                  'Merchant Payload': merchantPayload,
                },
              });
            }}
          />
        );
      };
      break;
    }

    // Over Plus limit - can retry
    case errorCode === errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE]}
            onPress={() => {
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                actionItem: 'PayNow',
                extraData: {
                  'Merchant Config': merchantConfig,
                  'Store Name': shopName,
                  'Order Amount': totalAmount,
                  'Merchant Payload': merchantPayload,
                },
              });
            }}
          />
        );
      };
      break;
    }

    case errorCode === errorCodecV2.MERCHANT_REQUIRES_KYC: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.MERCHANT_REQUIRES_KYC]}
            onPress={() => {
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                actionItem: 'eKYC',
                extraData: {
                  'Merchant Config': merchantConfig,
                  'Store Name': shopName,
                  'Order Amount': totalAmount,
                  'Merchant Payload': merchantPayload,
                },
              });
            }}
          />
        );
      };
      break;
    }

    case errorCode === errorCodecV2.MERCHANT_REQUIRES_HOSHINO: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.MERCHANT_REQUIRES_HOSHINO]}
            onPress={() => {
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                actionItem: 'eKYC',
                extraData: {
                  'Merchant Config': merchantConfig,
                  'Store Name': shopName,
                  'Order Amount': totalAmount,
                  'Merchant Payload': merchantPayload,
                },
              });
            }}
          />
        );
      };
      break;
    }

    case errorCode === errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE]}
            onPress={() => {
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                actionItem: 'Boost',
                extraData: {
                  'Merchant Config': merchantConfig,
                  'Store Name': shopName,
                  'Order Amount': totalAmount,
                  'Merchant Payload': merchantPayload,
                },
              });
            }}
          />
        );
      };
      break;
    }

    case errorCode === errorCodecV2.EXCEEDED_NLP_LIMIT: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.EXCEEDED_NLP_LIMIT]}
            onPress={() => {
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                actionItem: 'Check limit',
                extraData: {
                  'Merchant Config': merchantConfig,
                  'Store Name': shopName,
                  'Order Amount': totalAmount,
                  'Merchant Payload': merchantPayload,
                },
              });
            }}
          />
        );
      };
      break;
    }

    /*</NEW ERROR CODES>*/
    // internal error
    case INTERNAL_ERROR_TYPES.indexOf(errorCode) > -1:
    case errorCode === errorCodecV2.GENERIC:
    default: {
      // eslint-disable-next-line react/display-name
      Content = () => {
        return (
          <ErrorContent
            {...errorScreenContent[errorCodecV2.GENERIC]}
            onPress={() => {
              MixpanelHelpers.trackAction({
                pathname,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
              });
            }}
          />
        );
      };
      break;
    }
  }

  MixpanelHelpers.trackAction({ pathname, actionName: MIXPANEL_ACTION_PAGE_VIEW });

  return (
    <Frame
      hasFooter={
        isDesktop
          ? false
          : !Object.values(errorCodecV2).includes(errorCode) ||
            errorCode === errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE ||
            errorCode === errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE
      }
      hasCornerBackButton={false}
    >
      <Content />
    </Frame>
  );
};

const mapStateToProps = state => ({
  shopName: state.merchant.shopName,
  totalAmount: state.payment.totalAmount,
});

export default withRouter(connect(mapStateToProps)(ErrorDemo));
