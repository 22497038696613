// @flow
import { isMobileOnly } from 'react-device-detect';

export const ADULT_AGE_THRESHOLD: number = 20;

export const loadingTypes: { [string]: string } = {
  BIG: 'big',
  REGULAR: 'regular',
};

export const tierMap: { [string]: string } = {
  CLASSIC: 'classic',
  DIGITAL: 'digital',
  PLUS: 'plus',
};

export const installmentTypeMap: { [string]: string } = {
  SINGLE: 'single',
  MULTI: 'multi',
  THREEPAY: 'threepay',
  TOKEN: 'token',
};

export const authTypeMap: { [string]: string } = {
  SMS: 'sms',
  VOICE: 'voice',
};

export const paymentStatusMap: { [string]: string } = {
  CREATED: 'created',
  AUTHENTICATED: 'authenticated',
  AUTHORIZED: 'authorized',
  PENDING: 'pending',
  REJECTED: 'rejected',
  CLOSED: 'closed',
  CANCELED: 'canceled',
  // for token
  ACTIVE: 'active',
  FAILED: 'failed',
};

export const requiredBigContractDataTypeMap: { [string]: string } = {
  REGULAR: 'regular',
  NONE: 'none',
};

export const plusFormTypeMap: { [string]: string } = {
  REGULAR: 'regular',
  EXTENDED: 'extended',
  NONE: 'none',
};

export const requiredConsumerDataTypeMap: { [string]: ?string } = {
  REGULAR: 'regular',
  NULL: null,
};

export const BAD_REQUEST_TYPES: Array<string> = [
  'request_content-malformed',
  'request_entity-invalid',
  'cors-malformed',
  'cors-invalid',
  'version-unknown',
];

export const INTERNAL_ERROR_TYPES: Array<string> = [
  'service-forbidden',
  'service-conflict',
  'service-exception',
  'service-unavailable',
  'service-error',
];

export const maxCSSHeight = 'calc(80vh - 136px)';

export const desktopMaxHeightStyle = isMobileOnly ? undefined : { height: maxCSSHeight };
export const desktopBigMaxHeightStyle = isMobileOnly
  ? undefined
  : { height: 790, maxHeight: maxCSSHeight };

export const PAIDY_OFFLINE_MERCHANT_ID: Array<string> = [
  'pk_live_ajnhao03gu46ke9u43oiihn18n',
  'pk_live_lomva5rhvcqfk45okslnfi2lun', // ひまわりデンタルクリニック 駒込
  'pk_live_330qbttnnhkrdm5h7i08ob80ag', // ひまわり歯科 相模原
  'pk_live_4m93rqmtjef53td9odmkcnbguk', // ラビット歯科 戸田
  'pk_live_knflbjipshdieu3i5881rlen53', // ラビット歯科 春日部
  'pk_live_nj8n8k29sl1k2qoa5lkkiqdnft', // ラビット歯科 松戸
  'pk_live_7kg84rrfu4qgv2irr0mt2cf99k', // ラビット歯科 仙台
  'pk_test_jgvpco4bptgghfc5asmo6ktamv', // Paidy Offline Test
  'pk_live_k1hda676p34mtqf71dqapk1h6k', // Paidy Offline Usability Test
];

export const HIGH_TICKET_MERCHANT_API_KEYS: { [string]: string } = {
  DIOR_PROD: 'pk_live_4pa3t15ojne92ufvo7k01dguvd',
  DIOR_STG: 'pk_live_7eulk12v5knlsfv1fra9okrtr9',
  TAG_HEUER_PROD: 'pk_live_hjj9ji68nfn9aq7afkv5em8t0r',
  TAG_HEUER_STG: 'pk_live_gsiaointue3tbtunb3k3op4lmd',
}

export const SELECTION_OPTION_PHASE: { [string]: string } = {
  PRESELECT: 'PRESELECT',
  RESELECT: 'RESELECT',
  UNAVAILABLE: 'UNAVAILABLE',
};

export default {
  ADULT_AGE_THRESHOLD,
  loadingTypes,
  tierMap,
  installmentTypeMap,
  authTypeMap,
  paymentStatusMap,
  requiredBigContractDataTypeMap,
  plusFormTypeMap,
  requiredConsumerDataTypeMap,
  BAD_REQUEST_TYPES,
  INTERNAL_ERROR_TYPES,
  maxCSSHeight,
  desktopMaxHeightStyle,
  PAIDY_OFFLINE_MERCHANT_ID,
  HIGH_TICKET_MERCHANT_API_KEYS,
  SELECTION_OPTION_PHASE
};

export const COMBINI_FEE = 390;
