import React from 'react';
import QRCode from 'react-qr-code';

import { emailReminderCodes, errorCodecV2, errorCodes } from '../../constants/error-codes';
import { onClose } from '../../utils';
import styles from './Error.scss';
import { MixpanelHelpers, MIXPANEL_ACTION_LINK_CLICK } from '../../utils/mixpanel';

const errorScreenContent = {
  [errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE]: {
    body: [
      <b key="title" className={styles.title}>
        大変申し訳ございませんが、ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={styles.description}>
        ペイディのご利用可能枠を超過しているため、決済を承認することができませんでした。
      </p>,
      <p
        key="desc-line2"
        className={`${styles['description-desktop']} ${styles['bottom-space-16']}`}
      >
        お手数ですが、下記のボタンをクリックして、店舗が提供する他の決済方法を選択してください。
      </p>,
      <div key="close-btn" className={styles['controls-space']}>
        <button
          className={`${styles.button} ${styles['button-space']}`}
          onClick={() => {
            MixpanelHelpers.trackAction({
              pathname: `/error/${errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE}`,
              actionName: MIXPANEL_ACTION_LINK_CLICK,
              extraData: {
                Type: 'Other payment method',
              },
            });
            onClose(false);
          }}
          data-testid="btn-close"
        >
          他の決済方法を選ぶ
        </button>
      </div>,
      <p key="desc-line3" className={styles['description-last']}>
        {[
          'なお、ペイディアプリから「すぐ払い」機能を利用して、コンビニでご請求金額をお支払いいただくことで、ご利用いただけるようになる場合がございます。詳細なご利用方法は、',
          <a
            key="こちら"
            target="_blank"
            rel="noopener noreferrer"
            href="https://cs-support.paidy.com/support/solutions/articles/150000040753"
            onClick={() => {
              MixpanelHelpers.trackAction({
                pathname: `/error/${errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE}`,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                extraData: {
                  Type: 'FAQ',
                },
              });
            }}
          >
            こちら
          </a>,
          'をご確認ください。',
        ]}
      </p>,
      <div key="download" className={styles['download-container']}>
        <img
          src={require('../../assets/over-limit-pay-now-eligible-desktop.png')}
          className={styles['download-image']}
        />
        <div className={styles['download-qr-container']}>
          <div className={styles['qr-container']}>
            <QRCode value={process.env.ONE_LINK_OVER_PLUS_LIMIT_CAN_RETRY} size={72} />
          </div>
          <p className={styles['qr-title']}>
            QRコードを読み取って
            <br />
            アプリで確認する
          </p>
          <p className={styles['qr-description']}>
            ※エラーが表示される場合は下記からご利用ください
          </p>
          <div className={styles['store-container']}>
            <a
              href="https://apps.apple.com/jp/app/paidy/id1220373112"
              className={styles['app-store-download']}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('../../assets/app-store-download.svg')} width={66} height={24} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.paidy.paidy&amp;hl=ja"
              className={styles['google-play-download']}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/google-play-download.svg')}
                width={81}
                height={24}
                className={styles['google-play-download']}
              />
            </a>
          </div>
        </div>
      </div>,
    ],
    hasFooter: false,
    hideShopInfo: true,
  },
  [errorCodes.CONSUMER_BILL_STATEMENT_OVERDUE]: {
    buttonText: '今すぐ支払う',
    buttonHref: process.env.ONE_LINK_OVERDUE_BILL,
    onClick: () => {
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.OVERDUE_BILL}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Pay bill',
        },
      });
    },
    hasFooter: true,
    footerText: '他の決済方法を選ぶ',
    footerOnClick: () => {
      onClose(false);
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.OVERDUE_BILL}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
    },
    hideShopInfo: true,
  },
  [errorCodecV2.EXCEEDED_PLUS_LIMIT]: {
    body: [
      <div key="img-container" className={styles['sorry-container']}>
        <img
          key="img"
          src={require('../../assets/sorry.svg')}
          alt="sorry"
          width={184}
          height={184}
        />
      </div>,
      <b key="title" className={styles.title}>
        大変申し訳ございませんが、ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={styles['description-desktop']}>
        ペイディのご利用可能枠を超過しているため、決済を承認することができませんでした。
      </p>,
      <br key={'br'} />,
      <p
        key="desc-line2"
        className={`${styles['description-desktop']} ${styles['description-last']}`}
      >
        なお、ご利用可能枠は、ペイディアプリよりご確認いただけます。
      </p>,
      <div key="download" className={styles['download-container']}>
        <div
          className={`${styles['download-qr-container']} ${styles['download-qr-container-no-image']}`}
        >
          <div className={styles['qr-container']}>
            <QRCode value={process.env.ONE_LINK_OVER_PLUS_LIMIT_CANNOT_RETRY} size={72} />
          </div>
          <p className={styles['qr-title']}>
            QRコードを読み取って
            <br />
            アプリで確認する
          </p>
          <p className={styles['qr-description']}>
            ※エラーが表示される場合は下記からご利用ください
          </p>
          <div className={styles['store-container']}>
            <a
              href="https://apps.apple.com/jp/app/paidy/id1220373112"
              className={styles['app-store-download']}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('../../assets/app-store-download.svg')} width={66} height={24} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.paidy.paidy&amp;hl=ja"
              className={styles['google-play-download']}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/google-play-download.svg')}
                width={81}
                height={24}
                className={styles['google-play-download']}
              />
            </a>
          </div>
        </div>
      </div>,
    ],
    onClick: () => {
      onClose(false);
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.EXCEEDED_PLUS_LIMIT}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
    },
    buttonText: '他の決済方法を選ぶ',
    hideShopInfo: true,
    hasFooter: false,
  },
  [errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE]: {
    body: [
      <b key="title" className={styles.title}>
        大変申し訳ございませんが、ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={styles['description-desktop']}>
        ペイディの今月のご利用可能枠を超過しているため、決済を承認することができませんでした。
      </p>,
      <br key={'br'} />,
      <p
        key="desc-line2"
        className={`${styles['description-desktop']} ${styles['description-desktop-bottom-24']}`}
      >
        なお、ペイディアプリから下記の機能を利用することで、ご利用いただけるようになる場合がございます。
      </p>,
      <div className={styles['bulleted-item']} key="npay">
        <img src={require('../../assets/bulleted-dark.svg')} alt="bulleted icon" />
        <span>今月のご利用分を3・6・12回あと払いに変更する</span>
      </div>,
      <div className={styles['bulleted-item']} key="paynow">
        <img src={require('../../assets/bulleted-dark.svg')} alt="bulleted icon" />
        <span>すぐ払い機能でご請求金額を支払う</span>
      </div>,
      <p
        key="description"
        className={`${styles['description-desktop-retriable']} ${styles['space-vertical-24']}`}
      >
        {[
          '詳細なご利用方法は、',
          <a
            key="こちら"
            target="_blank"
            rel="noopener noreferrer"
            href="https://cs-support.paidy.com/support/solutions/articles/150000040638-3%E3%83%BB6%E3%83%BB12%E5%9B%9E%E3%81%82%E3%81%A8%E6%89%95%E3%81%84%E3%81%AE%E5%88%A9%E7%94%A8%E6%96%B9%E6%B3%95%E3%81%8C%E7%9F%A5%E3%82%8A%E3%81%9F%E3%81%84"
            onClick={() => {
              MixpanelHelpers.trackAction({
                pathname: `/error/${errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE}`,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                extraData: {
                  Type: 'FAQ',
                },
              });
            }}
          >
            こちら
          </a>,
          'をご確認ください。',
        ]}
      </p>,
      <div key="download" className={styles['download-container']}>
        <img
          src={require('../../assets/over-limit-pay-now-eligible.png')}
          className={styles['download-image']}
        />
        <div className={styles['download-qr-container']}>
          <div className={styles['qr-container']}>
            <QRCode value={process.env.ONE_LINK_CHECKOUT_EXCEEDED_NLP_LIMIT_RETRIABLE} size={72} />
          </div>
          <p className={styles['qr-title']}>
            QRコードを読み取って
            <br />
            アプリで確認する
          </p>
          <p className={styles['qr-description']}>
            ※エラーが表示される場合は下記からご利用ください
          </p>
          <div className={styles['store-container']}>
            <a
              href="https://apps.apple.com/jp/app/paidy/id1220373112"
              className={styles['app-store-download']}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('../../assets/app-store-download.svg')} width={66} height={24} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.paidy.paidy&amp;hl=ja"
              className={styles['google-play-download']}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/google-play-download.svg')}
                width={81}
                height={24}
                className={styles['google-play-download']}
              />
            </a>
          </div>
        </div>
      </div>,
    ],
    qrCodeContent: process.env.ONE_LINK_CHECKOUT_EXCEEDED_NLP_LIMIT_RETRIABLE,
    downloadImage: require('../../assets/over-limit-pay-now-eligible.png'),
    buttonText: '他の決済方法を選ぶ',
    onClick: () => {
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
      onClose(false);
    },
    hideShopInfo: true,
    hasFooter: false,
  },
  [errorCodecV2.EXCEEDED_NLP_LIMIT]: {
    body: [
      <div key="img-container" className={styles['sorry-container']}>
        <img
          key="img"
          src={require('../../assets/sorry.svg')}
          alt="sorry"
          width={184}
          height={184}
        />
      </div>,
      <b key="title" className={styles.title}>
        大変申し訳ございませんが、ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={styles['description-desktop']}>
        ペイディのご利用可能枠を超過しているため、決済を承認することができませんでした。
      </p>,
      <br key={'br'} />,
      <p key="desc-line2" className={styles['description-desktop']}>
        なお、ご利用可能枠は、ペイディアプリよりご確認いただけます。
      </p>,
      <div key="download" className={styles['download-container']}>
        <div
          className={`${styles['download-qr-container']} ${styles['download-qr-container-no-image']}`}
        >
          <div className={styles['qr-container']}>
            <QRCode value={process.env.ONE_LINK_CHECKOUT_EXCEEDED_NLP_LIMIT} size={72} />
          </div>
          <p className={styles['qr-title']}>
            QRコードを読み取って
            <br />
            アプリで確認する
          </p>
          <p className={styles['qr-description']}>
            ※エラーが表示される場合は下記からご利用ください
          </p>
          <div className={styles['store-container']}>
            <a
              href="https://apps.apple.com/jp/app/paidy/id1220373112"
              className={styles['app-store-download']}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('../../assets/app-store-download.svg')} width={66} height={24} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.paidy.paidy&amp;hl=ja"
              className={styles['google-play-download']}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/google-play-download.svg')}
                width={81}
                height={24}
                className={styles['google-play-download']}
              />
            </a>
          </div>
        </div>
      </div>,
    ],
    onClick: () => {
      onClose(false);
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.EXCEEDED_NLP_LIMIT}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
    },
    buttonText: '他の決済方法を選ぶ',
    hideShopInfo: true,
    hasFooter: false,
  },
  [errorCodecV2.OVERDUE_BILL]: {
    body: [
      <b key="title" className={styles.title}>
        ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={styles['description']}>
        お支払い待ちのご請求があるため、ペイディの決済を承認することができませんでした。
      </p>,
      <br key={'br'} />,
      <p key="desc-line2" className={`${styles.description} ${styles['description-last']}`}>
        下記のボタンからMyPaidyに進み、案内に従ってお支払い手続きを行ってください。
      </p>,
    ],
    buttonText: '今すぐ支払う',
    buttonHref: process.env.ONE_LINK_OVERDUE_BILL,
    onClick: () => {
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.OVERDUE_BILL}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Pay bill',
        },
      });
    },
    hasFooter: true,
    footerText: '他の決済方法を選ぶ',
    footerOnClick: () => {
      onClose(false);
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.OVERDUE_BILL}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
    },
    hideShopInfo: true,
  },
  [errorCodecV2.MERCHANT_REQUIRES_HOSHINO]: {
    body: [
      <b key="title" className={styles.title}>
        大変申し訳ございませんが、ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={styles['description-desktop']}>
        ご利用の加盟店でペイディを利用するには、本人確認が必要です。お客様は本人確認が完了していないため、決済を承認することができませんでした。
      </p>,
      <br key="br1" />,
      <p key="desc-line2" className={styles['description-desktop']}>
        本人確認後はお客様のご利用可能枠が確認可能になり、その範囲内でぺイディをご利用いただけます。
      </p>,
      <br key="br2" />,
      <p key="desc-line3" className={`${styles.description} ${styles['description-last']}`}>
        大変お手数をおかけいたしますが、ペイディアプリから本人確認後、もう一度お試しいただけますと幸いです。
      </p>,
      <div
        key="download"
        className={`${styles['download-container']} ${styles['download-container-remove-bottom-space']}`}
      >
        <img src={require('../../assets/hoshino-kyc.png')} className={styles['download-image']} />
        <div className={styles['download-qr-container']}>
          <div className={styles['qr-container']}>
            <QRCode value={process.env.ONE_LINK_HOSHINO} size={72} />
          </div>
          <p className={styles['qr-title']}>
            QRコードを読み取って
            <br />
            アプリで確認する
          </p>
          <p className={styles['qr-description']}>
            ※エラーが表示される場合は下記からご利用ください
          </p>
          <div className={styles['store-container']}>
            <a
              href="https://apps.apple.com/jp/app/paidy/id1220373112"
              className={styles['app-store-download']}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('../../assets/app-store-download.svg')} width={66} height={24} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.paidy.paidy&amp;hl=ja"
              className={styles['google-play-download']}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/google-play-download.svg')}
                width={81}
                height={24}
                className={styles['google-play-download']}
              />
            </a>
          </div>
        </div>
      </div>,
    ],
    onClick: () => {
      onClose(false);
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.MERCHANT_REQUIRES_HOSHINO}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
    },
    buttonText: '他の決済方法を選ぶ',
    hasFooter: false,
    hideShopInfo: true,
  },

  [errorCodecV2.MERCHANT_REQUIRES_KYC]: {
    body: [
      <b key="title" className={styles.title}>
        大変申し訳ございませんが、ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={styles['description-desktop']}>
        ご利用の加盟店でペイディを利用するには、本人確認が必要です。お客様は本人確認が完了していないため、決済を承認することができませんでした。
      </p>,
      <br key="br1" />,
      <p key="desc-line2" className={styles['description-desktop']}>
        本人確認後はお客様のご利用可能枠が確認可能になり、その範囲内でペイディをご利用いただけます。
      </p>,
      <br key="br2" />,
      <p key="desc-line3" className={`${styles.description} ${styles['description-last']}`}>
        大変お手数をおかけいたしますが、ペイディアプリから本人確認後、もう一度お試しいただけますと幸いです。
      </p>,
      <div
        key="download"
        className={`${styles['download-container']} ${styles['download-container-remove-bottom-space']}`}
      >
        <img src={require('../../assets/hoshino-kyc.png')} className={styles['download-image']} />
        <div className={styles['download-qr-container']}>
          <div className={styles['qr-container']}>
            <QRCode value={process.env.ONE_LINK_MERCHANT_REQUIRES_KYC} size={72} />
          </div>
          <p className={styles['qr-title']}>
            QRコードを読み取って
            <br />
            アプリで確認する
          </p>
          <p className={styles['qr-description']}>
            ※エラーが表示される場合は下記からご利用ください
          </p>
          <div className={styles['store-container']}>
            <a
              href="https://apps.apple.com/jp/app/paidy/id1220373112"
              className={styles['app-store-download']}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('../../assets/app-store-download.svg')} width={66} height={24} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.paidy.paidy&amp;hl=ja"
              className={styles['google-play-download']}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../assets/google-play-download.svg')}
                width={81}
                height={24}
                className={styles['google-play-download']}
              />
            </a>
          </div>
        </div>
      </div>,
    ],
    onClick: () => {
      onClose(false);
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.MERCHANT_REQUIRES_KYC}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
    },
    buttonText: '他の決済方法を選ぶ',
    hasFooter: false,
    hideShopInfo: true,
  },
  [errorCodecV2.GENERIC]: {
    body: [
      <div key="img-container" className={styles['sorry-container']}>
        <img
          key="img"
          src={require('../../assets/sorry.svg')}
          alt="sorry"
          width={184}
          height={184}
        />
      </div>,
      <b key="title" className={styles.title}>
        大変申し訳ございませんが、ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={styles['description-desktop']}>
        お手数ですが、店舗が提供する他の決済方法を選択してください。
      </p>,
      <br key={'br'} />,
      <p key="desc-line2" className={`${styles['description-desktop']}`}>
        ペイディでは、すべてのお客様に安心・安全にご利用いただける健全なサービスの運営を目指しており、その取り組みのひとつとして、ご注文ごとにご利用内容の確認を行っております。そのため、店舗や商品などのご注文内容によって、決済が承認されない場合があります。
      </p>,
      <br key={'br1'} />,
      <p
        key="desc-line3"
        className={`${styles['description-desktop']} ${styles['description-last']}`}
      >
        なお、詳細な審査基準に関しましてはお答えすることができません。ご迷惑をおかけして誠に申し訳ございませんが、何卒ご理解いただけますと幸いです。
      </p>,
    ],
    onClick: () => {
      onClose(false);
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.GENERIC}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
    },
    buttonText: '他の決済方法を選ぶ',
    hasFooter: false,
    hideShopInfo: true,
  },
  [errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE_X1]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      'ペイディのご利用可能額を超過しています。',
      [
        `ペイディアプリから`,
        <a
          key="すぐ払い"
          href={process.env.ONE_LINK_CHECKOUT_OVER_NLP_LIMIT_CAN_RETRY_1X}
          target="_blank"
          rel="noopener noreferrer"
        >
          すぐ払い
        </a>,
        `機能でご請求金額をお支払いいただくと、ご利用いただけるようになる場合があります。`,
      ],
    ],
    noteText: [
      '※すぐ払いはコンビニ払いのみご利用可能です。',
      `※毎月のご請求のお支払い方法を「口座振替」に設定している場合、をご利用いただけません。お支払方法を「コンビニ払い・銀行振込」に変更後、コンビニですぐ払いをご利用ください。`,
    ],
    qrCodeContent: process.env.ONE_LINK_CHECKOUT_OVER_NLP_LIMIT_CAN_RETRY_1X,
    downloadImage: require('../../assets/over-limit-pay-now-eligible.png'),
    hideShopInfo: false,
    hasFooter: false,
  },
  [errorCodecV2.EXCEEDED_NLP_LIMIT_X1]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      'ペイディの今月のご利用可能枠を超過しています。',
      '今月のご利用可能枠は、ペイディアプリでご確認いただけます。',
    ],
    downloadImage: require('../../assets/over-limit-pay-now-not-eligible.png'),
    qrCodeContent: process.env.ONE_LINK_CHECKOUT_OVER_NLP_LIMIT_CANNOT_RETRY_1X,
    hideShopInfo: false,
    hasFooter: false,
  },
  [errorCodecV2.HOUKATSU_REJECTED]: {
    body: [
      <div key="img-container" className={styles['sorry-container']}>
        <img
          key="img"
          src={require('../../assets/sorry.svg')}
          alt="sorry"
          width={184}
          height={184}
        />
      </div>,
      <b key="title" className={`${styles.title} ${styles['text-align-center']}`}>
        本人確認の結果について
      </b>,
      <p key="desc-line1" className={styles['description-desktop']}>
       ペイディでは、すべてのお客様に安心・安全にご利用いただける健全なサービスの運営を目指しており、その取り組みのひとつとして、審査を行っております。今回、お客様にお手続きいただいた内容をもとに検討を行いましたが、誠に残念ながら、今回のご利用は見送りとさせていただくこととなりました。お客様のご期待にそえず、誠に申し訳ございません。
      </p>,
      <br key={'br'} />,
      <p key="desc-line2" className={styles['description-desktop']}>
        お手数ですが、店舗が提供する他の決済方法をご利用ください。
      </p>,
    ],
    onClick: () => onClose(false),
    buttonText: '他の決済方法を選ぶ',
    hideShopInfo: true,
    hasFooter: false,
  },
  [errorCodecV2.PLUS_UPGRADE_KYC_REJECTED]: {
    body: [
      <div key="img-container" className={styles['sorry-container']}>
        <img
          key="img"
          src={require('../../assets/retry.svg')}
          alt="retry"
          width={184}
          height={184}
        />
      </div>,
      <b data-testid="error-screen-title" key="title" className={`${styles.title} ${styles['text-align-center']}`}>
        再度本人確認の手続きを<br key={'br'} />
        お願いします
      </b>,
      <p key="desc-line1" className={styles['description-last']}>
        本人確認の手続きを行っていただき、誠にありがとうございます。大変申し訳ございませんが、下記のような理由により、本人確認ができませんでした。
      </p>,
      <ul className={styles.ul}>
        <li>
          身分証明書の情報が入力情報と一致しない
        </li>
        <li>
          身分証明書の厚みが不自然
        </li>
        <li>
          身分証明証の情報が読み取れない など
        </li>
      </ul>,
      <p key="desc-line2" className={`${styles.description} ${styles['description-last']}`}>
        お手数ではございますが、再度お試しください。
      </p>,
    ],
    onClick: () => onClose(false),
    buttonText: '再度試す',
    hasFooter: false,
    hideShopInfo: true,
  },
  [errorCodecV2.PLUS_UPGRADE_TIMEOUT]: {
    body: [
      <div key="img-container" className={styles['sorry-container']}>
        <img
          key="img"
          src={require('../../assets/clock-timer@3x.png')}
          alt="clock-timer"
          width={184}
          height={184}
        />
      </div>,
      <b data-testid="error-screen-title" key="title" className={`${styles.title} ${styles['text-align-center']}`}>
       セッションの有効期限が切れました
      </b>,
      <p key="desc-line1" className={`${styles['description-desktop']} ${styles['text-align-center']}`}>
        お手数おかけしますが、<br />
        もう一度はじめからお試しください。
      </p>,
    ],
    onClick: () => onClose(false),
    buttonText: 'もう一度はじめから試す',
    hasFooter: false,
    hideShopInfo: true,
  },
  [errorCodecV2.PLUS_UPGRADE_OUTSIDE_CIC_HOURS]: {
    body: [
      <div key="img-container" className={styles['sorry-container']}>
        <img
          key="img"
          src={require('../../assets/clock-timer@3x.png')}
          alt="clock-timer"
          width={184}
          height={184}
        />
      </div>,
      <b key="title" className={`${styles.title} ${styles['text-align-center']}`}>
        ペイディの決済が完了できません
      </b>,
      <p key="desc-line1" className={styles['description-desktop']}>
       大変申し訳ございませんが、翌朝8:00までペイディの本人確認の結果の確認ができないため、ペイディの決済が完了できません
      </p>,
      <br key={'br'} />,
      <p key="desc-line2" className={styles['description-desktop']}>
        翌朝8:00以降に本人確認の結果を確認後、改めてペイディでの購入をお試しいただくか、他の決済方法を選択してください。
      </p>,
    ],
    onClick: () => onClose(false),
    buttonText: '他の決済方法を選ぶ',
    hideShopInfo: true,
    hasFooter: false,
  },
  [emailReminderCodes.PHONE_24H_ATTEMP]: {
    title: '時間をおいてから再度お試しください',
    description: [
      'メールアドレスの確認は、一度行うと24時間以内は行えません。時間をおいてから再度お試しください。',
    ],
    buttonText: '戻る',
    hasFooter: true,
    btnGoBack: true,
    buttonWrapperClass: 'controls-wrapper',
  },
  [emailReminderCodes.OTP_MAX_RETRIES]: {
    title: '正しい認証コードを確認できませんでした',
    description: [
      'ご入力いただいた認証コードに誤りがあるか、一定の時間が経過しています。',
      'お手数ですが、ペイディの画面を閉じて、もう一度お試しください。',
    ],
    buttonText: '戻る',
    btnGoToPath: '/login',
    buttonWrapperClass: 'controls-wrapper',
    hasFooter: true,
    btnGoBack: true,
  },
  [emailReminderCodes.OTP_MORE_THEN_3_ATTEMPS]: {
    title: '認証コードエラー',
    description: ['お手数ですが、再度お手続きをお願いいたします。'],
    buttonText: '戻る',
    hasFooter: true,
    buttonWrapperClass: 'controls-wrapper',
    btnGoToPath: '/login',
  },
  [emailReminderCodes.OTP_UNAVAILABLE]: {
    title: 'エラーが発生しました',
    description: ['しばらく時間をおいてからもう一度お試しください。'],
    buttonText: '戻る',
    hasFooter: true,
    buttonWrapperClass: 'controls-wrapper',
    btnGoBack: true,
  },
  [emailReminderCodes.OTP_UNAUTHORIZED]: {
    title: '認証コードエラー',
    description: ['お手数ですが、再度お手続きをお願いいたします'],
    buttonText: '戻る',
    hasFooter: true,
    buttonWrapperClass: 'controls-wrapper',
    btnGoBack: true,
  },
  [errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE_X1]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      'ペイディのご利用可能額を超過しています。',
      [
        `ペイディアプリから`,
        <a
          key="すぐ払い"
          href={process.env.ONE_LINK_CHECKOUT_OVER_NLP_LIMIT_CAN_RETRY_1X}
          target="_blank"
          rel="noopener noreferrer"
        >
          すぐ払い
        </a>,
        `機能でご請求金額をお支払いいただくと、ご利用いただけるようになる場合があります。`,
      ],
    ],
    noteText: [
      '※すぐ払いはコンビニ払いのみご利用可能です。',
      `※毎月のご請求のお支払い方法を「口座振替」に設定している場合、をご利用いただけません。お支払方法を「コンビニ払い・銀行振込」に変更後、コンビニですぐ払いをご利用ください。`,
    ],
    qrCodeContent: process.env.ONE_LINK_CHECKOUT_OVER_NLP_LIMIT_CAN_RETRY_1X,
    downloadImage: require('../../assets/over-limit-pay-now-eligible.png'),
  },
  [errorCodecV2.EXCEEDED_NLP_LIMIT_X1]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      'ペイディの今月のご利用可能枠を超過しています。',
      '今月のご利用可能枠は、ペイディアプリでご確認いただけます。',
    ],
    downloadImage: require('../../assets/over-limit-pay-now-not-eligible.png'),
    qrCodeContent: process.env.ONE_LINK_CHECKOUT_OVER_NLP_LIMIT_CANNOT_RETRY_1X,
  },
};

export default errorScreenContent;
