// @flow
import React from 'react';

import { onClose } from '../../utils';
import styles from './Error.scss';

const HoukatsuPreviouslyRejected = () => (
  <>
    <div className={styles['scrollable-content']}>
      <b className={styles.title}>ペイディの決済が承認されませんでした</b>
      <p>お手数ですが、店舗が提供する他の決済方法を選択してください。</p>
      <p>なお、詳細な審査基準に関しましてはお答えすることができません。ご迷惑をおかけして誠に申し訳ございませんが、何卒ご理解いただけますと幸いです。</p>
    </div>
    <div className={styles.controls}>
      <button className={styles.button} onClick={() => onClose()} data-testid="btn-close">
        戻る
      </button>
    </div>
  </>
);

export default HoukatsuPreviouslyRejected;
