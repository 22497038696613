import React from 'react';

export const CheckedInCircleIcon = () => {
  return (
    <svg
      width="123"
      height="122"
      viewBox="0 0 123 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M122.5 61C122.5 94.6894 95.1894 122 61.5 122C27.8106 122 0.5 94.6894 0.5 61C0.5 27.3106 27.8106 0 61.5 0C95.1894 0 122.5 27.3106 122.5 61Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.5 120.5C94.3609 120.5 121 93.8609 121 61C121 28.1391 94.3609 1.5 61.5 1.5C28.6391 1.5 2 28.1391 2 61C2 93.8609 28.6391 120.5 61.5 120.5ZM61.5 122C95.1894 122 122.5 94.6894 122.5 61C122.5 27.3106 95.1894 0 61.5 0C27.8106 0 0.5 27.3106 0.5 61C0.5 94.6894 27.8106 122 61.5 122Z"
        fill="#1C1C1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.2487 39.1748C89.0726 39.9105 89.1442 41.1748 88.4085 41.9987L52.099 82.6654C51.6943 83.1187 51.1054 83.3634 50.4986 83.3304C49.8918 83.2974 49.3329 82.9903 48.9797 82.4958L34.4559 62.1625C33.8138 61.2636 34.022 60.0146 34.9209 59.3725C35.8197 58.7305 37.0688 58.9387 37.7108 59.8375L50.7809 78.1357L85.4248 39.3346C86.1604 38.5107 87.4248 38.4391 88.2487 39.1748Z"
        fill="#E30E80"
      />
    </svg>
  );
};

export const EmailIcon = () => {
  return (
    <svg width="40" height="56" viewBox="0 0 40 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 23C1 20.7909 2.79086 19 5 19H19C21.2091 19 23 20.7909 23 23V33C23 35.2091 21.2091 37 19 37H5C2.79086 37 1 35.2091 1 33V23ZM5 20.5H19C20.1954 20.5 21.1949 21.3391 21.4416 22.4604L12 28.1254L2.55839 22.4604C2.80509 21.3391 3.80456 20.5 5 20.5ZM2.5 24.1746V33C2.5 34.3807 3.61929 35.5 5 35.5H19C20.3807 35.5 21.5 34.3807 21.5 33V24.1746L12.3859 29.6431C12.1484 29.7856 11.8516 29.7856 11.6141 29.6431L2.5 24.1746Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export const PhoneIcon = () => {
  return (
    <svg width="40" height="56" viewBox="0 0 40 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.25 20C10.25 19.5858 10.5858 19.25 11 19.25L13 19.25C13.4142 19.25 13.75 19.5858 13.75 20C13.75 20.4142 13.4142 20.75 13 20.75H11C10.5858 20.75 10.25 20.4142 10.25 20Z"
        fill="#1C1C1C"
      />
      <path
        d="M10 36.25C9.58579 36.25 9.25 36.5858 9.25 37C9.25 37.4142 9.58579 37.75 10 37.75H14C14.4142 37.75 14.75 37.4142 14.75 37C14.75 36.5858 14.4142 36.25 14 36.25H10Z"
        fill="#1C1C1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 19C19 17.3431 17.6569 16 16 16H8C6.34315 16 5 17.3431 5 19V37C5 38.6569 6.34315 40 8 40H16C17.6569 40 19 38.6569 19 37V19ZM17.5 37V19C17.5 18.1716 16.8284 17.5 16 17.5L8 17.5C7.17157 17.5 6.5 18.1716 6.5 19L6.5 37C6.5 37.8284 7.17157 38.5 8 38.5H16C16.8284 38.5 17.5 37.8284 17.5 37Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export const QuestionIcon = () => {
  return (
    <svg width="40" height="56" viewBox="0 0 40 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5156 29.7583C12.5156 30.2812 12.3164 30.6382 11.8018 30.6382C11.2871 30.6382 11.063 30.2563 11.063 29.7832V29.5508C11.063 28.4302 11.5859 27.6914 12.7231 27.0107C13.686 26.4214 14.0596 25.8818 14.0596 25.0352C14.0596 23.9561 13.2461 23.2256 12.0259 23.2256C10.9219 23.2256 10.1914 23.7817 9.91748 24.7778C9.77637 25.2842 9.59375 25.4419 9.22021 25.4419C8.79688 25.4419 8.56445 25.1929 8.56445 24.7612C8.56445 24.4043 8.63086 24.1221 8.78027 23.7983C9.27002 22.6196 10.4902 21.9307 12.084 21.9307C14.1426 21.9307 15.6035 23.1841 15.6035 24.9604C15.6035 26.2637 14.9312 27.1602 13.8687 27.7993C12.8145 28.4219 12.5156 28.8867 12.5156 29.7583Z"
        fill="#1C1C1C"
      />
      <path
        d="M12.7397 33.1367C12.7397 33.668 12.333 34.083 11.7935 34.083C11.2622 34.083 10.8472 33.668 10.8472 33.1367C10.8472 32.5972 11.2622 32.1904 11.7935 32.1904C12.333 32.1904 12.7397 32.5972 12.7397 33.1367Z"
        fill="#1C1C1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 40C18.6274 40 24 34.6274 24 28C24 21.3726 18.6274 16 12 16C5.37258 16 0 21.3726 0 28C0 34.6274 5.37258 40 12 40ZM22.5 28C22.5 33.799 17.799 38.5 12 38.5C6.20101 38.5 1.5 33.799 1.5 28C1.5 22.201 6.20101 17.5 12 17.5C17.799 17.5 22.5 22.201 22.5 28Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};