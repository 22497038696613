// @flow
/* eslint-disable camelcase, jsx-a11y/label-has-for */
import React, { Fragment } from 'react';
import { isIOS } from 'react-device-detect';

import InputBox from './InputBox';
import { placeholders } from '../../constants/form';

const YearsOptions = () => {
  const thisYear = new Date().getFullYear();
  const forHowManyYears = 100;

  return [...Array(forHowManyYears).keys()].map(y => (
    <option key={y} value={+thisYear - y}>
      {+thisYear - y}
    </option>
  ));
};

type Props = {
  errors: Object,
  value: string,
  onChange: Function,
  onBlur: Function,
  onValidate: Function,
  dobYear: string,
  dobMonth: string,
  dobDay: string,
};

function getNumberOfDays(date) {
  const [year, month] = date.split('-').map(el => +el);
  let numberOfDays = 0;

  if (
    month === 1 ||
    month === 3 ||
    month === 5 ||
    month === 7 ||
    month === 8 ||
    month === 10 ||
    month === 12
  ) {
    numberOfDays = 31;
  } else if (month === 4 || month === 6 || month === 9 || month === 11) {
    numberOfDays = 30;
  } else {
    // If month is February, calculate whether it is a leap year or not
    const isLeap = new Date(year, 1, 29).getMonth() === 1;

    numberOfDays = isLeap ? 29 : 28;
  }

  return numberOfDays;
}

// eslint-disable-next-line no-empty-pattern
const DOBInput = ({
  errors,
  value,
  onChange,
  onBlur,
  onValidate,
  dobYear,
  dobMonth,
  dobDay,
}: Props) => {
  if (isIOS) {
    return (
      <InputBox
        label="生年月日"
        size="full"
        type="date"
        errors={errors}
        name="date_of_birth"
        placeholder={placeholders.DATE_OF_BIRTH}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onValidate={onValidate}
      />
    );
  }

  return (
    <Fragment>
      <div>
        <div className="one-third">
          <label htmlFor="ip_dob_year">生年月日</label>
          <div className="select-box">
            <select
              id="ip_dob_year"
              name="dob_year"
              value={dobYear}
              onChange={onChange}
              onBlur={onBlur}
            >
              <option value="">年</option>
              <YearsOptions />
            </select>
          </div>
        </div>
        <div className="one-third">
          <label htmlFor="ip_dob_month">&nbsp;</label>
          <div className="select-box">
            <select
              id="ip_dob_month"
              name="dob_month"
              value={dobMonth}
              onChange={onChange}
              onBlur={onBlur}
            >
              <option value="">月</option>
              {[...Array(12).keys()].map(month => (
                <option key={month + 1} value={month + 1}>
                  {month + 1}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="one-third">
          <label htmlFor="ip_dob_day">&nbsp;</label>
          <div className="select-box">
            <select
              id="ip_dob_day"
              name="dob_day"
              value={dobDay}
              onChange={onChange}
              onBlur={onBlur}
            >
              <option value="">日</option>
              {[...Array(getNumberOfDays(value)).keys()].map(day => (
                <option key={day + 1} value={day + 1}>
                  {day + 1}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {errors.date_of_birth && <span className="input-error">{errors.date_of_birth}</span>}
    </Fragment>
  );
};

export default DOBInput;
