import { useCallback, useEffect, useState } from 'react';

const GOOGLE_COM = 'google.com';
const RECAPTCHA_NET = 'recaptcha.net';
const URL_TEMPLATE = 'https://www.BASE/recaptcha/enterprise.js?render=KEY';

// Enable ReCaptcha on the Web
const useRecaptcha = reCaptchaKey => {
  const [error, setError] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const recaptchaFailsToLoad = () => setError(true);

  useEffect(() => {
    let script;
    let loadScriptRecaptchaListener;
    let recaptchaFailsToLoadListener;
    let hideBadgeListener;

    const loadScript = baseUrl => {
      const js = document.createElement('script');

      if (baseUrl === GOOGLE_COM) {
        // If for some reason google.com is not reachable, use recaptcha.net to load the script
        loadScriptRecaptchaListener = js.addEventListener('error', () => loadScript(RECAPTCHA_NET));
      }

      if (baseUrl === RECAPTCHA_NET) {
        // Happens only when google.com fails to load.
        // Remove the script element that contains the google.com domain
        // So that only the recaptcha.net script remains
        document.body.removeChild(script);

        // If recaptcha fails to load from recaptcha.net as well, then report the error.
        // Nothing can be done at this point.
        recaptchaFailsToLoadListener = js.addEventListener('error', recaptchaFailsToLoad);
      }

      js.src = URL_TEMPLATE.replace('BASE', baseUrl).replace('KEY', reCaptchaKey);

      js.async = true;
      hideBadgeListener = js.addEventListener('load', hideBadge);

      document.body.appendChild(js);
      script = js;
    };

    // We want to hide the ReCaptcha badge in the page
    const hideBadge = () => {
      const styleName = 'recaptcha-badge-style';
      let style = document.querySelector(`[data-name="${styleName}"]`);

      // If user signs in and out multiple times without reloading the page there would be multiple badges created
      // But it does not matter because not many users do it and the badges would be hidden by this code
      if (!style) {
        style = document.createElement('style');
        style.setAttribute('data-name', styleName);
        style.textContent = '.grecaptcha-badge{visibility:hidden}';
        document.head.appendChild(style);
      }

      setIsReady(true); // Set ReCaptcha as ready when the script is loaded
    };

    loadScript(GOOGLE_COM);

    // Cleanup time
    return () => {
      if (hideBadgeListener) hideBadgeListener.remove();
      if (recaptchaFailsToLoadListener) recaptchaFailsToLoadListener.remove();
      if (loadScriptRecaptchaListener) loadScriptRecaptchaListener.remove();
      if (script) document.body.removeChild(script);
    };
  }, [reCaptchaKey]);

  const getToken = useCallback(
    action => {
      return new Promise(resolve => {
        const reCaptcha = window.grecaptcha.enterprise;

        reCaptcha.ready(async () => {
          const token = await reCaptcha.execute(reCaptchaKey, {
            action,
          });

          resolve(token);
        });
      });
    },
    [reCaptchaKey]
  );

  return { getToken, error, isReady };
};

export default useRecaptcha;