// @flow
/* eslint-disable camelcase */
import React, { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import PlusRegular from '../Plus/PlusRegularContainer';
import PlusExtended from '../Plus/PlusExtendedContainer';
import PlusConfirm from '../Plus/PlusConfirm';

import { updatePaymentPairs } from '../../redux/modules/payment';

type Props = {
  // meta
  onPatch: Function,
};

const Plus = ({ onPatch }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch('/plus/:phase?');

  const plusContractData: any = useSelector(state => state.payment.plusContractData);

  const onSubmitForm = useCallback(submittedPlusContractData => {
    dispatch(updatePaymentPairs({ plusContractData: submittedPlusContractData }));
    history.push('/plus/confirm');
  });

  switch (match.params.phase) {
    case 'regular': {
      return <PlusRegular onSubmitForm={onSubmitForm} />;
    }
    case 'extended': {
      return <PlusExtended onSubmitForm={onSubmitForm} />;
    }
    case 'confirm': {
      return <PlusConfirm onPatch={onPatch} plusContractData={plusContractData} />;
    }
    default: {
      return null;
    }
  }
};

export default Plus;
